"use client";

import { UserIcon } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import Button from "components/Button";
import useCurrentUser from "hooks/useCurrentUser";
import useSchemaState from "hooks/useSchemaState";
import useSearchQueryParams from "hooks/useSearchQueryParams";
import useTableActionsState from "hooks/useTableActionsState";
import { TableColumnType, TableFilterType } from "types/baTypes";
import { APP_QUERY_PARAM_TYPES, ButtonAppearance, CellType, FILTER_OPERATOR } from "utils/constants";
import useRecordTypes from "hooks/useRecordTypes";
import { appendRecordTypeLookupToCol } from "utils/columnUtils";
import { FILTERS_PARAMS_SET } from "types/common";
import Tooltip from "components/Tooltip";
import { CURRENT_USER_FILTER_ID } from "./utils";

const CurrentUserFilter = ({
  columns,
  tableName,
  tableSlug
}: {
  columns: TableColumnType[];
  tableName: string;
  tableSlug: string;
}) => {
  const currentUser = useCurrentUser();
  const { schemaInstance } = useSchemaState();
  const { setParams, clearParams } = useSearchQueryParams();
  const { data: recordTypesData } = useRecordTypes();
  const [filterApplied, setFilterApplied] = useState(false);
  const { filtersByTableSlug, paginationByTableSlug, updatePaginationByTableSlug } = useTableActionsState();
  const peopleFilterColumns = useMemo(() => {
    const columnWithFilters = columns.filter((col) => col.hasFilter);

    // if table Itself is people table return the id column
    if (tableName === "people") {
      return columnWithFilters.filter((col) => col.name === "id");
    }

    // check the column with foreignKey field for people table or join table lookup[0] level for people table
    return columnWithFilters
      .filter((col) => col.isLookup)
      .filter((col) => col?.lookupPath?.[0]?.lookupTableName === "people");
  }, [columns, tableName]);

  const finalPeopleFilterColumns = useMemo(() => {
    return !schemaInstance?.extendedSchema
      ? []
      : (peopleFilterColumns
          ?.map((col) =>
            appendRecordTypeLookupToCol(col, recordTypesData || [], tableName, schemaInstance?.extendedSchema)
          )
          .filter(Boolean) as TableColumnType[]) || [];
  }, [peopleFilterColumns, recordTypesData, tableName, schemaInstance?.extendedSchema]);

  const handleToggleFilter = () => {
    // remove the self user filters
    const updatedFilters: TableFilterType[] =
      filtersByTableSlug?.[tableSlug]?.filter((filter) => filter.id !== CURRENT_USER_FILTER_ID) || [];

    if (filterApplied) {
      const finalFilters = updatedFilters
        .map((filter) => {
          if (filter.id !== CURRENT_USER_FILTER_ID) {
            return {
              column: filter.column,
              filterOperator: filter.filterOperator,
              filterValue: filter.filterValue,
              filterSlug: tableSlug,
              filterOrGroup: filter.filterGroup,
              filterGroupType: CURRENT_USER_FILTER_ID
            } as FILTERS_PARAMS_SET;
          }
          return null;
        })
        .filter(Boolean) as FILTERS_PARAMS_SET[];
      if (finalFilters.length) {
        setParams({
          filters: finalFilters
        });
      } else {
        clearParams(APP_QUERY_PARAM_TYPES.FILTERS);
      }
    } else {
      const peopleFilterGroup: TableFilterType[] = [];
      const filterValue = currentUser?.id;
      finalPeopleFilterColumns.forEach((column) => {
        let isFilterColumnJoinTable = false;
        let filterField = column.name;
        if (column.isLookup) {
          const lookupPath = column?.lookupPath?.[0];
          const tableName = lookupPath?.lookupTableName;
          if (tableName && schemaInstance?.extendedSchema?.[tableName]?.compositePk?.length) {
            isFilterColumnJoinTable = true;
          } else {
            if (lookupPath?.lookupForeignKey) {
              filterField = lookupPath?.lookupForeignKey;
            }
          }
        }
        // this doesn't work for join table relations
        if (!isFilterColumnJoinTable) {
          const newFilter: TableFilterType = {
            ...column,
            column: { ...column },
            filterField,
            filterOperator: FILTER_OPERATOR.EQUALS,
            isFilterColumnJoinTable,
            innerAtAllLevels: column.type === CellType.PEOPLE,
            useColumnLabel: column.type === CellType.PEOPLE,
            filterValue,
            filterLookupPath: column.lookupPath
          };
          peopleFilterGroup.push(newFilter);
        }
      });

      updatedFilters.push({
        id: CURRENT_USER_FILTER_ID,
        filterGroup: peopleFilterGroup,
        filterOperator: FILTER_OPERATOR.OR
      });

      setParams({
        filters: updatedFilters.map(
          (filter) =>
            ({
              column: filter.column,
              filterOperator: filter.filterOperator,
              filterValue: filter.filterValue,
              filterSlug: tableSlug,
              filterOrGroup: filter.filterGroup,
              filterGroupType: CURRENT_USER_FILTER_ID
            }) as FILTERS_PARAMS_SET
        )
      });
      setFilterApplied(true);
    }

    // Reset pagination
    if (paginationByTableSlug?.[tableSlug]?.currentPage !== 1) {
      updatePaginationByTableSlug(
        {
          ...paginationByTableSlug?.[tableSlug],
          currentPage: 1
        },
        tableSlug
      );
    }
  };

  useEffect(() => {
    if (!finalPeopleFilterColumns.length || !tableSlug || !currentUser?.id) return;
    const currentPeopleFilter =
      filtersByTableSlug?.[tableSlug]?.filter((filter) => filter.id === CURRENT_USER_FILTER_ID) || [];

    if (!currentPeopleFilter.length && filterApplied) {
      setFilterApplied(false);
    }
    if (currentPeopleFilter?.length && !filterApplied) {
      setFilterApplied(true);
    }
  }, [filtersByTableSlug, tableSlug, currentUser?.id, filterApplied, finalPeopleFilterColumns]);

  if (!finalPeopleFilterColumns.length) {
    return null;
  }
  return (
    <Tooltip title="Show my records">
      <Button
        showCountIndicator={filterApplied}
        icon={UserIcon}
        label=""
        appearance={ButtonAppearance.SECONDARY}
        onClick={handleToggleFilter}
      />
    </Tooltip>
  );
};

export default CurrentUserFilter;
