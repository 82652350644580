import { useCallback } from "react";
import omit from "lodash/omit";

import toast from "utils/toast";
import { getSectionApiFieldsFromViewSections } from "utils/apiUtils";

import { ViewSection } from "types/baTypes";
import { RecordItem } from "types/common";

import useAddRecord from "./useAddRecord";
import useUpdateRecord from "./useUpdateRecord";
import useRemoveRecord from "./useRemoveRecord";

type AdditionalProps = {
  currentView?: string; // Used to add columns to sections in this view
  onSuccess?: (newViewSectionData?: RecordItem) => void;
  isCustomView?: boolean;
  isPageViewCustom?: boolean;
};

const useUpsertDeleteSections = () => {
  const { addRecordAsync } = useAddRecord();
  const { updateRecordAsync } = useUpdateRecord();
  const { removeRecordAsync } = useRemoveRecord();

  const addNewSection = useCallback(
    async (newProps: ViewSection, additionalProps: AdditionalProps) => {
      const { columns } = newProps;

      const convertedProps = getSectionApiFieldsFromViewSections(omit(newProps, ["id", "columns"]) as ViewSection);
      const { data: newViewSectionData, error } = await addRecordAsync({
        tableName: "ui_column_sections",
        input: convertedProps
      });
      if (
        newViewSectionData?.[0]?.id &&
        columns?.length &&
        additionalProps?.currentView &&
        !additionalProps?.isCustomView
      ) {
        // Add columns to this view
        const pageviewColSectionInputs: any = [];
        columns?.forEach((col) => {
          const pageViewCol = additionalProps?.isPageViewCustom
            ? col.customPageViews?.[newProps.viewId as string]
            : col?.views?.[additionalProps.currentView as string];
          if (pageViewCol) {
            pageviewColSectionInputs.push({
              ui_column_sections_id: newViewSectionData[0].id,
              ui_pages_views_columns_id: pageViewCol.id
            });
          }
        });

        if (pageviewColSectionInputs.length) {
          await addRecordAsync({
            tableName: "ui_pages_views_column_sections",
            input: pageviewColSectionInputs
          });
        }
      }
      if (newViewSectionData?.[0]?.id && columns?.length && additionalProps?.isCustomView) {
        // Add columns to this view
        const viewColSectionInputs: any = [];
        columns?.forEach((col) => {
          viewColSectionInputs.push({
            ui_column_sections_id: newViewSectionData[0].id,
            ui_views_columns_id: col.viewColumnId
          });
        });

        if (viewColSectionInputs.length) {
          await addRecordAsync({
            tableName: "ui_views_columns_sections",
            input: viewColSectionInputs
          });
        }
      }
      if (error) {
        toast.error("Error adding section");
        return;
      }
      additionalProps?.onSuccess?.(newViewSectionData?.[0]);
    },
    [addRecordAsync]
  );

  const deleteSection = useCallback(
    async (sectionId: string, additionalProps: AdditionalProps) => {
      const { error } = await removeRecordAsync({
        tableName: "ui_column_sections",
        id: sectionId
      });
      if (error) {
        toast.error("Error delete section");
        return;
      }
      additionalProps?.onSuccess?.();
    },
    [removeRecordAsync]
  );

  // This does not save the columns
  const updateSection = useCallback(
    async (newProps: ViewSection, additionalProps: AdditionalProps) => {
      const convertedProps = getSectionApiFieldsFromViewSections(omit(newProps, ["columns"]));
      const { error } = await updateRecordAsync({
        tableName: "ui_column_sections",
        input: convertedProps
      });
      if (error) {
        toast.error("Error adding section");
        return;
      }
      additionalProps?.onSuccess?.();
    },
    [updateRecordAsync]
  );

  const handleAddColumnToSection = useCallback(
    async (
      {
        pageViewColumnId,
        sectionId,
        viewColumnId
      }: { pageViewColumnId?: string; viewColumnId?: string; sectionId: string },
      additionalProps: AdditionalProps
    ) => {
      if ((!pageViewColumnId && !viewColumnId) || !sectionId) return;
      let finalError = undefined;
      if (additionalProps?.isCustomView) {
        const { error } = await addRecordAsync({
          tableName: "ui_views_columns_sections",
          input: {
            ui_column_sections_id: sectionId,
            ui_views_columns_id: viewColumnId
          }
        });
        finalError = error;
      } else {
        const { error } = await addRecordAsync({
          tableName: "ui_pages_views_column_sections",
          input: {
            ui_column_sections_id: sectionId,
            ui_pages_views_columns_id: pageViewColumnId
          }
        });
        finalError = error;
      }

      if (finalError) {
        toast.error("Error adding column to section");
        return;
      }
      additionalProps?.onSuccess?.();
    },
    [addRecordAsync]
  );

  const handleRemoveColumnFromSection = useCallback(
    async (
      {
        pageViewColumnId,
        sectionId,
        viewColumnId
      }: { pageViewColumnId?: string; viewColumnId?: string; sectionId: string },
      additionalProps: AdditionalProps
    ) => {
      if ((!pageViewColumnId && !viewColumnId) || !sectionId) return;
      let finalError = undefined;
      if (additionalProps?.isCustomView) {
        const { error } = await removeRecordAsync({
          tableName: "ui_views_columns_sections",
          compositePrimaryKey: {
            ui_column_sections_id: sectionId,
            ui_views_columns_id: viewColumnId
          }
        });
        finalError = error;
      } else {
        const { error } = await removeRecordAsync({
          tableName: "ui_pages_views_column_sections",
          compositePrimaryKey: {
            ui_column_sections_id: sectionId,
            ui_pages_views_columns_id: pageViewColumnId
          }
        });
        finalError = error;
      }
      if (finalError) {
        toast.error("Error removing column from section");
        return;
      }
      additionalProps?.onSuccess?.();
    },
    [removeRecordAsync]
  );

  return {
    addNewSection,
    deleteSection,
    updateSection,
    handleAddColumnToSection,
    handleRemoveColumnFromSection
  };
};

export default useUpsertDeleteSections;
