"use client";

import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import isEmpty from "lodash/isEmpty";
import { SearchIndex } from "algoliasearch";

import SearchInput from "components/SearchInput";
import { TableViewType } from "types/baTypes";
import { AlgoliaSearchResult, generateQueries, runSingleIndexSearch } from "components/Search/utils";
import useSearchTableById from "hooks/useSearchTableById";
import useAlgoliaIndexFacets from "hooks/useAlgoliaIndexFacets";
import useTableActionsState from "hooks/useTableActionsState";
import useSearchQueryParams from "hooks/useSearchQueryParams";
import useCurrentUser from "hooks/useCurrentUser";

import { algoliaSearchClient } from "utils/algolia";
import { getSearchQueryFromParams } from "utils/paramsUtils";
import { APP_QUERY_PARAM_TYPES, INITIAL_PAGINATION_STATE } from "utils/constants";
import { getAlgoliaFiltersFromTableFilters } from "./utils";

type AlgoliaSearchFilterProps = {
  searchTableId: string;
  tableViewFilters?: TableViewType;
  tableSlug: string;
  inputClassName?: string;
  isAdminPage?: boolean;
};
const AlgoliaSearchFilter = ({
  searchTableId,
  tableViewFilters,
  tableSlug,
  inputClassName,
  isAdminPage
}: AlgoliaSearchFilterProps) => {
  const { searchParams, setParams, clearParams } = useSearchQueryParams();
  const initialSearchParamLoaded = useRef<boolean>(false);
  const currentSearchQueryRef = useRef<string>();
  const [searchValue, setSearchValue] = useState("");
  const [activeFacet, setActiveFacet] = useState<string>();
  const [searchIndex, setSearchIndex] = useState<SearchIndex>();
  const isFacetSearchActiveRef = useRef(false);

  const currentUser = useCurrentUser();
  const { data: searchTableData } = useSearchTableById(searchTableId, undefined, isAdminPage);
  const { indexFacets, getSearchIndexSettings } = useAlgoliaIndexFacets();
  const {
    setTableSearchResults,
    paginationByTableSlug,
    updatePaginationByTableSlug,
    tableSearchStateBySlug,
    updateGridPaginationByTableSlug
  } = useTableActionsState();

  const searchResultIdsLength = tableSearchStateBySlug?.[tableSlug]?.resultIds?.length;
  const currentSearchPage = tableSearchStateBySlug?.[tableSlug]?.currentPage;
  const currentPage = paginationByTableSlug?.[tableSlug]?.currentPage;

  const defaultAlgoliaFilters = useMemo(() => {
    const tableFilters = tableViewFilters?.filters;
    if (!tableFilters?.length) return {};

    const { filters, facetFilters } = getAlgoliaFiltersFromTableFilters(tableFilters);
    return { filters, facetFilters };
  }, [tableViewFilters]);

  const onSearchResultChange = useCallback(
    (results: AlgoliaSearchResult[], totalHits?: number, currentPage?: number) => {
      const resultIds = results?.map((result) => result.id);
      // Don't reset empty search results if already empty
      if (!(!resultIds?.length && !searchResultIdsLength)) {
        setTableSearchResults({
          tableSlug,
          searchState: {
            searchValue,
            resultIds,
            totalCount: totalHits,
            currentPage: currentPage ?? 1
          }
        });

        if (!currentPage || currentPage === 1) {
          updatePaginationByTableSlug(
            {
              ...INITIAL_PAGINATION_STATE,
              currentPage: 1
            },
            tableSlug
          );
          updateGridPaginationByTableSlug(
            {
              currentPage: 0
            },
            tableSlug
          );
        }
      }
    },
    [
      tableSlug,
      setTableSearchResults,
      updatePaginationByTableSlug,
      searchValue,
      searchResultIdsLength,
      updateGridPaginationByTableSlug
    ]
  );

  const handleSearchChange = useCallback(
    (value: string, searchWithActiveFacet?: boolean, page?: number) => {
      if (!searchTableData?.indexName || (!!activeFacet && !searchWithActiveFacet) || !searchIndex) return;
      if (value?.length > 2) {
        const queries = generateQueries({
          search: value,
          indices: [searchTableData.indexName],
          searchParamValues: {
            ...defaultAlgoliaFilters,
            hitsPerPage: INITIAL_PAGINATION_STATE.pageSize,
            page
          },
          activeFacet,
          orgId: isAdminPage ? currentUser?.org_id : undefined
        });
        runSingleIndexSearch({
          index: searchIndex,
          query: queries?.[0],
          callbacks: {
            onSuccess: (searchResult, totalHits, searchPage) => {
              onSearchResultChange(searchResult, totalHits, (searchPage || 0) + 1);
            },
            onError: () => {}
          }
        });
      } else if (searchResultIdsLength) {
        onSearchResultChange([], 0, 1);
      }
    },
    [
      searchTableData?.indexName,
      defaultAlgoliaFilters,
      activeFacet,
      onSearchResultChange,
      searchResultIdsLength,
      searchIndex,
      isAdminPage,
      currentUser?.org_id
    ]
  );

  const onFacetSelected = useCallback((facet: string) => {
    isFacetSearchActiveRef.current = true;
    setActiveFacet((prev) => (prev === facet ? undefined : facet));
  }, []);

  const facetSearchClicked = useCallback(() => {
    isFacetSearchActiveRef.current = false;
    handleSearchChange(searchValue, true);
  }, [handleSearchChange, searchValue]);

  const onClearSearch = useCallback(() => {
    setSearchValue("");
    setActiveFacet(undefined);
  }, []);

  useEffect(() => {
    if (activeFacet || (currentPage && currentPage > 1)) return;
    handleSearchChange(searchValue);
  }, [searchValue, handleSearchChange, activeFacet, currentPage]);

  useEffect(() => {
    if (!searchValue.length && onSearchResultChange && !activeFacet && searchResultIdsLength) {
      onSearchResultChange([], 0, 1);
    }
  }, [searchValue, onSearchResultChange, activeFacet, searchResultIdsLength, tableSlug]);

  useEffect(() => {
    if (initialSearchParamLoaded.current === false && isEmpty(searchValue === "") && !isEmpty(searchParams)) {
      const searchQuery = getSearchQueryFromParams(searchParams);
      setSearchValue(searchQuery);
    }
    initialSearchParamLoaded.current = true;
  }, [searchParams, tableSlug, searchValue]);

  useEffect(() => {
    if (!initialSearchParamLoaded.current || activeFacet || searchValue === currentSearchQueryRef?.current) return;
    if (!searchValue) {
      clearParams(APP_QUERY_PARAM_TYPES.SEARCH_QUERY);
    } else {
      setParams({ searchQuery: searchValue });
    }
    currentSearchQueryRef.current = searchValue;
  }, [setParams, searchValue, clearParams, activeFacet]);

  useEffect(() => {
    if (!searchTableData?.indexName) return;
    const index = algoliaSearchClient.initIndex(searchTableData.indexName);
    setSearchIndex(index);
  }, [searchTableData?.indexName]);

  // This effect is used to paginate search results
  useEffect(() => {
    if (
      !currentPage ||
      currentPage <= 1 ||
      searchValue?.length < 3 ||
      isFacetSearchActiveRef.current ||
      currentSearchPage === currentPage
    ) {
      return;
    }
    handleSearchChange(searchValue, !!activeFacet, currentPage);
  }, [currentPage, searchValue, handleSearchChange, activeFacet, tableSlug, currentSearchPage]);

  return (
    <SearchInput
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      onClear={onClearSearch}
      inputClassName={inputClassName}
      onFocus={() => getSearchIndexSettings(searchTableData?.indexName)}
      indexFacets={indexFacets}
      onFacetSelected={onFacetSelected}
      activeFacet={activeFacet}
      facetSearchClicked={facetSearchClicked}
    />
  );
};

export default AlgoliaSearchFilter;
