"use client";

import { useQueryClient } from "@tanstack/react-query";
import { LucideIcon } from "lucide-react";
import { useRouter } from "next/navigation";
import { Fragment, useCallback, useMemo } from "react";

import { getBASchema } from "lib/adminApi";
import {
  ButtonAppearance,
  CrudActions,
  STATIC_SIDEBAR_IDS,
  SidebarContainer,
  ViewModes,
  ViewOption
} from "utils/constants";
import { generatePageTitle } from "utils/texts";
import toast from "utils/toast";

import useAddRecord from "hooks/useAddRecord";
import useAllCustomViews from "hooks/useAllCustomViews";
import useAllPagesLite from "hooks/useAllPagesLite";
import useCurrentUser from "hooks/useCurrentUser";
import useInLayout from "hooks/useInLayout";
import useNestedViewState from "hooks/useNestedViewState";
import usePageConfigState from "hooks/usePageConfigState";
import useSchemaState from "hooks/useSchemaState";
import useTableActionsState from "hooks/useTableActionsState";

import Button from "components/Button";
import IconButton from "components/IconButton";
import MenuDropdown from "components/MenuDropdown";
import { ViewsLabel } from "components/ViewSelector/utils";
import { useGeneralActionsUpdater } from "context/GeneralActionsContext";

import { ADMIN_CUSTOM_VIEWS } from "app/(authRoutes)/(global)/admin/utils";
import useSearchQueryParams from "hooks/useSearchQueryParams";
import { TableColumnType, TablePageViewTab, TableViewConfig } from "types/baTypes";
import { CONFIG_MODAL_TYPES, PAGE_TYPES, RecordItem } from "types/common";
import useFormOpenHandler from "hooks/useFormOpenHandler";

type TableCreateButtonProps = {
  title: string | undefined;
  activeView: ViewModes;
  params: { slug: string };
  columns: TableColumnType[];
  tableName: string | undefined;
  pageId?: string;
  inSidebar?: boolean;
  isFileTable: boolean;
  isNoteTable?: boolean;
  tabConfig?: TablePageViewTab;
  parentRecordId?: string;
  parentRecordSlug?: string;
  parentRecordTable?: string;
  isFormEnabled?: boolean;
  showAddLabel?: boolean;
  icon?: LucideIcon | null;
  isFormCollapsible?: boolean;
  tabSlug?: string;
  pageViews?: TableViewConfig[];
  multiFormEnabled?: boolean;
  multiFormViewIds?: string[];
  onSuccessFormSubmit?: (record: RecordItem | undefined) => void;
};

function TableCreateButton({
  title,
  columns,
  params,
  activeView,
  tableName,
  pageId,
  inSidebar = false,
  isFileTable = false,
  isNoteTable = false,
  isFormEnabled = false,
  tabConfig,
  parentRecordId,
  parentRecordSlug,
  parentRecordTable,
  showAddLabel = false,
  isFormCollapsible = false,
  multiFormEnabled = false,
  multiFormViewIds,
  icon = null,
  pageViews
}: TableCreateButtonProps) {
  const router = useRouter();
  const queryClient = useQueryClient();
  const user = useCurrentUser();
  const { resetSchema } = useSchemaState();
  const { updateNoteState, updateSidebarState } = useTableActionsState();
  const { setParams } = useSearchQueryParams();
  const { triggerFileUploadAction } = useGeneralActionsUpdater();
  const { updatePageConfigModalState } = usePageConfigState();
  const { addRecordAsync } = useAddRecord();
  const { isAdminPage, isAdminTablesPage, isAdminMenuPage, isAdminTilesPage, isAdminSearchTable, isAdminViewsPage } =
    useInLayout();
  const { nestedViewStack } = useNestedViewState();
  const { data: allPages } = useAllPagesLite();
  const { data: customViewsData } = useAllCustomViews();
  const { openForm } = useFormOpenHandler({
    pageViews,
    pageTitle: title,
    parentRecordId,
    parentRecordSlug,
    pageSlug: params.slug
  });

  const isAdminRoute = isAdminPage;
  const columnsWithFormView = columns.filter((col) => col.views?.[ViewOption.FORM]?.id) || [];
  const entity = generatePageTitle(title);

  const handleAddFileAction = useCallback(() => {
    const parentRecordInfo = nestedViewStack?.length
      ? {
          path: nestedViewStack[nestedViewStack.length - 1].pageData?.path,
          recordId: nestedViewStack[nestedViewStack.length - 1].recordId,
          tableName:
            nestedViewStack[nestedViewStack.length - 1].pageData?.table_name ||
            nestedViewStack[nestedViewStack.length - 1]?.pageTable,
          pageId: nestedViewStack[nestedViewStack.length - 1].pageId
        }
      : parentRecordId
        ? {
            path: parentRecordSlug ? `/${parentRecordSlug}` : parentRecordSlug,
            recordId: parentRecordId,
            tableName: parentRecordTable
          }
        : undefined;

    triggerFileUploadAction({
      isOpen: true,
      currentAction: "uploadFiles",
      actionProps: {
        tableName,
        pageId,
        parentRecordInfo,
        createdIn: activeView ? `File Upload - ${ViewsLabel[activeView]} View` : "File Upload",
        createdInPath: window.location.pathname
      }
    });
  }, [
    triggerFileUploadAction,
    tableName,
    pageId,
    nestedViewStack,
    parentRecordId,
    parentRecordSlug,
    parentRecordTable,
    activeView
  ]);

  const handleAddPage = useCallback(
    async (pageType: string) => {
      if (!pageType) return;
      toast.success("Creating new page...");
      let redirectType = "";
      let newPage = null;
      if (pageType === "database_page") {
        newPage = await addRecordAsync({
          tableName: "pages",
          input: {
            title: "New Page",
            path: "/new-page-path",
            page_type: PAGE_TYPES.DATABASE
          }
        });
        redirectType = PAGE_TYPES.DATABASE;
      } else if (pageType === "reporting_page") {
        newPage = await addRecordAsync({
          tableName: "pages",
          input: {
            title: "New Page",
            path: "/new-page-path",
            page_type: PAGE_TYPES.REPORTING
          }
        });
        redirectType = PAGE_TYPES.REPORTING;
      } else if (pageType === "static_page") {
        newPage = await addRecordAsync({
          tableName: "pages",
          input: {
            title: "New Page",
            path: "/new-page-path",
            page_type: PAGE_TYPES.STATIC
          }
        });
        redirectType = PAGE_TYPES.STATIC;
      }
      if (newPage?.data?.length) {
        router.push(`/admin/tables/${newPage.data[0].id}/basics?type=${redirectType}`);
      }
    },
    [addRecordAsync, router]
  );

  const handleRefreshSchemaCache = useCallback(async () => {
    const origin = window.location.origin;
    await getBASchema(origin, false, true);
    resetSchema();

    toast.success("Schema cache refreshed! Please reload the page");
    window.location.reload();
  }, [resetSchema]);

  const handleNewFormView = useCallback(() => {
    updatePageConfigModalState({
      isOpen: true,
      type: CONFIG_MODAL_TYPES.FORM_VIEW,
      additionalConfig: {},
      onSuccess: () => {
        queryClient.refetchQueries({
          queryKey: ["table", ADMIN_CUSTOM_VIEWS]
        });
      }
    });
  }, [updatePageConfigModalState, queryClient]);

  const adminTableAddBtnItems = useMemo(() => {
    const items = [
      {
        id: "database_page",
        label: "Database Page",
        onClick: () => {
          handleAddPage("database_page");
        }
      },
      {
        id: "reporting_page",
        label: "Reporting Page",
        onClick: () => {
          handleAddPage("reporting_page");
        }
      },
      {
        id: "static_page",
        label: "Static Page",
        onClick: () => {
          handleAddPage("static_page");
        }
      },
      {
        id: "template_page",
        label: "Template",
        onClick: () => {}
      },
      {
        id: "add_form_view",
        label: "Add Form View",
        onClick: () => {
          handleNewFormView();
        }
      }
    ];
    if (user?.is_admin) {
      items.push({
        id: "Refresh Schema Cache",
        label: "Refresh Schema Cache",
        onClick: () => {
          handleRefreshSchemaCache();
        }
      });
    }
    return items;
  }, [handleAddPage, user?.is_admin, handleRefreshSchemaCache, handleNewFormView]);

  const handleOpendAdminViewConfig = useCallback(
    (viewId?: string) => {
      if (!viewId || !pageId) return;
      updateSidebarState(
        {
          showDetailView: false,
          pageViewConfigEditState: {
            isOpen: true,
            onActionComplete: () => {
              router.refresh();
            },
            pageId: pageId,
            view: ViewOption.FORM,
            pageFormViewId: viewId
          }
        },
        STATIC_SIDEBAR_IDS.PAGE_VIEW_CONFIG_EDIT_SIDEBAR
      );
    },
    [pageId, updateSidebarState, router]
  );

  const multiFormBtnItems = useMemo(() => {
    if (!multiFormEnabled || !pageViews?.length) return [];
    const btnItems: Array<{ id: string; label: string; onClick: () => void; onExternalLinkClick?: () => void }> = [];
    const mainFormView = pageViews?.find(
      (view: TableViewConfig) => view.viewType === ViewOption.FORM && !view.isPageViewCustom
    );
    const pageFormViewIds = mainFormView?.additionalConfig?.multiPageFormViewIds;
    const multiFormPageIds = mainFormView?.additionalConfig?.multiFormPageIds;
    multiFormPageIds?.forEach((pageId) => {
      const page = allPages?.find((page) => page.id === pageId);
      if (page) {
        const formConfig = page.views?.find((view) => view.viewType === ViewOption.FORM);
        if (formConfig?.columns?.length) {
          btnItems.push({
            id: page.id,
            label: `${formConfig?.additionalConfig?.showAddButtonLabel ? "Add" : "Create"} ${generatePageTitle(
              page.title
            )}`,
            onClick: () => openForm({ viewId: "", isCustom: false, page })
          });
        }
      }
    });
    const allFormViews = pageViews.filter(
      (view) =>
        view.viewType === ViewOption.FORM && (!view.isPageViewCustom || pageFormViewIds?.includes(view.viewId || ""))
    );
    allFormViews.forEach((formView) => {
      const { isPageViewCustom, additionalConfig, columns } = formView;
      if ((additionalConfig?.hideDefaultInMultiForm && formView.isDefault) || !columns?.length) {
        return;
      }
      btnItems.push({
        id: formView.id,
        label: `${!isPageViewCustom ? formView.title || generatePageTitle(title) : `${formView.title}`}`,
        onClick: () => openForm({ viewId: formView.viewId || "" }),
        onExternalLinkClick: user?.is_admin ? () => handleOpendAdminViewConfig(formView.id) : undefined
      });
    });
    if (multiFormViewIds?.length) {
      multiFormViewIds.forEach((viewId) => {
        const customView = customViewsData?.find((view) => view.id === viewId);
        if (customView && customView?.columns?.length) {
          btnItems.push({
            id: viewId,
            label: `${customView.title}`,
            onClick: () => openForm({ viewId, isCustom: true }),
            onExternalLinkClick: user?.is_admin ? () => handleOpendAdminViewConfig(customView.id) : undefined
          });
        }
      });
    }

    return btnItems;
  }, [
    multiFormEnabled,
    multiFormViewIds,
    pageViews,
    title,
    openForm,
    customViewsData,
    allPages,
    handleOpendAdminViewConfig,
    user?.is_admin
  ]);

  const onCreateButtonClick = () => {
    if (isAdminMenuPage) {
      router.push("/admin/menus/new");
      return;
    }

    if (isAdminTilesPage) {
      router.push("/admin/tiles/new");
      return;
    }
    if (isAdminSearchTable) {
      router.push("/admin/searchTables/new");
      return;
    }
    if (isAdminViewsPage) {
      handleNewFormView();
      return;
    }
    if (isNoteTable && activeView === ViewOption.NOTE) {
      updateNoteState({
        isCreating: true
      });
      return;
    }
    if (isFormCollapsible && !tabConfig?.hasAdd && !inSidebar) {
      setParams({
        form: {
          title: showAddLabel ? `Add ${entity}` : `Create ${entity}`,
          tablePath: params.slug,
          sidebar: SidebarContainer.Collapsible,
          action: tabConfig?.hasAdd ? undefined : CrudActions.CREATE,
          parentRecordId,
          parentRecordSlug,
          add: tabConfig?.hasAdd
            ? {
                addPageId: tabConfig.addPage?.id || "",
                expanded: !!tabConfig?.defaultAddExpanded,
                tabId: tabConfig?.id
              }
            : undefined
        }
      });
    } else {
      setParams({
        form: {
          tablePath: params.slug,
          sidebar: inSidebar ? SidebarContainer.Modal : SidebarContainer.Sidebar,
          action: tabConfig?.hasAdd ? undefined : CrudActions.CREATE,
          parentRecordId,
          parentRecordSlug,
          add: tabConfig?.hasAdd
            ? {
                addPageId: tabConfig.addPage?.id || "",
                expanded: !!tabConfig?.defaultAddExpanded,
                tabId: tabConfig?.id
              }
            : undefined
        }
      });
    }
  };

  return (
    <>
      {isFileTable && !tabConfig?.hasAdd && isFormEnabled && (
        <Button label={`Add ${generatePageTitle("Files")}`} onClick={handleAddFileAction} />
      )}
      {(!isFileTable || tabConfig?.hasAdd) &&
      !multiFormEnabled &&
      (columnsWithFormView?.length || isAdminMenuPage || isAdminSearchTable || isAdminTilesPage || isAdminViewsPage) ? (
        icon ? (
          <IconButton icon={icon} color={ButtonAppearance.PRIMARY} onClick={onCreateButtonClick} />
        ) : (
          <Button
            label={
              isAdminRoute
                ? isAdminMenuPage
                  ? "Add Menu"
                  : isAdminTilesPage
                    ? "Add New Tile"
                    : isAdminSearchTable
                      ? "Add Search Table"
                      : isAdminViewsPage
                        ? "Add Custom View"
                        : ""
                : tabConfig?.hasAdd || showAddLabel
                  ? `Add ${entity}`
                  : `Create ${entity}`
            }
            appearance={ButtonAppearance.PRIMARY}
            className="whitespace-nowrap"
            onClick={onCreateButtonClick}
          />
        )
      ) : isAdminTablesPage ? (
        <MenuDropdown
          wrapper={<Fragment />}
          labelComponent={
            <Button label="Add Table" appearance={ButtonAppearance.PRIMARY} className="whitespace-nowrap" />
          }
          showArrow={false}
          labelClassName="!px-0 !py-0"
          items={adminTableAddBtnItems}
        />
      ) : multiFormEnabled && multiFormBtnItems.length ? (
        <MenuDropdown
          wrapper={<Fragment />}
          labelComponent={
            <Button
              label={showAddLabel ? "Add" : "Create"}
              appearance={ButtonAppearance.PRIMARY}
              className="whitespace-nowrap"
            />
          }
          showArrow={false}
          labelClassName="!px-0 !py-0"
          items={multiFormBtnItems}
        />
      ) : null}
    </>
  );
}
export default TableCreateButton;
