"use client";

import { useQueryClient } from "@tanstack/react-query";
import { Plus as PlusIcon } from "lucide-react";
import { useRouter, useSelectedLayoutSegments } from "next/navigation";
import { useCallback, useEffect, useMemo, useState } from "react";

import sortBy from "lodash/sortBy";
import Button from "components/Button";
import Dropdown, { DropDownItemProps } from "components/Dropdown";
import usePageConfigState from "hooks/usePageConfigState";
import { CONFIG_MODAL_TYPES } from "types/common";
import { ButtonAppearance, ViewModes, ViewOption } from "utils/constants";
import { AvailableViews, ViewsIcon, ViewsLabel } from "./utils";

type ViewSelectorProps = {
  activeView: ViewModes;
  views: ViewModes[];
  onViewChange?: (view: ViewModes) => void;
  inDetailView?: boolean;
  slug: string;
  pageId?: string;
  isExposed?: boolean;
};

const ViewSelector = ({
  activeView: initialView,
  views = [],
  onViewChange,
  inDetailView,
  slug,
  pageId,
  isExposed = false
}: ViewSelectorProps) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const layoutSegments = useSelectedLayoutSegments();
  const { updatePageConfigModalState } = usePageConfigState();
  const [activeView, setActiveView] = useState<ViewModes>(initialView);

  const handleAddView = useCallback(() => {
    if (!pageId) return;
    updatePageConfigModalState({
      isOpen: true,
      type: CONFIG_MODAL_TYPES.PAGE_VIEW,
      additionalConfig: {
        pageId
      },
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ["page", slug] });
        router.refresh();
      }
    });
  }, [pageId, queryClient, slug, updatePageConfigModalState, router]);

  const handleChangeView = useCallback(
    (view: ViewOption) => {
      setActiveView(view as ViewModes);

      if (inDetailView && onViewChange) {
        onViewChange(view as ViewModes);
        return;
      }
      const url = `/table/${slug}/${view.replace("_", "")}`;

      if ((layoutSegments?.length || 0) > 4) {
        router.push(url + "/" + layoutSegments?.[5]);
        return;
      }
      return router.push(url);
    },
    [inDetailView, onViewChange, slug, router, layoutSegments]
  );

  useEffect(() => {
    setActiveView(initialView);
  }, [initialView]);

  const items = useMemo(() => {
    let currentViews: DropDownItemProps[] = views
      ?.filter((view) => AvailableViews.includes(view))
      ?.map((view, idx) => ({
        id: `${view}_${idx}`,
        label: `${ViewsLabel[view]} View`,
        onClick: () => handleChangeView(view),
        icon: ViewsIcon[view],
        view
      }));

    // sort the currentViews in the same order as AvailableViews
    currentViews = sortBy(currentViews, (view) =>
      AvailableViews.indexOf(((view.id || "") as string).split("_")[0] as ViewOption)
    );

    if (!isExposed) {
      if (currentViews?.length) {
        currentViews[currentViews.length - 1].hasDivider = true;
      }

      currentViews.push({
        id: "new" as ViewModes,
        label: "Add New View",
        onClick: handleAddView,
        icon: PlusIcon
      });
    }
    return currentViews;
  }, [views, handleAddView, handleChangeView, isExposed]);

  return (
    <div className="border-separator border-r pr-2">
      {isExposed ? (
        <div className="flex items-center gap-2">
          {items.map((viewItem) => (
            <Button
              key={viewItem.id}
              label={viewItem.label}
              appearance={viewItem.view === activeView ? ButtonAppearance.PRIMARY : ButtonAppearance.SECONDARY}
              icon={viewItem.icon}
              className="whitespace-nowrap"
              onClick={viewItem.onClick}
            />
          ))}
        </div>
      ) : (
        <Dropdown
          MenuButton={
            <Button
              label={`${ViewsLabel[activeView]} View`}
              appearance={ButtonAppearance.SECONDARY}
              icon={ViewsIcon[activeView]}
              className="whitespace-nowrap"
            />
          }
          tooltipText="Change View"
          items={items}
          data-testid="ViewSelector"
        />
      )}
    </div>
  );
};

export default ViewSelector;
