import ControlDropdown from "components/ControlDropdown";
import { TableColumnType } from "types/baTypes";
import { ControlType } from "utils/constants";
import { TableColumnTypeAndFilters } from "./utils";

interface FiltersUIProps {
  columns: TableColumnType[];
  rows: any[];
  onUpdateRow: (rows: any[]) => void;
  customToggleHandler?: (colId: string) => void;
  customChangeColOrderHandler?: (newColId: string, prevColId: string) => void;
  customAddEmptyRow?: () => void;
  renderItem: (item: TableColumnTypeAndFilters, filterIndex: number) => React.ReactNode;
  open?: boolean;
}

export const FiltersUI = ({
  columns,
  rows,
  onUpdateRow,
  customToggleHandler,
  customChangeColOrderHandler,
  customAddEmptyRow,
  renderItem,
  open
}: FiltersUIProps) => {
  return (
    <ControlDropdown
      type={ControlType.FILTER}
      columns={columns}
      renderItem={renderItem}
      rows={rows}
      onUpdateRow={onUpdateRow}
      customToggleHandler={customToggleHandler}
      customChangeColOrderHandler={customChangeColOrderHandler}
      customAddEmptyRow={customAddEmptyRow}
      open={!!open}
      isFilters
    />
  );
};
