"use client";

import { useEffect, useMemo, useState } from "react";
import sortBy from "lodash/sortBy";
import { useMediaQuery } from "usehooks-ts";

import useRecordTypes from "hooks/useRecordTypes";
import usePageByPathLite from "hooks/usePageByPathLite";

import { appendRecordTypeLookupToCol } from "utils/columnUtils";
import { CellType, SelectCellTypes, ViewModes, ViewOption } from "utils/constants";

import { TableColumnType } from "types/baTypes";

import useSchemaState from "hooks/useSchemaState";
import FiltersSeparatedItem from "./FiltersSeparatedItem";

type FiltersSeparatedProps = {
  activeView: ViewModes;
  inAddMany: boolean;
  tableName: string;
  columns?: TableColumnType[];
  tableSlug?: string;
};

const FiltersSeparated = ({ activeView, inAddMany, tableName, columns, tableSlug }: FiltersSeparatedProps) => {
  const { data: recordTypesData } = useRecordTypes();
  const { schemaInstance } = useSchemaState();
  const isMobile = useMediaQuery("(max-width: 1023px)");
  const [windowWidth, setWindowWidth] = useState(0);

  const { data: pageData } = usePageByPathLite(
    {
      slug: tableSlug || "",
      additionalDataProps: ["pageFilters"],
      source: "FiltersSeparated"
    },
    {
      enabled: !!tableSlug,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      gcTime: Infinity
    }
  );

  const columnsSorted = useMemo(
    () =>
      !schemaInstance?.extendedSchema
        ? []
        : (sortBy(columns, "filterSortOrder")
            .map((col) =>
              appendRecordTypeLookupToCol(col, recordTypesData || [], tableName, schemaInstance?.extendedSchema)
            )
            .filter(Boolean) as TableColumnType[]) || [],
    [columns, recordTypesData, tableName, schemaInstance?.extendedSchema]
  );
  const columnsWithFilter = columnsSorted?.filter(
    (col) =>
      col.hasFilter &&
      col.cellConfig?.filterUIConfig?.showFilterExposed &&
      (SelectCellTypes.includes(col.type) || col.isSelect || col.type === CellType.BOOLEAN) &&
      !col.cellConfig?.hasAdd
  );

  const columnsToShow = useMemo(() => {
    let maxWidth = 0;
    const columns: TableColumnType[] = [];

    const widthForGeneralButtons = 287 + 120.36 + 86.23 + 81.36 + 34 + 34 + 34 + 8 * 12 + 40;
    const widthForGridView = activeView === ViewOption.GRID ? 38 + 38 + 38 + 2 * 12 : 0; // 38px for each button: density, visibility, group by
    const widthForSearch = pageData?.algoliaSearchTable?.id ? 200 : 0;
    const widthOtherBarButtons = widthForGeneralButtons + widthForGridView + widthForSearch;
    const maxWidthAllowed = windowWidth - widthOtherBarButtons;
    let i = 0;

    while (maxWidth < maxWidthAllowed) {
      const width = columnsWithFilter[i]?.header?.length * 13 + 12;
      if (maxWidth + width < maxWidthAllowed) {
        columns.push(columnsWithFilter[i]);
        maxWidth += width;
        i++;
      } else {
        break;
      }
    }

    return columns;
  }, [activeView, columnsWithFilter, pageData?.algoliaSearchTable?.id, windowWidth]);

  // Update window width on resize
  useEffect(() => {
    const update = () => {
      setWindowWidth(window.innerWidth);
    };

    // set initial width
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", update);
    return () => {
      window.removeEventListener("resize", update);
    };
  }, []);

  if (!pageData || !columnsWithFilter?.length || isMobile) return null;

  return (
    <>
      {columnsToShow.map((col) => (
        <FiltersSeparatedItem
          key={col.id}
          column={col}
          pageData={pageData}
          allColumns={columns}
          inAddMany={inAddMany}
        />
      ))}
    </>
  );
};

export default FiltersSeparated;
