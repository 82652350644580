import clsx from "clsx";
import { Trash2Icon, Check } from "lucide-react";
import { useCallback } from "react";
import { useMediaQuery } from "usehooks-ts";

import CellGroup, { CellGroupProps } from "components/CellGroup";
import GenericSelect, { SelectCellGroupProps } from "components/GenericSelect";
import IconButton from "components/IconButton";
import { IconButtonColor } from "components/IconButton/utils";
import { TableColumnType, TableFilterType, TableViewType } from "types/baTypes";
import { SelectOption, SelectOptions, SelectValue } from "types/common";
import { FILTER_GROUP_TYPE, FILTER_GROUP_TYPE_OPTIONS, FILTER_OPERATOR } from "utils/constants";
import { shouldResetFilterValueOnOperatorChange } from "./utils";

interface FilterItemBase {
  disabled: boolean;
  selectOptions: SelectOptions;
  cellOptions?: SelectOptions;
  filterOperator?: string;
  inAddMany?: boolean;
}

type RenderItemProps = FilterItemBase &
  Pick<SelectCellGroupProps, "initialValues"> & {
    onChangeOperator: (params: {
      colId: string;
      filterOperator?: string;
      isInOrGroup?: boolean;
      resetFilterValue?: boolean;
      orFilterIndex?: number;
    }) => void;
  } & Pick<CellGroupProps, "column" | "value" | "type"> & {
    onEdit?: (params: {
      colId: string;
      value: string | SelectOption | boolean;
      isInOrGroup?: boolean;
      orFilterIndex?: number;
      isJSONFilter?: boolean;
    }) => void;
    isMultiple?: boolean;
    tableName?: string;
    tablePath?: string;
    tableFiltersOption?: TableViewType;
    filterIndex?: number;
    orFilterIndex?: number;
    columnOptions: SelectOptions;
    onUpdateRow: (rows: any[]) => void;
    allColumns?: TableColumnType[];
    rows: any[];
    handleUpdateFilterGroup: (filterGroupType: FILTER_GROUP_TYPE, colId: string) => void;
    isInOrGroup?: boolean;
    handleSubmitJsonFilter?: (colId: string) => void;
  };

const FilterItem = ({
  onChangeOperator,
  disabled,
  onEdit,
  value,
  type,
  column,
  selectOptions,
  cellOptions,
  isMultiple,
  tableName,
  tablePath,
  tableFiltersOption,
  filterOperator,
  inAddMany = false,
  filterIndex,
  orFilterIndex,
  columnOptions,
  onUpdateRow,
  allColumns,
  rows,
  handleUpdateFilterGroup,
  isInOrGroup,
  handleSubmitJsonFilter
}: RenderItemProps) => {
  const isDesktop = useMediaQuery("(min-width: 1024px)");

  const handleSelect = useCallback(
    (operator: any) => {
      const resetFilterValue = shouldResetFilterValueOnOperatorChange(
        filterOperator as FILTER_OPERATOR,
        (operator as SelectOption).value as FILTER_OPERATOR
      );
      onChangeOperator({
        colId: column.id,
        isInOrGroup,
        resetFilterValue,
        filterOperator: (operator as SelectOption).value as string,
        orFilterIndex
      });
    },
    [onChangeOperator, column, isInOrGroup, filterOperator, orFilterIndex]
  );

  const onEditCell = useCallback(
    (val: any) => {
      onEdit?.({
        colId: column.id,
        value: val,
        isInOrGroup,
        orFilterIndex,
        isJSONFilter:
          column.dbType?.format === "jsonb" &&
          !!filterOperator &&
          [FILTER_OPERATOR.CONTAINS, FILTER_OPERATOR.EQUALS].includes(filterOperator as FILTER_OPERATOR)
      });
    },
    [onEdit, column, isInOrGroup, orFilterIndex, filterOperator]
  );

  const handleChangeColumn = useCallback(
    (newColId: SelectValue) => {
      if (!newColId) return;
      const newColumn = allColumns?.find((col) => col.id === (newColId as SelectOption).value);
      if (!newColumn) return;
      const row = rows.find((row) =>
        isInOrGroup
          ? row.filterGroup?.find((filterRow: TableFilterType) => filterRow.id === column.id)
          : row.id === column.id
      );
      if (row) {
        const updatedRows = rows
          .map((rowVal: TableFilterType) => {
            if (isInOrGroup) {
              if (rowVal.id !== row.id) return rowVal;

              return {
                ...rowVal,
                filterGroup: rowVal.filterGroup?.map((filter: any) => (filter.id === column.id ? newColumn : filter))
              };
            }
            return rowVal.id !== row.id ? rowVal : newColumn;
          })
          .filter(Boolean);
        onUpdateRow(updatedRows);
      }
    },
    [allColumns, onUpdateRow, rows, column.id, isInOrGroup]
  );

  const handleRemove = useCallback(() => {
    const row = rows.find((row) =>
      isInOrGroup
        ? row.filterGroup?.find((filterRow: TableFilterType) => filterRow.id === column.id)
        : row.id === column.id
    );
    if (row) {
      const updatedRows = rows
        .map((rowVal: TableFilterType) => {
          if (isInOrGroup) {
            if (rowVal.id !== row.id) return rowVal;

            const updatedFilterGroup: TableFilterType[] = [];
            rowVal.filterGroup?.forEach((filter, index) => {
              if (filter.id === column.id && index === orFilterIndex) {
                return;
              } else {
                updatedFilterGroup.push(filter);
              }
            });
            return {
              ...rowVal,
              filterGroup: updatedFilterGroup
            };
          }
          return rowVal.id !== row.id ? rowVal : null;
        })
        .filter(Boolean);

      // if only one filter is left in the group, remove the group
      if (isInOrGroup && updatedRows.length === 1 && updatedRows?.[0]?.filterGroup?.length === 1) {
        onUpdateRow(updatedRows?.[0]?.filterGroup);
      } else {
        onUpdateRow(updatedRows);
      }
    }
  }, [onUpdateRow, rows, column.id, isInOrGroup, orFilterIndex]);

  return (
    <div className="grid w-full grid-cols-[60%,40%] items-center gap-2 lg:grid-cols-[repeat(5,1fr),32px,32px]">
      <div className="col-span-2 text-md lg:col-span-1 lg:max-w-[110px]">
        {(isInOrGroup ? orFilterIndex === 0 && filterIndex === 0 : filterIndex === 0) ? (
          <span className="text-primary text-sm">Where</span>
        ) : (
          <div className="">
            <GenericSelect
              options={FILTER_GROUP_TYPE_OPTIONS}
              onSelect={(option: SelectValue) =>
                handleUpdateFilterGroup((option as SelectOption)?.value as FILTER_GROUP_TYPE, column.id)
              }
              initialValues={{
                value: isInOrGroup ? FILTER_GROUP_TYPE.OR : FILTER_GROUP_TYPE.AND,
                title: isInOrGroup ? FILTER_GROUP_TYPE.OR : FILTER_GROUP_TYPE.AND
              }}
            />
          </div>
        )}
      </div>
      <div className="col-span-1">
        <GenericSelect
          options={columnOptions}
          onSelect={handleChangeColumn}
          initialValues={{ value: column.id, title: column.header }}
          showSearch
        />
      </div>
      <div className="col-span-1 lg:max-w-[120px]">
        <GenericSelect
          options={selectOptions}
          onSelect={handleSelect}
          showSearch={false}
          initialValues={
            filterOperator ? selectOptions.find((opt) => opt.value === filterOperator) : selectOptions?.[0]
          }
          classNameOptions="max-lg:right-0"
          isSorted={false}
          position={isDesktop ? "left" : "right"}
        />
      </div>
      <div className={clsx(disabled ? "hidden" : "", "col-span-1 lg:col-span-3")}>
        <CellGroup
          column={column}
          options={cellOptions}
          onEdit={onEditCell}
          value={value}
          type={type}
          ignoreValidation
          isMultiple={isMultiple}
          columnTableName={tableName}
          columnTablePath={tablePath}
          tableFiltersOption={tableFiltersOption}
          inAddMany={inAddMany}
          isEditable
          inForm
          isInFilters
        />
      </div>
      <div className="col-span-1 justify-self-end">
        {column.dbType?.format === "jsonb" &&
        filterOperator &&
        [FILTER_OPERATOR.CONTAINS, FILTER_OPERATOR.EQUALS].includes(filterOperator as FILTER_OPERATOR) ? (
          <IconButton
            icon={Check}
            onClick={() => handleSubmitJsonFilter?.(column.id || "")}
            color={IconButtonColor.TRANSPARENT}
          />
        ) : null}
        <IconButton icon={Trash2Icon} onClick={handleRemove} color={IconButtonColor.TRANSPARENT} />
      </div>
    </div>
  );
};

export default FilterItem;
