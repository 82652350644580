import { useQuery } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { algoliaSearchClient } from "utils/algolia";
// Only called on input focus once
const fetchSearchIndexSettings = async (indexName?: string) => {
  if (!indexName) return;
  const index = algoliaSearchClient.initIndex(indexName);
  const settings = await index.getSettings();
  if (!!settings?.attributesForFaceting?.length) {
    // Remove any facets that are all lowercase and replace underscore with space
    return settings.attributesForFaceting
      .filter((facet) => !/^[a-z_]+$/.test(facet) && !facet.includes("searchable("))
      .map((facet) => facet.replace("_", " "));
  }
  return [];
};

const useAlgoliaIndexFacets = () => {
  const [indexName, setIndexName] = useState<string | undefined>();

  const { data: indexFacets } = useQuery({
    queryKey: ["indexFacets", indexName],
    queryFn: () => fetchSearchIndexSettings(indexName),
    enabled: !!indexName,
    staleTime: Infinity,
    refetchInterval: false,
    refetchOnMount: false,
    gcTime: Infinity
  });

  const getSearchIndexSettings = useCallback((indexName?: string) => {
    if (!indexName) return;
    setIndexName(indexName);
  }, []);

  return { indexFacets, getSearchIndexSettings };
};

export default useAlgoliaIndexFacets;
