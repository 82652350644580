"use client";

import { FolderDown as ExportIcon } from "lucide-react";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import useTableActionsState from "hooks/useTableActionsState";
import { ButtonAppearance } from "utils/constants";

const ExportGridToCSV = () => {
  const { gridApiRef } = useTableActionsState();

  const exportGridToCSV = () => {
    if (!gridApiRef.current || !gridApiRef.current.gridRef) return;
    gridApiRef.current.gridRef.exportDataAsCsv();
  };

  return (
    <Tooltip title="Export as CSV">
      <Button
        label=""
        appearance={ButtonAppearance.SECONDARY}
        icon={ExportIcon}
        onClick={exportGridToCSV}
        className="!px-2"
      />
    </Tooltip>
  );
};

export default ExportGridToCSV;
