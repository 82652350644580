"use client";

import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { X as CloseIcon, ChevronDownIcon, Trash2 as DeleteIcon } from "lucide-react";
import useKeypress from "react-use-keypress";

import { v4 } from "uuid";
import sortBy from "lodash/sortBy";
import toast from "utils/toast";
import useTableActionsState from "hooks/useTableActionsState";
import useUpsertRecord from "hooks/useUpsertRecord";
import Button from "components/Button";
import {
  ActionType,
  AdditionalFormActions,
  ButtonAppearance,
  ButtonIconPosition,
  PAGE_ACTION_VISIBILITY,
  STATIC_SIDEBAR_IDS,
  SidebarContainer,
  UI_ACTIONS,
  USER_TYPE,
  ViewModes,
  ViewOption
} from "utils/constants";
import Dropdown, { DropDownItemProps } from "components/Dropdown";
import IconButton from "components/IconButton";
import useSchemaState from "hooks/useSchemaState";
import useUpdateRecord from "hooks/useUpdateRecord";
import { PageAction, SidebarState, ViewAction } from "types/baTypes";
import useCurrentUser from "hooks/useCurrentUser";
import useNotificationsState from "hooks/useNotificationsState";
import { NOTIFICATION_TYPE } from "components/Notifications/utils";
import { RecordItem } from "types/common";
import Icon from "components/Icon";
import { downloadFiles } from "utils/file";
import { getTimeStamp } from "utils/columnUtils";
import ConfirmationModal from "components/ConfirmationModal";
import useSendToAction from "hooks/useSendToAction";
import { isTargetNodeInSearchContainer } from "components/Search/utils";
import useRemoveRecord from "hooks/useRemoveRecord";
import useActionsHandler from "hooks/useActionsHandler";
import { getCompositeKeyFromRecord } from "lib/utils";
import { formatActions } from "utils/actions";

const BulkActionsBar = ({
  tableSlug,
  hideActions,
  activeView,
  parentRecordSlug,
  parentRecordId,
  tabSlug,
  isLocked,
  tableName
}: {
  tableSlug: string;
  hideActions?: boolean;
  activeView: ViewModes;
  parentRecordSlug?: string;
  parentRecordId?: string;
  tabSlug?: string;
  isLocked?: boolean;
  tableName: string;
}) => {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const { addPendingNotification } = useNotificationsState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const { bulkActionsStateByTableSlug, updateBulkActionsStateByTableSlug, updateSidebarState, currentTablePage } =
    useTableActionsState();
  const { upsertRecordAsync } = useUpsertRecord();
  const { updateRecordAsync } = useUpdateRecord();
  const { removeRecordAsync } = useRemoveRecord();
  const { schemaInstance } = useSchemaState();

  const { sendToTopAction, sendToBottomAction } = useSendToAction(
    tableSlug,
    bulkActionsStateByTableSlug?.[tableSlug]?.tableName
  );
  const { handleActionClick } = useActionsHandler({
    tablePath: tableSlug,
    parentRecordSlug,
    isBulkAction: true,
    tableName
  });

  const bulkActionsState = bulkActionsStateByTableSlug?.[tableSlug];

  const handleEditClick = () => {
    const sidebarOptions: SidebarState = {
      isOpen: true,
      action: AdditionalFormActions.BULK_EDIT,
      showDetailView: false
    };

    if (activeView === ViewOption.MATRIX) {
      sidebarOptions.fromView = ViewOption.MATRIX;
      sidebarOptions.parentRecordSlug = parentRecordSlug;
      sidebarOptions.parentRecordId = parentRecordId;
      sidebarOptions.tabSlug = tabSlug;
    }

    updateSidebarState(sidebarOptions, STATIC_SIDEBAR_IDS.MAIN_SIDEBAR);
  };

  const handleBulkDownload = useCallback(() => {
    downloadFiles({ files: bulkActionsState?.selectedRows?.map((row) => row.formattedRecordVal) || [] });
  }, [bulkActionsState?.selectedRows]);

  const handleBulkActionClick = useCallback(
    (action: PageAction) => {
      if (action?.action_properties?.sidebarPage?.id || action.viewId) {
        const formattedAction = formatActions([action]);
        handleActionClick({
          action: formattedAction?.[0] as ViewAction,
          bulkSelectedRowIds: bulkActionsState?.selectedRows?.map((row) => row.id),
          actionSource: currentTablePage?.path
        });
        return;
      }
      if (action.ui_action_id?.name === UI_ACTIONS.DOWNLOAD) {
        handleBulkDownload();
      }

      if (action.action_properties?.bulkWebhookUrl) {
        const notificationId = v4();
        const timestamp = getTimeStamp();
        if (action.action_properties?.bulkAddToPayload?.type) {
          updateSidebarState(
            {
              isOpen: true,
              action: AdditionalFormActions.WEBHOOK_FORM,
              container: SidebarContainer.Modal,
              additionalProps: {
                type: action.action_properties?.bulkAddToPayload?.type,
                pageId: action.action_properties?.bulkAddToPayload?.pageId,
                columnId: action.action_properties?.bulkAddToPayload?.columnId,
                webhookURL: action.action_properties?.bulkWebhookUrl,
                webhookProps: {
                  action: action.ui_action_id?.name,
                  actionId: action.ui_action_id?.id,
                  recordIds: bulkActionsState?.selectedRows?.map((row) => row.id),
                  tableName: currentTablePage?.table_name,
                  userId: currentUser?.user_id,
                  action_props: {
                    id: notificationId,
                    currentTablePage: currentTablePage?.path
                  },
                  hasWaitForSuccess: action.action_properties?.bulkWaitForSuccess
                }
              }
            },
            STATIC_SIDEBAR_IDS.MAIN_SIDEBAR
          );
          return;
        }
        fetch(action.action_properties.bulkWebhookUrl, {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify({
            action: action.ui_action_id?.name,
            actionId: action.ui_action_id?.id,
            recordIds: bulkActionsState?.selectedRows?.map((row) => row.id),
            tableName: currentTablePage?.table_name,
            userId: currentUser?.user_id,
            timestamp,
            action_props: {
              id: notificationId,
              currentTablePage: currentTablePage?.path
            }
          })
        });
        addPendingNotification({
          id: notificationId,
          action: action.ui_action_id?.name,
          recordId: "",
          tableName: currentTablePage?.table_name || "",
          userId: currentUser?.user_id || "",
          action_props: {
            id: notificationId,
            currentTablePage: currentTablePage?.path,
            hasWaitForSuccess: action.action_properties?.bulkWaitForSuccess
          },
          type: NOTIFICATION_TYPE.ACTION
        });
      }

      if (action.ui_action_id?.name === UI_ACTIONS.SEND_TO_TOP) {
        if (!bulkActionsState?.selectedRows?.length || !bulkActionsState?.tableName) return;

        sendToTopAction(bulkActionsState?.selectedRows);
      }

      if (action.ui_action_id?.name === UI_ACTIONS.SEND_TO_BOTTOM) {
        if (!bulkActionsState?.selectedRows?.length || !bulkActionsState?.tableName) return;

        sendToBottomAction(bulkActionsState?.selectedRows);
      }
      updateBulkActionsStateByTableSlug({ selectedRows: [] }, tableSlug);
    },
    [
      updateBulkActionsStateByTableSlug,
      tableSlug,
      handleBulkDownload,
      bulkActionsState?.selectedRows,
      bulkActionsState?.tableName,
      currentTablePage?.table_name,
      currentTablePage?.path,
      currentUser?.user_id,
      addPendingNotification,
      sendToTopAction,
      sendToBottomAction,
      updateSidebarState,
      handleActionClick
    ]
  );

  const bulkActions = useMemo(() => {
    if (!currentTablePage?.id) {
      return [];
    }
    if (currentTablePage.ui_pages_actions?.length) {
      return sortBy(
        currentTablePage.ui_pages_actions
          .filter((action) => [PAGE_ACTION_VISIBILITY.BULK, PAGE_ACTION_VISIBILITY.ALL].includes(action.visibility))
          .map((action) => ({
            ...action,
            icon: (props: any) => <Icon name={action.ui_action_id?.icon as any} {...props} />,
            label: action.ui_action_id?.name || "",
            onClick: () => handleBulkActionClick(action),
            action: {
              type: action?.ui_action_id?.type,
              requireConfirmation: action?.ui_action_id?.requires_confirmation
            },
            hasDivider: action?.has_divider
          })),
        "sort_order"
      );
    }
    return [];
  }, [currentTablePage, handleBulkActionClick]);

  const handleUpdateDelete = useCallback(
    async (deleteInput: RecordItem[], tableName: string, isBaseTableJoin = false) => {
      const deleteResp = [];
      for (let i = 0; i < deleteInput.length; i++) {
        const input = deleteInput[i];
        if (isBaseTableJoin) {
          deleteResp.push(
            await removeRecordAsync({
              tableName,
              id: input.id,
              compositePrimaryKey: input.compositeKeyInput
            })
          );
        } else {
          deleteResp.push(
            await updateRecordAsync({
              tableName,
              input
            })
          );
        }
      }
      const hasError = deleteResp.some((resp) => resp?.error);
      return hasError;
    },
    [updateRecordAsync, removeRecordAsync]
  );

  const handleDeleteClick = useCallback(async () => {
    if (!bulkActionsState?.selectedRows?.length || !bulkActionsState?.tableName || !schemaInstance?.extendedSchema)
      return;
    const compositePrimaryKey = schemaInstance?.extendedSchema[bulkActionsState?.tableName]?.compositePk;
    const isBaseTableJoin = !!compositePrimaryKey?.length;
    const deleteInput = bulkActionsState?.selectedRows?.map((row) => {
      if (isBaseTableJoin) {
        const record = row.originalRow ? row.originalRow : row;
        const compositeKeyInput = getCompositeKeyFromRecord(record, compositePrimaryKey);
        return {
          id: row.id,
          is_active: false,
          compositeKeyInput
        };
      } else {
        return {
          id: row.id,
          is_active: false
        };
      }
    });
    let actionResp;
    if (isBaseTableJoin) {
      const hasError = await handleUpdateDelete(deleteInput, bulkActionsState.tableName, isBaseTableJoin);
      if (hasError) {
        actionResp = { error: true };
      }
    } else {
      actionResp = await upsertRecordAsync({
        tableName: bulkActionsState.tableName,
        input: deleteInput
      });
      if (actionResp.error) {
        // Try updating each record individually
        // Error occurs in cases when there is a non-nullable field in the base table
        // TODO: Move this to async
        const hasError = await handleUpdateDelete(deleteInput, bulkActionsState.tableName);
        if (hasError) {
          actionResp = { error: true };
        } else {
          actionResp = { error: false };
        }
      }
    }

    if (!actionResp?.error) {
      toast.success("Records deleted successfully");
      queryClient.refetchQueries({
        queryKey: ["table", bulkActionsState.tableName],
        exact: false
      });
    } else {
      toast.error("Error deleting records");
    }

    updateBulkActionsStateByTableSlug({ selectedRows: [] }, tableSlug);
  }, [
    bulkActionsState?.selectedRows,
    bulkActionsState?.tableName,
    upsertRecordAsync,
    updateBulkActionsStateByTableSlug,
    queryClient,
    tableSlug,
    schemaInstance?.extendedSchema,
    handleUpdateDelete
  ]);

  const bulkActionItems = useMemo(() => {
    const actions: DropDownItemProps[] = [...(bulkActions || [])];

    // add delete action for all Staff users
    if (currentUser?.type === USER_TYPE.STAFF) {
      actions.push({
        id: "delete",
        label: "Delete",
        onClick: handleDeleteClick,
        icon: DeleteIcon,
        action: {
          requireConfirmation: true,
          type: ActionType.DANGER
        }
      });
    }

    return actions;
  }, [bulkActions, handleDeleteClick, currentUser?.type]);

  useKeypress(["Backspace", "Delete"], (event: KeyboardEvent) => {
    if (!bulkActionsState?.selectedRows?.length) return;
    if (event.key === "Delete" || event.metaKey) {
      setShowDeleteConfirmation(true);
    }
  });

  useKeypress(["Escape"], (e: KeyboardEvent) => {
    if (isTargetNodeInSearchContainer(e.target as HTMLElement)) return;
    if (!bulkActionsState?.selectedRows?.length) return;

    updateBulkActionsStateByTableSlug({ selectedRows: [] }, tableSlug);
  });

  if (!bulkActionsState?.isSelected || !bulkActionsState?.selectedRows?.length) {
    return null;
  }

  return (
    <div className="flex h-[60px] items-center gap-x-3 px-5 py-2">
      <div className="flex h-[32px] items-center gap-x-2">
        <IconButton
          icon={CloseIcon}
          onClick={() => {
            updateBulkActionsStateByTableSlug({ selectedRows: [] }, tableSlug);
          }}
        />
        <span className="text-sm">{`${bulkActionsState?.selectedRows?.length || 0} Selected`}</span>
      </div>
      {!hideActions && (
        <>
          {!isLocked && (
            <Button className="mx-3" label="Edit" appearance={ButtonAppearance.PRIMARY} onClick={handleEditClick} />
          )}

          {!!(activeView !== ViewOption.MATRIX && bulkActionItems.length) && (
            <Dropdown
              MenuButton={
                <Button
                  label="More"
                  appearance={ButtonAppearance.SECONDARY}
                  icon={ChevronDownIcon}
                  iconPosition={ButtonIconPosition.RIGHT}
                  className="whitespace-nowrap"
                />
              }
              items={bulkActionItems}
              isLink
            />
          )}
        </>
      )}
      <ConfirmationModal
        isOpen={showDeleteConfirmation}
        onClose={() => setShowDeleteConfirmation(false)}
        title="Confirm Delete"
        message="Are you sure?"
        onConfirm={handleDeleteClick}
        confirmButtonAppearance={ButtonAppearance.RED}
      />
    </div>
  );
};

export default BulkActionsBar;
