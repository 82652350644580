"use client";

import clsx from "clsx";

import Bar from "components/Bar";
import MatrixXAxisColumnsSetting from "components/MatrixXAxisColumnsSetting";
import MenuActions from "components/NavigationItem/MenuActions";
import TableCreateButton from "components/TableCreateButton";

import { SortItem, TableColumnType, TablePageViewTab, TableViewConfig, TableViewType } from "types/baTypes";
import { CardSize, NavigationItem, NavigationItemAction, RecordItem } from "types/common";

import { ViewModes, ViewOption } from "utils/constants";
import { getActiveViewFromPathName } from "utils/server";

import BulkActionsBar from "./BulkActionsBar";
import PageErrorDisplay from "./PageErrorDisplay";
import TableHeaderBottom from "./TableHeaderBottom";
import TableHeaderTitle from "./TableHeaderTitle";
import TableSections from "./TableSections";

type TableHeaderProps = {
  title: string | undefined;
  totalCount?: number | undefined;
  views: ViewModes[];
  params: { slug: string };
  columns: TableColumnType[];
  tableName: string | undefined;
  pageId?: string;
  tablePath?: string;
  onViewChange?: (view: ViewModes) => void;
  inDetailView?: boolean;
  tableSlug: string;
  inSidebar?: boolean;
  isFileTable: boolean;
  tableFiltersOption?: TableViewType;
  isNoteTable?: boolean;
  hideTitle?: boolean;
  tabConfig?: TablePageViewTab;
  hideBulkActions?: boolean;
  parentRecordId?: string;
  parentRecordSlug?: string;
  parentRecordTable?: string;
  tabSlug?: string;
  searchTableId?: string;
  isFormEnabled?: boolean;
  barControlsEnabled?: boolean;
  actions?: NavigationItemAction[];
  showAddLabel?: boolean;
  pageDataId?: string;
  inAddMany?: boolean;
  isDetailViewEnabled?: boolean;
  isFormCollapsible?: boolean;
  exposeFiltersEnabled?: boolean;
  exposeViewSelectorEnabled?: boolean;
  defaultCardSize?: CardSize;
  multiFormEnabled?: boolean;
  multiFormViewIds?: string[];
  onSuccessFormSubmit?: (record: RecordItem | undefined) => void;
  isLocked?: boolean;
  menuItem?: NavigationItem;
  isUserAdmin?: boolean;
  pageViews?: TableViewConfig[];
  currentView?: ViewModes; // if pasesd, will be used instead of getActiveView
  globalSort?: SortItem[];
  isAdminPage?: boolean;
  pathname?: string;
  isLoading?: boolean;
};

function TableHeader({
  title,
  totalCount,
  columns,
  params,
  tableName,
  pageId,
  views = [],
  inDetailView,
  onViewChange,
  tableSlug,
  inSidebar,
  isFileTable = false,
  tableFiltersOption,
  isNoteTable = false,
  tabConfig,
  hideTitle = false,
  hideBulkActions = false,
  parentRecordId,
  parentRecordSlug,
  parentRecordTable,
  searchTableId,
  isFormEnabled = false,
  barControlsEnabled = false,
  actions = [],
  showAddLabel = false,
  pageDataId,
  inAddMany = false,
  isDetailViewEnabled = false,
  isFormCollapsible = false,
  tabSlug,
  exposeFiltersEnabled = false,
  exposeViewSelectorEnabled = false,
  defaultCardSize = CardSize.LARGE,
  multiFormEnabled = false,
  multiFormViewIds,
  onSuccessFormSubmit,
  isLocked = false,
  menuItem,
  isUserAdmin,
  pageViews,
  currentView,
  globalSort,
  isAdminPage,
  pathname,
  isLoading = false
}: TableHeaderProps) {
  const columnsWithFormView = columns.filter(
    (col) => col.views?.[ViewOption.FORM]?.id || col.views?.[ViewOption.FORM]?.isHidden
  );

  const activeView: ViewModes = currentView ? currentView : getActiveViewFromPathName(pathname);
  const currentViewConfig = pageViews?.find((view) => view.viewType === activeView);

  if (inSidebar && !totalCount && activeView !== ViewOption.MATRIX) {
    return null;
  }

  return (
    <div className="z-10 flex flex-col">
      {!hideTitle && (
        <div className="border-separator flex w-full flex-col border-b">
          <div className="text-primary border-separator flex h-[60px] grow justify-between px-3 pl-5 pr-10 text-[16px] font-medium tracking-tight">
            <div className="flex items-center gap-4">
              <TableHeaderTitle
                tableSlug={tableSlug}
                totalCount={totalCount}
                inDetailView={inDetailView}
                isLoading={isLoading}
              >
                <span
                  className={clsx(isLoading ? "flex h-[24px] w-[100px] animate-pulse rounded-full bg-skeleton" : "")}
                >
                  {title}
                </span>
              </TableHeaderTitle>
              <PageErrorDisplay />
              <TableSections
                pageId={pageId}
                tableSlug={tableSlug}
                tableName={tableName}
                columns={columns}
                currentView={currentView}
                isUserAdmin={isUserAdmin}
                pageViews={pageViews}
                inSidebar={inSidebar}
                inAddMany={inAddMany}
                isLoading={isLoading}
              />
              {!!(
                activeView === ViewOption.MATRIX && currentViewConfig?.additionalConfig?.matrixShowExpandedXAxisCols
              ) && (
                <MatrixXAxisColumnsSetting
                  xAxisColumns={currentViewConfig.additionalConfig?.matrixXAxisCols || []}
                  className="self-end max-sm:hidden"
                  tableSlug={tableSlug}
                  isExpanded
                />
              )}
            </div>

            <div className="flex items-center gap-x-2">
              {!isLocked && (
                <TableCreateButton
                  title={title}
                  columns={columnsWithFormView}
                  params={params}
                  activeView={activeView}
                  tableName={tableName}
                  pageId={pageDataId}
                  inSidebar={inSidebar}
                  isFileTable={isFileTable}
                  isNoteTable={isNoteTable}
                  isFormEnabled={isFormEnabled}
                  tabConfig={tabConfig}
                  parentRecordId={parentRecordId}
                  parentRecordSlug={parentRecordSlug}
                  parentRecordTable={parentRecordTable}
                  showAddLabel={showAddLabel}
                  isFormCollapsible={isFormCollapsible}
                  tabSlug={tabSlug}
                  multiFormEnabled={multiFormEnabled}
                  multiFormViewIds={multiFormViewIds}
                  onSuccessFormSubmit={onSuccessFormSubmit}
                  pageViews={pageViews}
                />
              )}
              {actions?.length ? <MenuActions actions={actions} item={menuItem} isHovered /> : null}
            </div>
          </div>
          {!!(activeView === ViewOption.MATRIX && currentViewConfig?.additionalConfig?.matrixShowExpandedXAxisCols) && (
            <div className="hidden w-full max-sm:flex">
              <MatrixXAxisColumnsSetting
                xAxisColumns={currentViewConfig.additionalConfig?.matrixXAxisCols || []}
                tableSlug={tableSlug}
                isExpanded
              />
            </div>
          )}
        </div>
      )}
      <TableHeaderBottom
        inSidebar={inSidebar}
        barControlsEnabled={barControlsEnabled}
        tableSlug={tableSlug}
        totalCount={totalCount}
        searchTableId={searchTableId}
        inAddMany={inAddMany}
        tableFiltersOption={tableFiltersOption}
      >
        <Bar
          tableName={tableName || ""}
          columns={columns}
          activeView={activeView as ViewModes}
          views={views}
          params={{ slug: params.slug }}
          inDetailView={inDetailView}
          onViewChange={onViewChange}
          tableSlug={tableSlug}
          tableFiltersOption={tableFiltersOption}
          searchTableId={searchTableId}
          totalCount={totalCount}
          pageDataId={pageDataId}
          inAddMany={inAddMany}
          isDetailViewEnabled={isDetailViewEnabled}
          exposeFiltersEnabled={exposeFiltersEnabled}
          exposeViewSelectorEnabled={exposeViewSelectorEnabled}
          defaultCardSize={defaultCardSize}
          currentViewConfig={currentViewConfig}
          inSidebar={inSidebar}
          globalSort={globalSort}
          isAdminPage={isAdminPage}
          barControlsEnabled={barControlsEnabled}
          isPageDataLoading={isLoading}
        />

        <BulkActionsBar
          tableName={tableName || ""}
          tableSlug={tableSlug}
          hideActions={hideBulkActions}
          activeView={activeView}
          parentRecordSlug={parentRecordSlug}
          parentRecordId={parentRecordId}
          tabSlug={tabSlug}
          isLocked={isLocked}
        />
      </TableHeaderBottom>
    </div>
  );
}
export default TableHeader;
