import { TableFilterType } from "types/baTypes";
import { FILTER_OPERATOR } from "utils/constants";

export const getAlgoliaFiltersFromTableFilters = (filters: TableFilterType[]) => {
  const facetFilters: Array<string | string[]> = [];
  const filterVal: string[] = [];
  const facetFiltersAttribute: string[] = [];
  filters.map((filter) => {
    let finalFilterField = filter.filterField ?? "";
    if (filter.filterLookupPath) {
      Object.keys(filter.filterLookupPath).forEach((lookupLevel) => {
        const { lookupTableName, lookupColumns, lookupForeignKey, lookupColumnLabel } =
          filter.filterLookupPath?.[lookupLevel] || {};
        if (lookupColumns?.length) {
          finalFilterField += `${lookupColumnLabel || lookupForeignKey || lookupTableName}.${lookupColumns[0]}`;
        } else {
          finalFilterField += `${lookupColumnLabel || lookupForeignKey || lookupTableName}.`;
        }
      });
      finalFilterField = finalFilterField.endsWith(".") ? finalFilterField.slice(0, -1) : finalFilterField;
    }

    switch (filter.filterOperator) {
      case FILTER_OPERATOR.EQUALS:
        facetFilters.push(`${finalFilterField}:${filter.filterValue}`);
        facetFiltersAttribute.push(`filterOnly(${finalFilterField})`);
        return;
      case FILTER_OPERATOR.NOT_EQUALS:
        facetFilters.push(`${finalFilterField}:-${filter.filterValue}`);
        facetFiltersAttribute.push(`filterOnly(${finalFilterField})`);
        return;
      case FILTER_OPERATOR.GREATER_THAN:
        filterVal.push(`(${finalFilterField} > ${filter.filterValue})`);
        return;
      case FILTER_OPERATOR.LESS_THAN:
        filterVal.push(`(${finalFilterField} < ${filter.filterValue})`);
        return;
      case FILTER_OPERATOR.IN:
        let valueParts =
          (typeof filter.filterValue === "string"
            ? (filter.filterValue as string)
            : (filter.filterValue as string[])) || [];

        if (typeof valueParts === "string") {
          valueParts = valueParts.split(",").filter((f) => f.trim()) as string[];
        }
        facetFilters.push(valueParts.map((val) => `${finalFilterField}:${val}`));
        facetFiltersAttribute.push(`filterOnly(${finalFilterField})`);
        return;
      default:
        return;
    }
  });

  return { filters: filterVal.join(" AND "), facetFilters: facetFilters, facetFiltersAttribute };
};
