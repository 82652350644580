"use client";

import { useCallback } from "react";
import { AppWindowIcon } from "lucide-react";

import Button from "components/Button";
import { ButtonAppearance } from "utils/constants";
import useTableActionsState from "hooks/useTableActionsState";
import { CardSize } from "types/common";
import Tooltip from "components/Tooltip";

type CardSizeProps = {
  tableSlug: string;
  defaultCardSize?: CardSize;
};

type NextCardSize = {
  [key in CardSize]: CardSize;
};

type NextCardIcon = {
  [key in CardSize]: () => JSX.Element;
};

const nextCardSize: NextCardSize = {
  [CardSize.SMALL]: CardSize.EXTRA_LARGE,
  [CardSize.MEDIUM]: CardSize.SMALL,
  [CardSize.LARGE]: CardSize.MEDIUM,
  [CardSize.EXTRA_LARGE]: CardSize.LARGE
};

const cardSizeIcon = {
  [CardSize.SMALL]: () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.05493" y="1.05493" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="1.05493" y="6.54932" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="6.5752" y="1.05493" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="6.5752" y="6.54932" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="1.05493" y="3.80225" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="1.05493" y="9.29663" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="6.5752" y="3.80225" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="6.5752" y="9.29663" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="3.78784" y="1.05493" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="3.78784" y="6.54932" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="9.30811" y="1.05493" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="9.30811" y="6.54932" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="3.78784" y="3.80225" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="3.78784" y="9.29663" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="9.30811" y="3.80225" width="1.63698" height="1.64835" fill="#1D2433" />
      <rect x="9.30811" y="9.29663" width="1.63698" height="1.64835" fill="#1D2433" />
    </svg>
  ),
  [CardSize.MEDIUM]: () => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.752686" y="0.752686" width="2.59269" height="2.59269" fill="#1D2433" />
      <rect x="0.752686" y="8.3208" width="2.59269" height="2.59269" fill="#1D2433" />
      <rect x="8.40723" y="0.752686" width="2.59269" height="2.59269" fill="#1D2433" />
      <rect x="8.40723" y="8.3208" width="2.59269" height="2.59269" fill="#1D2433" />
      <rect x="0.752686" y="4.53674" width="2.59269" height="2.59269" fill="#1D2433" />
      <rect x="8.40723" y="4.53674" width="2.59269" height="2.59269" fill="#1D2433" />
      <rect x="4.53674" y="0.752686" width="2.59269" height="2.59269" fill="#1D2433" />
      <rect x="4.53674" y="8.3208" width="2.59269" height="2.59269" fill="#1D2433" />
      <rect x="4.53674" y="4.53674" width="2.59269" height="2.59269" fill="#1D2433" />
    </svg>
  ),
  [CardSize.LARGE]: AppWindowIcon,
  [CardSize.EXTRA_LARGE]: () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.45" y="0.45" width="6.1" height="6.1" rx="0.55" stroke="#1D2433" strokeWidth="0.9" />
      <rect x="9.45" y="8.45" width="6.1" height="6.1" rx="0.55" stroke="#1D2433" strokeWidth="0.9" />
      <rect x="1.45" y="8.45" width="6.1" height="6.1" rx="0.55" stroke="#1D2433" strokeWidth="0.9" />
      <rect x="9.45" y="0.45" width="6.1" height="6.1" rx="0.55" stroke="#1D2433" strokeWidth="0.9" />
    </svg>
  )
};

const CardSizeControl = ({ tableSlug, defaultCardSize = CardSize.LARGE }: CardSizeProps) => {
  const { cardSizeByTableSlug, updateCardSizeByTableSlug } = useTableActionsState();

  const currentCardSize = cardSizeByTableSlug?.[tableSlug] || defaultCardSize;

  const handleChangeSize = useCallback(() => {
    updateCardSizeByTableSlug(tableSlug, nextCardSize[currentCardSize as keyof NextCardSize]);
  }, [updateCardSizeByTableSlug, tableSlug, currentCardSize]);

  return (
    <Tooltip title="Change Card Size">
      <Button
        label=""
        appearance={ButtonAppearance.SECONDARY}
        icon={cardSizeIcon[currentCardSize as keyof NextCardIcon]}
        classNameIcon="!w-4 !h-4"
        onClick={handleChangeSize}
        className="!w-[30px] shrink-0 !px-1"
      />
    </Tooltip>
  );
};

export default CardSizeControl;
