"use client";

import { LinkIcon } from "lucide-react";

import Button from "components/Button";
import Tooltip from "components/Tooltip";
import useCurrentUser from "hooks/useCurrentUser";
import { ButtonAppearance, ViewOption } from "utils/constants";
import AdminUserActionSelector from "./AdminUserActionSelector";

type AdminConfigBarButtonsProps = {
  views: ViewOption[];
  isDetailViewEnabled?: boolean;
  pageDataId?: string;
  tableSlug: string;
  tableName: string;
  activeView: ViewOption;
  searchTableId?: string;
};

const AdminConfigBarButtons = ({
  views,
  isDetailViewEnabled,
  pageDataId,
  tableSlug,
  tableName,
  activeView,
  searchTableId
}: AdminConfigBarButtonsProps) => {
  const currentUser = useCurrentUser();
  if (!currentUser?.is_admin) {
    return null;
  }

  return (
    <>
      <Tooltip title="Admin Table">
        <Button
          label=""
          appearance={ButtonAppearance.SECONDARY}
          icon={LinkIcon}
          className="!px-2"
          as={<a target="_blank" />}
          href={`/admin/tables/${pageDataId}/basics`}
        />
      </Tooltip>

      <AdminUserActionSelector
        activeView={activeView}
        views={views}
        tableSlug={tableSlug}
        tableName={tableName}
        pageDataId={pageDataId}
        isDetailViewEnabled={isDetailViewEnabled}
        searchTableId={searchTableId}
      />
    </>
  );
};

export default AdminConfigBarButtons;
