import noop from "lodash/noop";
import Toggle from "components/Toggle";
import { ToggleSize } from "components/Toggle/utils";
import useCurrentUser from "hooks/useCurrentUser";
import useTableActionsState from "hooks/useTableActionsState";

const BarOrgIdFilterToggle = ({ tableSlug }: { tableSlug: string }) => {
  const currentUser = useCurrentUser();
  const { superAdminOrgIdFilterDisabledByTableSlug, updateSuperAdminOrgIdFilterDisabledByTableSlug } =
    useTableActionsState();
  const isOrgIdFilterDisabled = !!superAdminOrgIdFilterDisabledByTableSlug?.[tableSlug];
  if (!currentUser?.is_super_admin) {
    return null;
  }
  return (
    <div
      className="text-primary flex h-9 cursor-pointer items-center justify-center gap-2 rounded-md border border-neutral-300 px-3 py-2 shadow-50  hover:shadow-none  active:shadow-none dark:border-neutral-dark-300"
      onClick={() => updateSuperAdminOrgIdFilterDisabledByTableSlug(!isOrgIdFilterDisabled, tableSlug)}
    >
      <span className="text-primary bg-gree whitespace-nowrap text-sm font-medium">Show All Org Records: </span>
      <Toggle
        checked={isOrgIdFilterDisabled}
        checkedSwitchBgColor="bg-green-800"
        size={ToggleSize.SM}
        onChange={noop}
        className="flex items-center"
      />
    </div>
  );
};

export default BarOrgIdFilterToggle;
