import { useMemo } from "react";

import CellGroup from "components/CellGroup";
import useTableActionsState from "hooks/useTableActionsState";
import useFilterUpdate from "hooks/useFilterUpdate";
import { Page, TableColumnType, TableFilterType } from "types/baTypes";
import { SelectOption } from "types/common";
import { getFilterValueFromFilter } from "./utils";

type FiltersSeparatedItemProps = {
  column: TableColumnType;
  pageData: Page;
  inAddMany: boolean;
  allColumns?: TableColumnType[];
};

const FiltersSeparatedItem = ({ column, pageData, inAddMany, allColumns }: FiltersSeparatedItemProps) => {
  const { filtersByTableSlug } = useTableActionsState();
  const { handleSeparateUpdateFilterValue } = useFilterUpdate(
    pageData?.path?.replace("/", "") || "",
    allColumns || [],
    inAddMany
  );

  const tableSlug = pageData?.path?.replace("/", "") || "";

  const filterValue = useMemo(() => {
    const filter = filtersByTableSlug?.[tableSlug]?.find((filter: TableFilterType) => filter.id === column.id);

    return getFilterValueFromFilter(filter, column);
  }, [filtersByTableSlug, tableSlug, column]);

  return (
    <CellGroup
      column={column}
      type={column.type}
      columnTableName={pageData?.table_name}
      columnTablePath={pageData?.path}
      onEdit={(value) => handleSeparateUpdateFilterValue(column.id, value as string | boolean | SelectOption)}
      value={filterValue}
      inAddMany={inAddMany}
      filterForTableSlug={tableSlug}
      tableFiltersOption={pageData?.pageFilters}
      isSeparatedFilterActive={!!filterValue}
      isEditable
      isInFilters
      isInSeparatedFilter
      ignoreValidation
      loadOnClick
    />
  );
};

export default FiltersSeparatedItem;
