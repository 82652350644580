"use client";
import clsx from "clsx";
import lodashChunk from "lodash/chunk";
import sortBy from "lodash/sortBy";
import { BoxesIcon, Check, MoreVertical } from "lucide-react";
import dynamic from "next/dynamic";
import { useRouter, useSearchParams } from "next/navigation";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { TableColumnTypeAndFilters } from "components/Bar/Filters/utils";
import Button from "components/Button";
import Dropdown, { DropDownItemProps } from "components/Dropdown";
import toast from "utils/toast";

import useCurrentUser from "hooks/useCurrentUser";
import useFilterUpdate from "hooks/useFilterUpdate";
import useInLayout from "hooks/useInLayout";
import useSearchQueryParams from "hooks/useSearchQueryParams";
import useStableLayoutSegments from "hooks/useStableLayoutSegments";
import useTableActionsState from "hooks/useTableActionsState";
import useUpsertDeletePageViewConfig from "hooks/useUpsertDeletePageViewConfig";
import useUpsertDeleteSections from "hooks/useUpsertDeleteSections";

import { Page, TableColumnType, TableViewConfig, ViewSection } from "types/baTypes";
import { RecordItem } from "types/common";
import { APP_QUERY_PARAM_TYPES, ButtonAppearance, ViewOption } from "utils/constants";

const EditSection = dynamic(() => import("components/Admin/EditSection"), { ssr: false });

type ViewSectionSettingsProps = {
  viewSections: ViewSection[];
  className?: string;
  isExpanded?: boolean;
  tableSlug: string;
  activeView: ViewOption;
  columns: TableColumnType[];
  defaultViewSectionId?: string;
  inSidebar?: boolean;
  inAddMany?: boolean;
  pageState?: Partial<Page>;
  onSuccess?: () => void;
  currentViewConfig?: TableViewConfig;
};
const ViewSectionSettings = ({
  viewSections,
  className,
  isExpanded = false,
  tableSlug,
  activeView,
  columns,
  defaultViewSectionId,
  inSidebar,
  inAddMany,
  pageState,
  onSuccess,
  currentViewConfig
}: ViewSectionSettingsProps) => {
  const router = useRouter();
  const lastSectionFilterSetRef = useRef(false);
  const lastTableSlugDefaultSectionIdRef = useRef("");
  const currentUser = useCurrentUser();
  const { setParams, clearParams } = useSearchQueryParams();
  const { groupByInfoByTableSlug, updateColumnsVisibilityModelByTableSlug, updateSectionFiltersByTableSlug } =
    useTableActionsState();
  const queryParams = useSearchParams();
  const layoutSegments = useStableLayoutSegments();
  const { isTable } = useInLayout();
  const { handleUpdateFilters } = useFilterUpdate(tableSlug, columns, inAddMany);
  const { deleteSection, updateSection, addNewSection } = useUpsertDeleteSections();
  const { handleSavePageView } = useUpsertDeletePageViewConfig();

  const [sectionHovered, setSectionHovered] = useState<RecordItem>({});
  const [activeViewSectionId, setActiveViewSectionId] = useState<string | null>(null);
  const [editSection, setEditSection] = useState<ViewSection | null>(null);

  const onViewSectionColumnsUpdateSuccess = useCallback(() => {
    router.refresh();
  }, [router]);

  const columnOptions = useMemo(() => {
    return columns
      ?.filter((c) => !!c.views?.[activeView]?.id)
      .map((col) => ({
        title: col.nickname || col.header,
        value: col.id,
        column: col
      }));
  }, [columns, activeView]);

  const lastTab = useMemo(() => {
    let lastTab = "";
    if ((layoutSegments?.length || 0) > 1) {
      const tabSlugsParams = layoutSegments?.[1]?.split("/");
      const tabSlugPairs = lodashChunk(tabSlugsParams, 2);
      const lastPair = tabSlugPairs[tabSlugPairs.length - 1];
      lastTab = lastPair?.[0];
    } else {
      lastTab = layoutSegments?.[0] || "";
    }
    return lastTab;
  }, [layoutSegments]);

  const activeViewVisibleColumns = useMemo(
    () =>
      columns.filter(
        (col) =>
          col.views?.[activeView]?.id &&
          (col.views?.[activeView]?.isFeaturedImage ||
            (!col.views?.[activeView]?.isFeaturedImage && !col.views?.[activeView]?.isHidden)) &&
          !!col.isActive &&
          !(col.isRollup && !col.name) &&
          (col.isAdminColumn ? currentUser?.is_admin : true)
      ),
    [columns, activeView, currentUser]
  );

  const changeViewSection = useCallback(
    (viewSectionId?: string) => {
      if (viewSectionId) {
        setParams({
          viewSection: {
            sectionId: viewSectionId,
            sectionSlug: tableSlug
          }
        });
      }
    },
    [setParams, tableSlug]
  );

  const handleChangeViewSection = useCallback(
    (viewSection?: ViewSection) => {
      if (!activeViewVisibleColumns) return;

      const groupByInfo = groupByInfoByTableSlug?.[tableSlug]?.[activeView];
      const updatedColumnVisibilityModel: { [key: string]: boolean } = {};
      activeViewVisibleColumns.forEach((col) => {
        if (!viewSection) {
          if (!groupByInfo?.groupedColumns?.includes(col.id)) {
            updatedColumnVisibilityModel[col.id] = true;
          } else {
            updatedColumnVisibilityModel[col.id] = false;
          }
        } else {
          if (viewSection.columns?.find((c) => c.id === col.id) && !groupByInfo?.groupedColumns?.includes(col.id)) {
            updatedColumnVisibilityModel[col.id] = true;
          } else {
            updatedColumnVisibilityModel[col.id] = false;
          }
        }
      });

      // Check if view section has filters
      if (viewSection?.filters?.length) {
        const userVisibleFilters = viewSection.filters.filter((filter) => filter.isUserVisible || filter.pageColumnId);
        if (userVisibleFilters?.length) {
          const finalVisibleFilters: TableColumnTypeAndFilters[] = [];
          userVisibleFilters.forEach((filter) => {
            const column = columns.find((col) => col.pageColumnId === filter.pageColumnId && !!filter.isUserVisible);
            if (column) {
              finalVisibleFilters.push({
                ...filter,
                ...column,
                id: column.id
              } as TableColumnTypeAndFilters);
            }
          });

          handleUpdateFilters(finalVisibleFilters as TableColumnTypeAndFilters[]);
          lastSectionFilterSetRef.current = true;
        } else {
          const filterQuerySlug = queryParams?.get(APP_QUERY_PARAM_TYPES.FILTERS);
          if (filterQuerySlug === tableSlug) {
            clearParams(APP_QUERY_PARAM_TYPES.FILTERS);
          }
        }
        // Check if any filter has column linked
        const finalSectionFilters = viewSection.filters.filter((filter) => !filter.isUserVisible);
        updateSectionFiltersByTableSlug(finalSectionFilters, tableSlug);
      } else {
        updateSectionFiltersByTableSlug([], tableSlug);
        if (lastSectionFilterSetRef.current) {
          const filterQuerySlug = queryParams?.get(APP_QUERY_PARAM_TYPES.FILTERS);
          if (filterQuerySlug === tableSlug) {
            clearParams(APP_QUERY_PARAM_TYPES.FILTERS);
          }
          lastSectionFilterSetRef.current = false;
        }
      }
      updateColumnsVisibilityModelByTableSlug(tableSlug, updatedColumnVisibilityModel, activeView);
    },
    [
      activeViewVisibleColumns,
      groupByInfoByTableSlug,
      updateColumnsVisibilityModelByTableSlug,
      tableSlug,
      activeView,
      updateSectionFiltersByTableSlug,
      columns,
      handleUpdateFilters,
      queryParams,
      clearParams
    ]
  );

  const handleViewSectionAction = useCallback(
    (section: DropDownItemProps, action: string) => {
      if (!section) return;
      if (action === "edit") {
        const finalSection = viewSections.find((s) => `${s.id}` === section.id);
        if (finalSection) {
          setEditSection(finalSection);
        }
      } else if (action === "delete") {
        deleteSection(`${section.id}`, {
          onSuccess
        });
      } else if (action === "duplicate") {
        const finalSection = viewSections.find((s) => `${s.id}` === section.id);
        if (finalSection && currentViewConfig?.id) {
          const viewSectionToAdd: ViewSection = {
            id: "new",
            columns: finalSection.columns,
            title: `${finalSection.title} (Copy)`,
            sort: "",
            viewId: currentViewConfig.isCustom ? currentViewConfig.id : undefined,
            pageViewId: currentViewConfig.isCustom ? undefined : currentViewConfig.id,
            isAdminOnly: finalSection.isAdminOnly,
            webhookUrl: finalSection.webhookUrl,
            isClose: finalSection.isClose
          };
          addNewSection(viewSectionToAdd, {
            currentView: currentViewConfig?.viewType,
            onSuccess: onSuccess,
            isPageViewCustom: !!currentViewConfig?.isPageViewCustom
          });
        }
      } else if (action === "default") {
        if (currentViewConfig?.id) {
          handleSavePageView({
            input: {
              id: currentViewConfig.id,
              default_column_section_id: `${section?.id}`
            },
            onSuccess: () => {
              toast.success("Default section updated successfully");
              onSuccess?.();
            }
          });
        }
      } else if (action === "user_section") {
        updateSection(
          {
            id: section.id as string,
            userId: currentUser?.user_id
          },
          {
            onSuccess
          }
        );
      } else if (action === "public_section") {
        updateSection(
          {
            id: section.id as string,
            userId: null
          },
          {
            onSuccess
          }
        );
      }
    },
    [
      viewSections,
      deleteSection,
      onSuccess,
      currentUser?.user_id,
      updateSection,
      currentViewConfig?.id,
      currentViewConfig?.isCustom,
      currentViewConfig?.isPageViewCustom,
      currentViewConfig?.viewType,
      handleSavePageView,
      addNewSection
    ]
  );

  const handleViewSectionEditSave = useCallback(() => {
    if (!editSection) return;
    updateSection(editSection, {
      onSuccess
    });

    setEditSection(null);
  }, [updateSection, editSection, onSuccess]);

  const onUpdateViewSection = useCallback((newProps: Omit<Partial<ViewSection>, "id">) => {
    setEditSection(
      (prev) =>
        ({
          ...(prev || {}),
          ...newProps
        }) as ViewSection
    );
  }, []);

  const viewSectionActions = useCallback(
    (viewSection: DropDownItemProps) => {
      const items: DropDownItemProps[] = [];
      if (currentUser?.is_admin || currentUser?.user_id === viewSection?.userId) {
        items.push(
          {
            id: "edit",
            label: "Edit Section",
            onClick: () => handleViewSectionAction(viewSection, "edit")
          },
          {
            id: "duplicate",
            label: "Duplicate Section",
            onClick: () => handleViewSectionAction(viewSection, "duplicate")
          },
          {
            id: "delete",
            label: "Delete Section",
            onClick: () => handleViewSectionAction(viewSection, "delete"),
            hasDivider: true
          }
        );
      }
      if (!defaultViewSectionId || (!!defaultViewSectionId && `${defaultViewSectionId}` !== `${viewSection?.id}`)) {
        items.push({
          id: "default",
          label: "Set as Default Section",
          onClick: () => handleViewSectionAction(viewSection, "default"),
          hasDivider: true
        });
      }
      if (currentUser?.is_admin) {
        items.push({
          id: "visibility",
          label: "Change Visibility",
          childItems: [
            {
              id: "user_section",
              label: "Only to you",
              onClick: () => handleViewSectionAction(viewSection, "user_section"),
              icon: !!viewSection?.isUserSection ? Check : null
            },
            {
              id: "public_section",
              label: "Everyone in BuildAppeal",
              onClick: () => handleViewSectionAction(viewSection, "public_section"),
              icon: !viewSection?.isUserSection ? Check : null
            }
          ]
        });
      }

      return items;
    },
    [handleViewSectionAction, currentUser?.is_admin, currentUser?.user_id, defaultViewSectionId]
  );

  const sortedViewSectionItems = useMemo(() => {
    const sortedItems = sortBy(viewSections, `sort`);

    if (!sortedItems.length) return [];

    return sortedItems
      .map((section) => {
        return {
          id: `${section.id}`,
          label: section.title || "",
          onClick: () => changeViewSection(section.id),
          isUserSection: section.userId === currentUser?.user_id,
          style: section?.config?.color
            ? {
                backgroundColor: section?.config?.color
              }
            : undefined
        };
      })
      .concat([
        {
          id: "show_all",
          label: "All",
          onClick: () => changeViewSection("all"),
          style: undefined,
          isUserSection: false
        }
      ]);
  }, [viewSections, changeViewSection, currentUser?.user_id]);

  useEffect(() => {
    if (
      !queryParams?.get(APP_QUERY_PARAM_TYPES.VIEW_SECTION) ||
      (queryParams?.get(APP_QUERY_PARAM_TYPES.VIEW_SECTIONSLUG) &&
        queryParams?.get(APP_QUERY_PARAM_TYPES.VIEW_SECTIONSLUG) !== tableSlug) ||
      !viewSections?.length ||
      !activeViewVisibleColumns?.length
    ) {
      return;
    }
    const sectionId = queryParams?.get(APP_QUERY_PARAM_TYPES.VIEW_SECTION);
    if (sectionId === "all") {
      handleChangeViewSection();
      setActiveViewSectionId(null);
      return;
    }

    if (sectionId === activeViewSectionId) return;
    setActiveViewSectionId(sectionId);
    const selectedSection = sectionId !== "all" ? viewSections?.find((section) => `${section.id}` === sectionId) : null;
    if (selectedSection) {
      handleChangeViewSection(selectedSection);
    }
  }, [queryParams, handleChangeViewSection, viewSections, tableSlug, activeViewVisibleColumns, activeViewSectionId]);

  useEffect(() => {
    if (
      queryParams?.get(APP_QUERY_PARAM_TYPES.VIEW_SECTIONSLUG) === tableSlug ||
      (!isTable && lastTab !== tableSlug) ||
      !defaultViewSectionId ||
      (defaultViewSectionId && lastTableSlugDefaultSectionIdRef?.current === `${tableSlug}_${defaultViewSectionId}`) ||
      inSidebar
    ) {
      return;
    }

    setParams({
      viewSection: {
        sectionId: defaultViewSectionId,
        sectionSlug: tableSlug
      }
    });
    lastTableSlugDefaultSectionIdRef.current = `${tableSlug}_${defaultViewSectionId}`;
  }, [defaultViewSectionId, setParams, queryParams, tableSlug, lastTab, isTable, inSidebar]);

  useEffect(() => {
    if (!editSection?.id) return;
    const section = viewSections.find((s) => s.id === editSection?.id);
    if (section) {
      setEditSection(section);
    }
  }, [viewSections, editSection?.id]);

  if (!isExpanded) {
    return (
      <Dropdown
        MenuButton={
          <Button
            label=""
            appearance={ButtonAppearance.SECONDARY}
            icon={BoxesIcon}
            className="whitespace-nowrap !px-2.5"
          />
        }
        tooltipText="Change view section"
        items={sortedViewSectionItems}
      />
    );
  }

  return (
    <div className={clsx("flex h-full items-center gap-3 overflow-x-auto pt-[3px] scrollbar-hide", className)}>
      {sortedViewSectionItems.map((groupItem) => {
        const sectionActions = viewSectionActions(groupItem);
        return (
          <div
            key={groupItem.id}
            className={clsx(
              "flex h-full cursor-pointer items-center border-b-[2px] border-transparent py-2 text-sm font-medium transition-transform",
              (!!activeViewSectionId && groupItem.id === activeViewSectionId) ||
                (!activeViewSectionId && groupItem.id === "show_all")
                ? "text-primary border-b-primary-700 font-medium dark:border-b-primary-dark-700"
                : "text-secondary"
            )}
            onClick={groupItem.onClick}
          >
            <div
              className="hover:text-primary flex flex-row items-center justify-between rounded-md px-3 py-1 hover:!bg-neutral-200 dark:hover:!bg-neutral-dark-200"
              style={groupItem.style}
              onMouseEnter={() => setSectionHovered((prev) => ({ ...prev, [groupItem.id]: true }))}
              onMouseLeave={() => setSectionHovered((prev) => ({ ...prev, [groupItem.id]: false }))}
            >
              <span>{groupItem.label}</span>
              {sectionActions?.length && groupItem.id !== "show_all" && sectionHovered?.[groupItem.id] ? (
                <Dropdown
                  MenuButton={
                    <Button
                      label=""
                      appearance={ButtonAppearance.TERTIARY}
                      icon={MoreVertical}
                      className="whitespace-nowrap !px-2.5"
                    />
                  }
                  tooltipText="Section Actions"
                  items={sectionActions}
                />
              ) : null}
            </div>
          </div>
        );
      })}
      {editSection ? (
        <EditSection
          onUpdateSuccess={onSuccess}
          handleViewSectionEditSave={handleViewSectionEditSave}
          viewSections={viewSections}
          editViewSection={editSection}
          columnOptions={columnOptions}
          currentView={activeView}
          handleClose={() => setEditSection(null)}
          onUpdateViewSection={onUpdateViewSection}
          pageState={pageState}
          onViewSectionColumnsUpdateSuccess={onViewSectionColumnsUpdateSuccess}
        />
      ) : null}
    </div>
  );
};

export default ViewSectionSettings;
