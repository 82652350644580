import { Switch as BaseSwitch } from "@headlessui/react";
import clsx from "clsx";

type SwitchProps = Parameters<typeof BaseSwitch>[0];

const Switch = (props: SwitchProps) => {
  return (
    <div data-testid="Switch" className="flex items-center py-[6px] px-3">
      <BaseSwitch
        {...props}
        className={clsx(
          props.checked ? "bg-primary-700 dark:bg-primary-dark-700" : "bg-neutral-300 dark:bg-gray-700",
          "relative inline-flex h-[23.33px] w-10 shrink-0 items-center rounded-full border-2 border-transparent px-[2px] transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
        )}
      >
        <span
          aria-hidden="true"
          className={`${props.checked ? "translate-x-[15px]" : "translate-x-0"}
pointer-events-none inline-block h-[16.67px] w-[16.67px] transform rounded-full bg-neutral-0 shadow-lg ring-0 transition duration-200 ease-in-out`}
        />
      </BaseSwitch>
      <span className={"ml-3 whitespace-nowrap text-sm font-medium leading-4"}>{props.name}</span>
    </div>
  );
};

export default Switch;
