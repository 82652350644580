import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { getPageByPathLite } from "lib/adminApi";
import { PAGE_QUERY_ADDITIONAL_PROP_TYPES, QueryHookOptions } from "types/common";
import { USER_TYPE } from "utils/constants";
import { TableColumnType } from "types/baTypes";
import useUserType from "./useUserType";
import useCurrentUser from "./useCurrentUser";

type usePageByPathProps = {
  slug: string;
  userTypeQP?: string;
  baseColumns?: string[];
  additionalColumns?: TableColumnType[];
  additionalDataProps?: PAGE_QUERY_ADDITIONAL_PROP_TYPES;
  source?: string;
};

const usePageByPathLite = (
  { slug, userTypeQP, baseColumns, additionalColumns, additionalDataProps, source }: usePageByPathProps,
  options?: QueryHookOptions
) => {
  const supabaseClient = useSupabaseBrowser();
  const { userType } = useUserType();
  const currentUser = useCurrentUser();
  const finalUserType = currentUser?.type === USER_TYPE.STAFF && userTypeQP ? (userTypeQP as USER_TYPE) : userType;

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["page", slug, finalUserType, additionalDataProps, currentUser?.org_id],
    queryFn: () =>
      getPageByPathLite(supabaseClient, {
        path: "/" + slug,
        userType: finalUserType,
        baseTableColumnNames: baseColumns,
        additionalColumns,
        additionalDataProps,
        source,
        organizationId: currentUser?.org_id
      }),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    enabled: !!slug,
    ...options
  });

  return {
    data: data?.data,
    isLoading: isLoading || !userType,
    refetch
  };
};

export default usePageByPathLite;
