import { WrapTextIcon } from "lucide-react";
import { ButtonAppearance, RowDensity, ROW_DENSITY_OPTIONS, DensityType } from "utils/constants";
import Button from "components/Button";
import Dropdown from "components/Dropdown";

type DensitySelectorProps = {
  onChange?: (option: RowDensity) => void;
  value?: RowDensity;
};

const DensitySelector = ({ value, onChange }: DensitySelectorProps) => {
  const items = ROW_DENSITY_OPTIONS.map((option) => ({
    id: option.type,
    label: option.label,
    onClick: () => onChange?.(option),
    isActive: option.type === value?.type
  }));

  if (value?.type === DensityType.AUTO) {
    return null;
  }

  return (
    <Dropdown
      MenuButton={
        <Button
          label=""
          appearance={ButtonAppearance.SECONDARY}
          icon={WrapTextIcon}
          className="whitespace-nowrap !px-2.5"
        />
      }
      tooltipText="Change Row Density"
      items={items}
      data-testid="DensitySelector"
    />
  );
};

export default DensitySelector;
