"use client";

import clsx from "clsx";

import useTableActionsState from "hooks/useTableActionsState";
import AlgoliaSearchFilter from "components/AlgoliaSearchFilter";
import { TableViewType } from "types/baTypes";

type TableHeaderBottomProps = {
  barControlsEnabled?: boolean;
  tableSlug: string;
  totalCount?: number;
  inSidebar?: boolean;
  children: React.ReactNode;
  searchTableId?: string;
  inAddMany?: boolean;
  tableFiltersOption?: TableViewType;
};

const TableHeaderBottom = ({
  barControlsEnabled = false,
  tableSlug,
  totalCount,
  inSidebar,
  children,
  searchTableId,
  inAddMany,
  tableFiltersOption
}: TableHeaderBottomProps) => {
  const { getTotalCountByTableSlug } = useTableActionsState();

  const finalTotalCount = getTotalCountByTableSlug(tableSlug, totalCount);

  if (inAddMany && searchTableId) {
    return (
      <>
        <div className={clsx(!!finalTotalCount && barControlsEnabled && "h-[60px] bg-transparent")}>{children}</div>
        <div className="border-separator border-b px-5 py-2">
          <AlgoliaSearchFilter
            searchTableId={searchTableId}
            tableViewFilters={tableFiltersOption}
            tableSlug={tableSlug}
            inputClassName="!bg-transparent !border-none !shadow-none focus:!ring-0 focus:!outline-none focus-visible:!ring-0 focus-visible:!outline-none"
          />
        </div>
      </>
    );
  }

  return <div className={clsx(inSidebar ? "hidden" : "h-[60px] bg-transparent")}>{children}</div>;
};

export default TableHeaderBottom;
