"use client";
import { LayoutTemplateIcon } from "lucide-react";
import clsx from "clsx";

import IconButton from "components/IconButton";
import useUIState from "hooks/useUIState";
import useTableActionsState from "hooks/useTableActionsState";

type TableHeaderTitleProps = {
  inDetailView?: boolean;
  tableSlug: string;
  totalCount?: number;
  children?: React.ReactNode;
  isLoading?: boolean;
};

const TableHeaderTitle = ({ children, inDetailView, tableSlug, totalCount, isLoading }: TableHeaderTitleProps) => {
  const { uiState, updateUIState } = useUIState();
  const { getTotalCountByTableSlug } = useTableActionsState();

  const finalTotalCount = getTotalCountByTableSlug(tableSlug, totalCount);

  return (
    <>
      {!inDetailView && uiState?.menuPosition === "left" && (
        <IconButton icon={LayoutTemplateIcon} onClick={() => updateUIState({ isCollapsed: !uiState?.isCollapsed })} />
      )}
      <>
        <>{children}</>
        <span
          className={clsx(
            "text-secondary pl-3 text-[14px] font-normal",
            isLoading || finalTotalCount === undefined ? "h-[21px] w-[25px] animate-pulse rounded-full bg-skeleton" : ""
          )}
        >
          {finalTotalCount ? `${finalTotalCount.toLocaleString()}` : null}
        </span>
      </>
    </>
  );
};

export default TableHeaderTitle;
