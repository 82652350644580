import React from "react";
import clsx from "clsx";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";

type MenuLinkButtonProps = {
  label: string;
  wrapper: React.ReactElement;
  active?: boolean;
  open?: boolean;
  showArrow?: boolean;
  className?: string;
};

const MenuLinkButton = React.forwardRef(
  ({ showArrow, label, wrapper, open, className, ...rest }: MenuLinkButtonProps, ref) => {
    const classNameIcon = "h-5 w-5 ml-2 text-neutral-300";

    return React.cloneElement(wrapper, {
      ...rest,
      "data-testid": "MenuLinkButton",
      ref: ref,
      className: clsx(
        "inline-flex w-full cursor-pointer items-center justify-center bg-neutral-0 dark:bg-neutral-dark-0 dark:text-neutral-0 p-4 text-sm text-base-secondary hover:bg-neutral-100 active:bg-neutral-200",
        className
      ),
      children: (
        <>
          {label}
          {showArrow && open && <ChevronUpIcon className={classNameIcon} aria-hidden />}
          {showArrow && !open && <ChevronDownIcon className={classNameIcon} aria-hidden />}
        </>
      ),
    });
  }
);

MenuLinkButton.displayName = "MenuLinkButton";

export default MenuLinkButton;
