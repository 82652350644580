"use client";
import { AlertTriangle } from "lucide-react";
import Link from "next/link";

import IconButton from "components/IconButton";
import Tooltip from "components/Tooltip";
import useTableActionsState from "hooks/useTableActionsState";
import useCurrentUser from "hooks/useCurrentUser";

const PageErrorDisplay = () => {
  const { pageConfigErrors } = useTableActionsState();
  const currentUser = useCurrentUser();

  if (!pageConfigErrors?.length || !currentUser?.is_admin) {
    return null;
  }

  return (
    <Tooltip
      title={
        <div className="flex h-full w-[200px] flex-col p-3">
          {pageConfigErrors?.map((error) => (
            <span className="my-2 w-full text-sm text-white" key={error.message}>
              {error.message}
            </span>
          ))}
          {pageConfigErrors?.length ? (
            <div className="mt-4 text-sm text-blue-200 underline underline-offset-2">
              <Link
                href={`/admin/tables/${pageConfigErrors?.[0]?.parentPageId || pageConfigErrors?.[0]?.pageId}/basics`}
              >
                Fix in Admin
              </Link>
            </div>
          ) : null}
        </div>
      }
    >
      <IconButton icon={AlertTriangle} color="red" />
    </Tooltip>
  );
};

export default PageErrorDisplay;
