import algoliasearch from "algoliasearch";
import { AlgoliaSearchTable, Page, TableColumnType } from "types/baTypes";
import { RecordItem } from "types/common";
import { ApiRecordType, GenericCellColumn } from "types/apiTypes";

import { CellType, LookupTypes, RELATION_TYPES } from "./constants";
import { appendRecordTypeLookupToCol } from "./columnUtils";
import { generateFinalDataColumns } from "./dataUtils";
import { ExtendedSchema } from "./schema";

export const algoliaAppId: string | undefined = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID ?? "";
export const algoliaApiKey: string | undefined = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY ?? "";
export const algoliaWriteApiKey: string | undefined = process.env.ALGOLIA_WRITE_API_KEY ?? "";

export const algoliaSearchClient = algoliasearch(algoliaAppId, algoliaApiKey);

export const algoliaWriteClient = algoliasearch(algoliaAppId, algoliaWriteApiKey);

export const getAllValidColumnsForSearch = ({
  searchTableData,
  linkedPageColumns,
  action,
  pageData,
  recordTypesData,
  extendedSchema
}: {
  searchTableData: AlgoliaSearchTable;
  linkedPageColumns: TableColumnType[];
  action: "update" | "save";
  pageData?: Page;
  recordTypesData?: ApiRecordType[];
  extendedSchema?: ExtendedSchema;
}): TableColumnType[] => {
  // Combine master config linked page columns with search table columns for fetching data
  // if not already present in search table columns
  // For update we always push all master index columns
  const masterConfigLinkedCols: TableColumnType[] = [];
  if (searchTableData?.enableMasterIndex && searchTableData.masterIndexConfig) {
    const nameCol = linkedPageColumns.find((col) => col.id === searchTableData.masterIndexConfig?.nameColumn);
    if (
      (nameCol && action === "update") ||
      (nameCol && action !== "update" && !searchTableData.columns?.find((col) => col.id === nameCol?.id))
    ) {
      masterConfigLinkedCols.push(nameCol as TableColumnType);
    }
    searchTableData.masterIndexConfig?.coreAttributesColumns?.forEach((colEntry: RecordItem) => {
      if (colEntry.type === "column") {
        const linkedCol = linkedPageColumns?.find((col) => col.id === colEntry.value);
        if (
          linkedCol &&
          (action === "update" ||
            (action === "save" && !searchTableData.columns?.find((col) => col.id === linkedCol.id)))
        ) {
          masterConfigLinkedCols.push(linkedCol);
        }
      }
    });
    if (searchTableData?.masterIndexConfig?.linkedConfig?.length) {
      const joinTablesAdded: string[] = [];
      // We need to add all these columns to sync
      searchTableData.masterIndexConfig?.linkedConfig?.forEach((linkedConfig) => {
        if (linkedConfig?.type === RELATION_TYPES.FOREIGN) {
          masterConfigLinkedCols.push({
            id: linkedConfig.columnName,
            name: linkedConfig.columnName || "",
            header: linkedConfig.columnName || "",
            type: CellType.TEXT,
            isLookup: false
          } as TableColumnType);
        } else if (linkedConfig?.type === RELATION_TYPES.JOIN) {
          if (joinTablesAdded.includes(linkedConfig.tableName)) {
            // We need to update lookupColumns in the lookupPath for this table
            masterConfigLinkedCols.forEach((col) => {
              if (
                col.isLookup &&
                col.lookupPath?.[0]?.lookupTableName === linkedConfig.tableName &&
                col.lookupPath[0].lookupColumns?.length
              ) {
                col.lookupPath[0].lookupColumns.push(linkedConfig.columnName);
              }
            });
          } else {
            masterConfigLinkedCols.push({
              id: linkedConfig.syncLabel || linkedConfig.columnName,
              name: "",
              header: "join_" + linkedConfig.tableName || "",
              type: CellType.SELECT,
              isLookup: true,
              lookupPath: {
                "0": {
                  lookupTableName: linkedConfig.tableName,
                  lookupColumns: linkedConfig?.hasIsActive
                    ? [linkedConfig.columnName, "is_active"]
                    : [linkedConfig.columnName],
                  lookupType: LookupTypes.JOIN,
                  lookupColumnLabel: linkedConfig.syncLabel,
                  lookupTableHint: linkedConfig.useColumnHint || undefined
                }
              }
            } as TableColumnType);
            joinTablesAdded.push(linkedConfig.tableName);
          }
        }
      });
    }
  }

  const finalSearchDataCols: Partial<TableColumnType>[] = [];
  // If action is update, then we only pick the columns added for page_id
  // we don't have to refetch all the other columns
  if (action === "update" && pageData?.columns?.length) {
    searchTableData.columns?.forEach((col) => {
      if (pageData.columns?.find((pageCol) => pageCol.id === col.id)) {
        finalSearchDataCols.push(col);
      }
    });
  } else {
    finalSearchDataCols.push(...searchTableData.columns);
  }

  // We need to add display result column always, as this resyncs master index
  // uniqueAttributes, which needs these values
  if (searchTableData.enableMasterIndex && searchTableData?.resultConfig?.resultColumn) {
    const { genericConfig } = searchTableData?.resultConfig?.resultColumn?.cellConfig || {};
    if (genericConfig?.layout === "search_result") {
      // Add title column
      if (genericConfig?.title?.columnId) {
        const titleCol = linkedPageColumns.find((col) => col.id === genericConfig.title?.columnId);
        if (titleCol) {
          finalSearchDataCols.push(titleCol);
        }
      }
      if (Array.isArray(genericConfig?.subTitle) && (genericConfig?.subTitle as GenericCellColumn[])?.length) {
        (genericConfig?.subTitle as GenericCellColumn[])?.forEach((subTitleVal) => {
          if (subTitleVal?.columnId) {
            const subTitleCol = linkedPageColumns.find((col) => col.id === subTitleVal?.columnId);
            if (subTitleCol) {
              finalSearchDataCols.push(subTitleCol);
            }
          }
        });
      }
    }
  }
  const finalCols = [...finalSearchDataCols, ...masterConfigLinkedCols];
  // Dedupe
  const colIdsInFinalCols: string[] = [];
  const finalColsDeduped = finalCols.filter((col) => {
    if (col?.id && !colIdsInFinalCols.includes(col?.id)) {
      colIdsInFinalCols.push(col?.id);
      return true;
    }
    return false;
  });
  const finalRecordTypeAppendCols = finalColsDeduped
    .map((col) =>
      appendRecordTypeLookupToCol(
        col as TableColumnType,
        recordTypesData || [],
        searchTableData.tableName,
        extendedSchema
      )
    )
    .filter(Boolean) as TableColumnType[];
  const finalDataCols = generateFinalDataColumns({
    columns: finalRecordTypeAppendCols,
    recordTypesData,
    tableName: searchTableData.tableName,
    pickAllColumns: true,
    extendedSchema: extendedSchema
  });

  return finalDataCols as TableColumnType[];
};
