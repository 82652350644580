"use client";

import { useState } from "react";
import { Popover } from "@headlessui/react";
import Popper from "@mui/material/Popper";
import { EyeOff as VisibilityIcon } from "lucide-react";
import sortBy from "lodash/sortBy";
import Button from "components/Button";
import Switch from "components/Switch";
import { TableColumnType } from "types/baTypes";
import { ButtonAppearance, ViewOption } from "utils/constants";
import useTableActionsState from "hooks/useTableActionsState";
import Tooltip from "components/Tooltip";

type ViewColumnSettingsProps = {
  activeView: ViewOption;
  columns: TableColumnType[];
  tableSlug: string;
};

const ViewColumnSettings = ({ columns, activeView = ViewOption.GRID, tableSlug }: ViewColumnSettingsProps) => {
  const { columnVisibilityModelByTableSlug, updateColumnVisibilityModelByTableSlug } = useTableActionsState();

  const columnVisibilityModel = columnVisibilityModelByTableSlug?.[tableSlug]?.[activeView];

  const [targetElement, setTargetElement] = useState<HTMLButtonElement | null>();

  const sortedColumns = sortBy(
    (columns || []).filter((col) => !!col.views?.[activeView]?.id && !col.views?.[activeView]?.isHidden),
    `sortOrder.${activeView}`
  );

  const onChange = (column: TableColumnType, checked: boolean) => {
    updateColumnVisibilityModelByTableSlug(tableSlug, column.id, checked, activeView);
  };

  return (
    <Popover data-testid="ColumnSettings" className="relative">
      {({ open }) => {
        const id = open ? "popper" : undefined;

        return (
          <>
            <Popover.Button ref={setTargetElement}>
              <Tooltip title="Toggle column visibility">
                <Button
                  as={<div />}
                  label=""
                  icon={VisibilityIcon}
                  appearance={ButtonAppearance.SECONDARY}
                  className="!px-2.5"
                />
              </Tooltip>
            </Popover.Button>
            <Popper
              open={open}
              id={id}
              anchorEl={targetElement}
              disablePortal={false}
              className="z-50"
              placement="bottom-start"
            >
              <Popover.Panel className="text-primary bg-dropdown border-dropdown fixed inset-0 z-10 border lg:absolute lg:-left-10 lg:bottom-auto lg:right-auto lg:top-auto lg:mt-2 lg:rounded-lg lg:shadow-100 dark:lg:shadow-dark100">
                <div className="max-h-80 overflow-auto px-2 py-5">
                  {sortedColumns.map((column) => {
                    const enabled = columnVisibilityModel?.[column.id] ?? true;

                    return (
                      <Switch
                        key={column.id}
                        checked={enabled}
                        onChange={(checked: boolean) => onChange(column, checked)}
                        name={column.nickname || column.header}
                      />
                    );
                  })}
                </div>
              </Popover.Panel>
            </Popper>
          </>
        );
      }}
    </Popover>
  );
};

export default ViewColumnSettings;
