"use client";
import { useEffect, useRef } from "react";
import { useMediaQuery } from "usehooks-ts";

import ControlDropdown from "components/ControlDropdown";
import GenericSelect from "components/GenericSelect";
import useTableActionsState from "hooks/useTableActionsState";
import { SortItem, TableColumnType } from "types/baTypes";
import { SelectOption } from "types/common";
import { ControlType, DB_VIEWS, sortOptions } from "utils/constants";
import useSortUpdate from "hooks/useSortUpdate";

type SortingsProps = {
  columns: TableColumnType[];
  tableSlug: string;
  isViewFiltered?: boolean;
  allColumns?: TableColumnType[];
  globalSort?: SortItem[];
  tableName?: string;
};

const Sortings = ({ columns, tableSlug, allColumns, globalSort, tableName }: SortingsProps) => {
  const { sortingByTableSlug } = useTableActionsState();
  const { handleSortChange, handleUpdateSortingForColumn, handleUpdateSorting, handleClearSort } = useSortUpdate({
    tableSlug,
    columns: allColumns
  });
  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const globalSortForTable = useRef("");

  useEffect(() => {
    if (!allColumns?.length || globalSortForTable?.current === tableSlug || !tableName || DB_VIEWS.includes(tableName))
      return;
    if (!globalSort?.length) {
      // Check if all columns contains ID column use that first
      const idCol = allColumns.find((col) => col.name === "id");
      if (idCol?.id) {
        handleUpdateSorting([
          {
            ...idCol,
            desc: true,
            sortSlug: tableSlug
          }
        ]);
        globalSortForTable.current = tableSlug;
        return;
      }
      handleUpdateSorting([
        {
          id: "id_sort",
          name: "id",
          desc: true,
          sortSlug: tableSlug
        }
      ]);
      globalSortForTable.current = tableSlug;
      return;
    }
    handleUpdateSorting(globalSort.map((item) => ({ ...item, sortSlug: tableSlug })));
    globalSortForTable.current = tableSlug;
  }, [tableSlug, globalSort, allColumns, handleUpdateSorting, tableName]);

  const renderSorting = (col: TableColumnType) => {
    const sortingItem = sortingByTableSlug?.[tableSlug]?.find((item) => item.id === col.id);

    return (
      <GenericSelect
        options={sortOptions}
        onSelect={(option) => handleSortChange(col.id, (option as SelectOption)?.value as string)}
        showSearch={false}
        initialValues={sortingItem?.desc ? sortOptions[1] : sortOptions[0]}
        classNameOptions="max-lg:right-0"
        isSorted={false}
        position={isDesktop ? "left" : "right"}
      />
    );
  };

  return (
    <ControlDropdown
      type={ControlType.SORT}
      columns={columns}
      renderItem={renderSorting}
      rows={sortingByTableSlug?.[tableSlug] || []}
      onUpdateRow={handleUpdateSortingForColumn}
      onRemoveRow={handleClearSort}
    />
  );
};

export default Sortings;
