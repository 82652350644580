"use client";

import { useCallback, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { PlusIcon } from "lucide-react";

import Button from "components/Button";
import { ComboBoxValue } from "components/ui/ComboBox";
import Tooltip from "components/Tooltip";
import Modal from "components/Modal";
import TextField from "components/TextField";
import Dropdown from "components/Dropdown";
import Select from "components/Select";

import LexoRank from "lib/LexoRank";

import useTableActionsState from "hooks/useTableActionsState";
import useCurrentUser from "hooks/useCurrentUser";
import useUpsertDeleteSections from "hooks/useUpsertDeleteSections";
import useUpsertDeleteFilters from "hooks/useUpsertDeleteFilters";

import { APP_FILTER_TYPES, ButtonAppearance, ModalSize, ViewOption } from "utils/constants";
import { RecordItem, SelectOption } from "types/common";
import { TableColumnType, TableViewConfig, ViewSection } from "types/baTypes";

type SaveUserViewSectionProps = {
  tableSlug: string;
  currentSection?: ViewSection;
  lastSectionSort?: string;
  currentViewConfig?: TableViewConfig;
  onSuccess?: () => void;
  columns?: TableColumnType[];
  activeView?: ViewOption;
};

const SAVE_SECTION_OPTIONS = [
  {
    label: "Save as User Section",
    id: "user_section"
  },
  {
    label: "Save as Public Section",
    id: "public_section"
  }
];

const SaveUserViewSection = ({
  tableSlug,
  currentSection,
  lastSectionSort,
  currentViewConfig,
  onSuccess,
  columns,
  activeView
}: SaveUserViewSectionProps) => {
  const queryClient = useQueryClient();
  const currentUser = useCurrentUser();
  const { filtersByTableSlug } = useTableActionsState();
  const { addNewSection } = useUpsertDeleteSections();
  const { handleFilterChange } = useUpsertDeleteFilters();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [currentSectionType, setCurrentSectionType] = useState<ComboBoxValue | null>(null);
  const [selectedColumns, setSelectedColumns] = useState<SelectOption[]>([]);
  const hasFilters = !!filtersByTableSlug?.[tableSlug]?.length;

  const columnOptions = useMemo(() => {
    return columns
      ?.filter((c) => !!(activeView && c.views?.[activeView]?.id) && !c.isFilterTabCol)
      .map((col) => ({
        title: col.nickname || col.header,
        value: col.id,
        column: col
      }));
  }, [columns, activeView]);

  const onAddNewSectionSuccess = useCallback(() => {
    queryClient.invalidateQueries({ queryKey: ["page", tableSlug] });
    queryClient.removeQueries({ queryKey: ["page", tableSlug] });
    queryClient.invalidateQueries({ queryKey: ["userViewSections", currentUser?.user_id] });
    queryClient.removeQueries({ queryKey: ["userViewSections", currentUser?.user_id] });
    onSuccess?.();
  }, [queryClient, currentUser?.user_id, onSuccess, tableSlug]);

  const handleAddFilters = useCallback(
    async (newSection: RecordItem, filterInputs: RecordItem[]) => {
      if (filterInputs?.length) {
        handleFilterChange(filterInputs, {
          filterType: APP_FILTER_TYPES.SECTION_FILTER,
          onSuccess: onAddNewSectionSuccess,
          sectionId: newSection?.id
        });
      }
    },
    [onAddNewSectionSuccess, handleFilterChange]
  );

  const handleSaveSectionButton = useCallback(
    (sectionType?: ComboBoxValue | null) => {
      if (sectionType) {
        if (!currentSection?.id) {
          setSelectedColumns(columnOptions || []);
        }
        setCurrentSectionType(sectionType);
        setIsModalOpen(true);
      }
    },
    [columnOptions, currentSection?.id]
  );

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSectionName("");
    setCurrentSectionType(null);
    setSelectedColumns([]);
  }, []);

  const sectionMenuItems = useMemo(() => {
    if (!hasFilters) {
      return [
        {
          label: "Save as Public Section",
          id: "public_section",
          onClick: () => handleSaveSectionButton("public_section")
        }
      ];
    }
    return SAVE_SECTION_OPTIONS.map((opt) => {
      return {
        ...opt,
        id: opt.id,
        onClick: () => handleSaveSectionButton(opt.id)
      };
    });
  }, [hasFilters, handleSaveSectionButton]);

  const handleSaveViewSection = useCallback(() => {
    if (
      !currentUser?.user_id ||
      (currentSectionType === "user_section" && !filtersByTableSlug?.[tableSlug]?.length) ||
      !currentViewConfig?.viewId
    ) {
      return;
    }
    const rank = lastSectionSort ? LexoRank.from(lastSectionSort) : LexoRank.getNewRank([], 0, 0);
    const finalColumns = currentSection?.id ? currentSection?.columns : columnOptions?.map((col) => col.column);
    const sectionInput = {
      id: "new",
      title: sectionName ? sectionName : `${currentSection?.title || ""} - Copy`,
      description: currentSection?.description,
      sort: rank?.increment().toString(),
      viewId: currentViewConfig?.isCustom ? currentViewConfig?.id : undefined,
      pageViewId: currentViewConfig?.isCustom ? undefined : currentViewConfig?.id,
      userId: currentSectionType === "user_section" ? currentUser?.user_id : null,
      columns: finalColumns
    };
    const filterInputs: RecordItem[] = [];
    filtersByTableSlug?.[tableSlug]?.forEach((filter) => {
      filterInputs.push({
        pageColumnId: filter.pageColumnId,
        filterOperator: filter.filterOperator,
        filterValue: !filter.column?.isLookup ? filter.filterValue : null,
        filterOptionValue: filter.column?.isLookup ? filter.filterValue : null,
        isUserVisible: true
      });
    });

    addNewSection(sectionInput, {
      currentView: currentViewConfig?.viewType,
      onSuccess: (newSection) => {
        if (newSection?.id) {
          handleAddFilters(newSection, filterInputs);
          handleCloseModal();
        }
      }
    });
  }, [
    currentUser?.user_id,
    filtersByTableSlug,
    tableSlug,
    currentSection,
    addNewSection,
    handleAddFilters,
    lastSectionSort,
    currentViewConfig?.viewType,
    currentViewConfig?.viewId,
    sectionName,
    currentSectionType,
    handleCloseModal,
    currentViewConfig?.id,
    currentViewConfig?.isCustom,
    columnOptions
  ]);

  return (
    <>
      {" "}
      <Tooltip title="Save view section options" placement="top">
        <div key="save-user-section">
          <Dropdown
            MenuButton={
              <Button
                label=""
                appearance={ButtonAppearance.TERTIARY}
                icon={PlusIcon}
                className="whitespace-nowrap !px-2.5"
              />
            }
            items={sectionMenuItems}
          />
        </div>
      </Tooltip>
      <Modal size={ModalSize.MD} isOpen={isModalOpen} onClose={handleCloseModal}>
        <div className="flex flex-col p-4">
          <TextField
            label="Section Name"
            className="mb-4"
            value={sectionName || ""}
            onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => setSectionName(value)}
            required
          />
          {!currentSection?.id ? (
            <Select
              value={selectedColumns}
              label="Select Columns"
              onChange={(options: SelectOption | SelectOption[] | null) => {
                setSelectedColumns(options as SelectOption[]);
              }}
              multiple
              options={columnOptions?.filter((item) => {
                return activeView && item?.column?.views?.[activeView]?.id;
              })}
            />
          ) : null}
          <div className="flex w-full flex-col items-center">
            <Button
              label="Save"
              appearance={ButtonAppearance.PRIMARY}
              className="mt-5 whitespace-nowrap !px-2"
              onClick={handleSaveViewSection}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SaveUserViewSection;
