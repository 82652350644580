import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { getPageUserViewSections } from "lib/adminApi";
import { mapApiPageViewsToPageViews } from "utils/pageUtils";

import useCurrentUser from "./useCurrentUser";

const useUserPageViewSections = (pageId?: string) => {
  const currentUser = useCurrentUser();
  const supabaseClient = useSupabaseBrowser();

  const {
    data: userViewSections,
    refetch,
    isLoading
  } = useQuery({
    queryKey: ["userViewSections", currentUser?.user_id, pageId],
    queryFn: () =>
      getPageUserViewSections({
        supabaseClient,
        userId: currentUser?.user_id,
        pageId,
        organizationId: currentUser?.org_id
      }),
    enabled: !!currentUser?.id && !!pageId,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    staleTime: Infinity,
    gcTime: Infinity
  });

  const finalViews = useMemo(() => {
    if (!userViewSections?.data?.views) return undefined;
    return mapApiPageViewsToPageViews(userViewSections?.data?.views);
  }, [userViewSections?.data?.views]);

  return {
    data: finalViews,
    refetch,
    isLoading
  };
};

export default useUserPageViewSections;
