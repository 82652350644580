"use client";

import { useMemo } from "react";
import { usePathname } from "next/navigation";
import { LayersIcon } from "lucide-react";

import Button from "components/Button";
import Tooltip from "components/Tooltip";
import useInLayout from "hooks/useInLayout";
import useTableActionsState from "hooks/useTableActionsState";
import { ButtonAppearance, ViewOption } from "utils/constants";
import { getActiveViewFromPathName } from "utils/server";

type CollapseExpandProps = {
  tableSlug: string;
  activeView: ViewOption;
};

const CollapseExpand = ({ tableSlug, activeView }: CollapseExpandProps) => {
  const { groupByInfoByTableSlug, updateGroupByInfoByTableSlug } = useTableActionsState();
  const pathname = usePathname();
  const { isTable } = useInLayout();

  const finalActiveView = useMemo(() => {
    if (isTable) {
      return getActiveViewFromPathName(pathname);
    }

    return activeView;
  }, [activeView, isTable, pathname]);

  if (
    ([ViewOption.GRID, ViewOption.CARD].includes(finalActiveView) &&
      groupByInfoByTableSlug?.[tableSlug]?.[finalActiveView]?.enabled) ||
    finalActiveView === ViewOption.BOARD
  ) {
    return (
      <Tooltip title="Collapse/Expand Rows">
        <Button
          icon={LayersIcon}
          onClick={() =>
            updateGroupByInfoByTableSlug(
              {
                isCollapsed: !groupByInfoByTableSlug?.[tableSlug]?.[finalActiveView]?.isCollapsed,
              },
              tableSlug,
              finalActiveView
            )
          }
          label=""
          appearance={ButtonAppearance.SECONDARY}
          className="!px-2.5"
        />
      </Tooltip>
    );
  }

  return null;
};

export default CollapseExpand;
