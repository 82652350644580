import isEmpty from "lodash/isEmpty";
import { ReadonlyURLSearchParams } from "next/navigation";
import querystring from "query-string";
import { useCallback } from "react";

import {
  CURRENT_USER_FILTER_ID,
  TableColumnTypeAndFilters,
  generateFilterFromColumn,
  getValidFilterTypes
} from "components/Bar/Filters/utils";
import { TableColumnType, TableFilterType } from "types/baTypes";
import { FILTERS_PARAMS_SET, RecordItem, SelectOption } from "types/common";
import {
  APP_QUERY_PARAM_TYPES,
  CellType,
  FILTER_GROUP_TYPE,
  FILTER_OPERATOR,
  FILTER_OPERATOR_SEARCH_PARAMS
} from "utils/constants";
import { isColumnFileTag, isColumnNestedFileTag } from "utils/dataUtils";
import { getFilterValueAndRelatedProps, getFilterValueFromDbFilterValue, isColumnFileType } from "utils/filterUtils";
import { getColumnSearchParamName } from "utils/paramsUtils";

import useSchemaState from "./useSchemaState";
import useSearchQueryParams from "./useSearchQueryParams";
import useTableActionsState from "./useTableActionsState";

const useFilterUpdate = (tableSlug: string, columns: TableColumnType[], inAddMany?: boolean) => {
  const { filtersByTableSlug, updateFiltersByTableSlug, updatePaginationByTableSlug, paginationByTableSlug } =
    useTableActionsState();
  const { schemaInstance } = useSchemaState();
  const { setParams, clearParams } = useSearchQueryParams();
  const finalTableSlug = inAddMany ? `${tableSlug}_add_many` : tableSlug;

  const handleSeparateUpdateFilterValue = useCallback(
    (colId: string, value: string | SelectOption | boolean) => {
      const column = columns?.find((col) => col.id === colId);

      if (!tableSlug || !column) return;

      const isNewFilter = !filtersByTableSlug?.[tableSlug]?.find((filter: TableFilterType) => filter.id === colId);

      const selectOptions = getValidFilterTypes(column);
      const operator = selectOptions?.length ? selectOptions[0].value : "";

      const newFilter = generateFilterFromColumn({ column, operator, extendedSchema: schemaInstance?.extendedSchema });

      const finalFilters = (filtersByTableSlug?.[tableSlug] || [])?.concat(isNewFilter ? [newFilter] : []);

      const updatedFilters: TableFilterType[] = [];
      finalFilters?.forEach((filter) => {
        if (filter.id === colId) {
          const filterValProps = getFilterValueAndRelatedProps(filter, value);
          updatedFilters.push({
            ...filter,
            ...filterValProps
          });
        } else {
          updatedFilters.push(filter);
        }
      });
      setParams({
        filters: updatedFilters.map((item: TableFilterType) => {
          return {
            column: item,
            filterOperator: item.filterOperator,
            filterValue: item.filterValue,
            filterSlug: tableSlug,
            filterOrGroup: item.filterGroup
          } as FILTERS_PARAMS_SET;
        })
      });

      // Reset pagination
      if (paginationByTableSlug?.[finalTableSlug]?.currentPage !== 1) {
        updatePaginationByTableSlug(
          {
            ...paginationByTableSlug?.[finalTableSlug],
            currentPage: 1
          },
          tableSlug,
          { inAddMany: !!inAddMany }
        );
      }
    },
    [
      filtersByTableSlug,
      columns,
      paginationByTableSlug,
      schemaInstance?.extendedSchema,
      tableSlug,
      updatePaginationByTableSlug,
      setParams,
      finalTableSlug,
      inAddMany
    ]
  );

  const handleUpdateFilterValue = useCallback(
    ({
      colId,
      value,
      isInOrGroup,
      orFilterIndex
    }: {
      colId: string;
      value: string | SelectOption | boolean;
      isInOrGroup?: boolean;
      orFilterIndex?: number;
    }) => {
      const selectedCol = columns?.find((col) => col.id === colId);
      const selectedFilter = filtersByTableSlug?.[tableSlug]?.find((row) =>
        isInOrGroup ? row.filterGroup?.find((filterRow: TableFilterType) => filterRow.id === colId) : row.id === colId
      );

      if (selectedCol && selectedFilter) {
        const updatedFilters: TableFilterType[] = [];
        filtersByTableSlug?.[tableSlug]?.forEach((filterRow) => {
          if (isInOrGroup) {
            if (filterRow.id !== selectedFilter.id) return filterRow;

            const updatedFilterGroup: TableFilterType[] = [];
            filterRow.filterGroup?.forEach((filter, index) => {
              if (filter.id === colId && index === orFilterIndex) {
                const filterValProps = getFilterValueAndRelatedProps(filter, value);

                updatedFilterGroup.push({
                  ...filter,
                  ...filterValProps
                });
              } else {
                updatedFilterGroup.push(filter);
              }
            });
            updatedFilters.push({
              ...filterRow,
              filterGroup: updatedFilterGroup
            });
          } else if (filterRow.id === colId) {
            const filterValProps = getFilterValueAndRelatedProps(filterRow, value);
            updatedFilters.push({
              ...filterRow,
              ...filterValProps
            });
          } else {
            updatedFilters.push(filterRow);
          }
        });
        setParams({
          filters: updatedFilters.map((item: TableFilterType) => {
            return {
              column: item,
              filterOperator: item.filterOperator,
              filterValue: item.filterValue,
              filterSlug: tableSlug,
              filterOrGroup: item.filterGroup
            } as FILTERS_PARAMS_SET;
          })
        });
      }

      // Reset pagination
      if (paginationByTableSlug?.[finalTableSlug]?.currentPage !== 1) {
        updatePaginationByTableSlug(
          {
            ...paginationByTableSlug?.[finalTableSlug],
            currentPage: 1
          },
          tableSlug,
          { inAddMany: !!inAddMany }
        );
      }
    },
    [
      filtersByTableSlug,
      paginationByTableSlug,
      tableSlug,
      updatePaginationByTableSlug,
      columns,
      setParams,
      finalTableSlug,
      inAddMany
    ]
  );

  const handleUpdateOperator = useCallback(
    ({
      colId,
      filterOperator,
      isInOrGroup,
      resetFilterValue = true,
      orFilterIndex
    }: {
      colId: string;
      filterOperator?: string;
      isInOrGroup?: boolean;
      resetFilterValue?: boolean;
      orFilterIndex?: number;
    }) => {
      const selectedCol = columns?.find((col) => col.id === colId);
      const selectedFilter = filtersByTableSlug?.[tableSlug]?.find((row) =>
        isInOrGroup ? row.filterGroup?.find((filterRow: TableFilterType) => filterRow.id === colId) : row.id === colId
      );

      if (selectedCol && selectedFilter) {
        const updatedFilters: TableFilterType[] = [];
        filtersByTableSlug?.[tableSlug]?.forEach((filterRow) => {
          if (isInOrGroup) {
            if (filterRow.id !== selectedFilter.id) return filterRow;

            const updatedFilterGroup: TableFilterType[] = [];
            filterRow.filterGroup?.forEach((filter, index) => {
              if (filter.id === colId && index === orFilterIndex) {
                let finalFilterValue = filter.filterValue;
                if (selectedCol?.dbType?.format === "boolean" || selectedCol.type === CellType.BOOLEAN) {
                  finalFilterValue = !!finalFilterValue;
                }
                updatedFilterGroup.push({
                  ...filter,
                  filterOperator,
                  filterValue: resetFilterValue ? undefined : finalFilterValue
                });
              } else {
                updatedFilterGroup.push(filter);
              }
            });
            updatedFilters.push({
              ...filterRow,
              filterGroup: updatedFilterGroup
            });
          } else if (filterRow.id === colId) {
            let finalFilterValue = filterRow.filterValue;
            if (selectedCol?.dbType?.format === "boolean" || selectedCol.type === CellType.BOOLEAN) {
              finalFilterValue = !!finalFilterValue;
            }
            updatedFilters.push({
              ...filterRow,
              filterOperator,
              filterValue: resetFilterValue ? undefined : finalFilterValue
            });
          } else {
            updatedFilters.push(filterRow);
          }
        });

        setParams({
          filters: updatedFilters.map((item: TableFilterType) => {
            return {
              column: item,
              filterOperator: item.filterOperator,
              filterValue: item.filterValue,
              filterSlug: tableSlug,
              filterOrGroup: item.filterGroup
            } as FILTERS_PARAMS_SET;
          })
        });
      }
    },
    [filtersByTableSlug, tableSlug, columns, setParams]
  );

  // Manages whether we need to add a filter group for OR
  const handleUpdateFilterGroup = useCallback(
    (filterGroupType: FILTER_GROUP_TYPE, colId: string) => {
      const updatedFilterIndex = filtersByTableSlug?.[tableSlug]?.findIndex((filter: TableFilterType) =>
        filterGroupType === FILTER_GROUP_TYPE.OR
          ? filter.id === colId
          : filter.filterOperator === FILTER_OPERATOR.OR
            ? filter.filterGroup?.some((filterRow) => filterRow.id === colId)
            : filter.id === colId
      );
      // We need to move the updated filter and the filter above it into a filterGroup
      if (updatedFilterIndex !== undefined && updatedFilterIndex > -1 && filterGroupType === FILTER_GROUP_TYPE.OR) {
        const updatedFilter = filtersByTableSlug?.[tableSlug]?.[updatedFilterIndex];
        const previousFilter = filtersByTableSlug?.[tableSlug]?.[updatedFilterIndex - 1];
        const updatedPreviousFilter = previousFilter?.filterGroup?.length
          ? {
              ...previousFilter,
              filterOperator: FILTER_OPERATOR.OR,
              filterGroup: [...(previousFilter?.filterGroup || []), updatedFilter]
            }
          : {
              ...previousFilter,
              filterOperator: FILTER_OPERATOR.OR,
              filterGroup: [previousFilter, updatedFilter]
            };
        const updatedFilters = filtersByTableSlug?.[tableSlug]
          ?.map((filter: TableFilterType) => {
            if (filter.id === colId) {
              return null;
            } else if (filter.id === previousFilter?.id) {
              return updatedPreviousFilter;
            } else {
              return filter;
            }
          })
          .filter((filter) => !!filter);

        setParams({
          filters: updatedFilters?.map(
            (filter) =>
              ({
                filterOperator: filter?.filterOperator,
                filterOrGroup: filter?.filterGroup,
                filterSlug: tableSlug,
                filterValue: filter?.filterValue,
                column: filter
              }) as FILTERS_PARAMS_SET
          )
        });
      } else if (
        updatedFilterIndex !== undefined &&
        updatedFilterIndex > -1 &&
        filterGroupType === FILTER_GROUP_TYPE.AND
      ) {
        // If previous filter is OR, we need to break up the filterGroup into separate filters
        const updatedFilterRow = filtersByTableSlug?.[tableSlug]?.find((row) =>
          row.filterGroup?.some((filterRow: TableFilterType) => filterRow.id === colId)
        );
        const updatedFilters: TableFilterType[] = [];
        filtersByTableSlug?.[tableSlug]?.forEach((filter: TableFilterType) => {
          if (filter.id === updatedFilterRow?.id && filter.filterOperator === FILTER_OPERATOR.OR) {
            const filterToExclude = filter.filterGroup?.find((filterRow) => filterRow.id === colId);
            const updatedFilterGroup = filter.filterGroup?.filter((filterRow) => filterRow.id !== colId);
            if (updatedFilterGroup?.length === 1) {
              updatedFilters.push(...(filter.filterGroup || []));
            } else {
              updatedFilters.push({
                ...filter,
                filterGroup: updatedFilterGroup
              });
              if (filterToExclude) {
                updatedFilters.push(filterToExclude);
              }
            }
          } else {
            updatedFilters.push(filter);
          }
        });
        setParams({
          filters: updatedFilters?.map(
            (filter) =>
              ({
                filterOperator: filter?.filterOperator,
                filterSlug: tableSlug,
                filterValue: filter?.filterValue,
                filterOrGroup: filter?.filterGroup,
                column: filter
              }) as FILTERS_PARAMS_SET
          )
        });
      }
    },
    [filtersByTableSlug, tableSlug, setParams]
  );

  const handleUpdateFilters = useCallback(
    (filtersUpdated: TableColumnTypeAndFilters[]) => {
      if ((filtersByTableSlug?.[tableSlug]?.length || -1) > 0 && filtersUpdated?.length === 0) {
        updatePaginationByTableSlug(
          {
            ...paginationByTableSlug?.[finalTableSlug],
            currentPage: 1
          },
          tableSlug,
          { inAddMany: !!inAddMany }
        );
      }

      if (!filtersUpdated?.length) {
        clearParams(APP_QUERY_PARAM_TYPES.FILTERS);
        return;
      }

      const finalUpdatedFilters = [...filtersUpdated];
      // check if last and second last filter id is same and convert to orFilter
      const lastFilter = finalUpdatedFilters.pop();
      const secondLastFilter = finalUpdatedFilters.pop();
      if (lastFilter && secondLastFilter && lastFilter.id === secondLastFilter.id) {
        finalUpdatedFilters.push({
          filterOperator: FILTER_OPERATOR.OR,
          filterGroup: [secondLastFilter, lastFilter]
        } as TableColumnTypeAndFilters);
      } else {
        // add them back if they are not same
        if (secondLastFilter) {
          finalUpdatedFilters.push(secondLastFilter);
        }
        if (lastFilter) {
          finalUpdatedFilters.push(lastFilter);
        }
      }

      setParams({
        filters: finalUpdatedFilters.map((item: TableColumnTypeAndFilters) => {
          const selectOptions = getValidFilterTypes(item);
          const operator = item.filterOperator
            ? item.filterOperator
            : selectOptions?.length
              ? selectOptions[0].value
              : "";
          const filterValProps = item.filterValue
            ? getFilterValueAndRelatedProps(item, item.filterValue)
            : { filterValue: "" };
          return {
            column: item,
            filterOperator: operator,
            filterSlug: tableSlug,
            filterOrGroup: item.filterGroup?.map((filterGroupRow) => ({
              column: filterGroupRow,
              filterOperator: filterGroupRow.filterOperator
                ? filterGroupRow.filterOperator
                : selectOptions?.length
                  ? selectOptions[0].value
                  : "",
              filterSlug: tableSlug,
              filterValue: filterGroupRow.filterValue
            })),
            filterValue:
              item?.dbType?.format === "boolean" || item.type === CellType.BOOLEAN
                ? !!filterValProps?.filterValue
                : filterValProps?.filterValue || ""
          } as FILTERS_PARAMS_SET;
        })
      });
    },
    [
      filtersByTableSlug,
      paginationByTableSlug,
      tableSlug,
      updatePaginationByTableSlug,
      clearParams,
      setParams,
      finalTableSlug,
      inAddMany
    ]
  );

  const getFilterColumnFromParams = useCallback(
    (paramKey: string, filterQueryValue: string | null) => {
      const filterParts = paramKey.split(":");
      const filterNameVal = filterParts[0];
      let filterOperator = filterParts[1];
      const filterCol = columns.find((col) => filterNameVal === getColumnSearchParamName(col));
      if (filterCol) {
        let filterValue: any = filterQueryValue ?? "";
        const matchedOperatorKey = Object.keys(FILTER_OPERATOR_SEARCH_PARAMS).find(
          (filterOp) =>
            filterOperator === FILTER_OPERATOR_SEARCH_PARAMS[filterOp as keyof typeof FILTER_OPERATOR_SEARCH_PARAMS]
        );
        if (matchedOperatorKey) {
          filterOperator = FILTER_OPERATOR[matchedOperatorKey as keyof typeof FILTER_OPERATOR];
        }

        let isFilterColumnJoinTable = false;
        let isFileTagNestedColumn = false; // When files_tags is a nested column in lookup
        let useFilterLookupColumn = false;
        let joinTableFilterColumn = "";
        if (filterCol?.isLookup) {
          if (
            isColumnFileType(filterCol) ||
            (filterCol?.dbType?.format === "integer" && filterCol?.type === CellType.TEXT)
          ) {
            useFilterLookupColumn = true;
          }
          const tableName = filterCol?.lookupPath?.[0]?.lookupTableName;
          if (tableName && schemaInstance?.extendedSchema?.[tableName]?.compositePk?.length) {
            isFilterColumnJoinTable = true;
          }
          isFileTagNestedColumn = isColumnNestedFileTag(filterCol);
          const isFilesTagColumn = isColumnFileTag(filterCol);

          if (isFileTagNestedColumn || isFilesTagColumn) {
            joinTableFilterColumn = "tag_id";
          }
          filterValue = getFilterValueFromDbFilterValue({ filterColumn: filterCol, filterValue });
        }
        return {
          ...filterCol,
          filterField: filterCol.name || filterNameVal.replace("f_", ""),
          filterOperator,
          filterValue,
          isFilterColumnJoinTable,
          innerAtAllLevels: !!isFilterColumnJoinTable || !!isFileTagNestedColumn,
          excludeFromInner: filterOperator === FILTER_OPERATOR.EMPTY,
          column: { ...filterCol },
          useFilterLookupColumn,
          joinTableFilterColumn
        };
      }
    },
    [columns, schemaInstance?.extendedSchema]
  );

  const updateFiltersFromSearchParams = useCallback(
    (queryParams: ReadonlyURLSearchParams | null) => {
      const newParams = querystring.parse((queryParams || "").toString(), {
        arrayFormat: "comma"
      });
      if (
        isEmpty(newParams) ||
        !tableSlug ||
        newParams?.[APP_QUERY_PARAM_TYPES.FILTERS] !== tableSlug ||
        !queryParams ||
        !Object.keys(newParams).find((key) => key.startsWith("f_"))
      ) {
        if (
          !Object.keys(newParams || {}).find((key) => key.startsWith("f_")) &&
          filtersByTableSlug?.[tableSlug]?.length
        ) {
          updateFiltersByTableSlug([], tableSlug);
        }
        return;
      }

      const filterCols: TableFilterType[] = [];
      Object.keys(newParams).forEach((paramKey) => {
        if (paramKey.startsWith("f_or")) {
          const groupType = paramKey.replace("f_or", "");
          const filterOrVal = newParams[paramKey];
          if (filterOrVal) {
            const filterOrParts = (filterOrVal as string).replace(/\(|\)/g, "");
            const orFilters = filterOrParts.split("|").filter(Boolean);
            const finalOrGroup: RecordItem[] = [];
            orFilters.forEach((orFilterString) => {
              const filterParts = orFilterString.split(".");
              const fCol = getFilterColumnFromParams(filterParts[0], filterParts[1]);
              finalOrGroup.push(fCol as TableFilterType);
            });
            filterCols.push({
              filterOperator: FILTER_OPERATOR.OR,
              filterGroup: finalOrGroup,
              id: groupType === CURRENT_USER_FILTER_ID ? CURRENT_USER_FILTER_ID : ""
            } as TableFilterType);
          }
        } else if (paramKey.startsWith("f_")) {
          const fCol = getFilterColumnFromParams(paramKey, queryParams?.get(paramKey));

          filterCols.push(fCol as TableFilterType);
        }
      });

      updateFiltersByTableSlug(filterCols, tableSlug);
    },
    [tableSlug, updateFiltersByTableSlug, filtersByTableSlug, getFilterColumnFromParams]
  );

  return {
    handleUpdateFilterValue,
    handleUpdateOperator,
    handleUpdateFilterGroup,
    handleUpdateFilters,
    handleSeparateUpdateFilterValue,
    updateFiltersFromSearchParams
  };
};

export default useFilterUpdate;
