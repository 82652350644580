import { useCallback, useMemo } from "react";
import {
  ArrowRight as SidebarIcon,
  CpuIcon,
  SearchIcon,
  SlidersHorizontalIcon,
  PencilIcon,
  BetweenVerticalEndIcon,
  PlusIcon,
  LibraryBigIcon,
  FilePlus2Icon,
  PaletteIcon
} from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { v4 } from "uuid";
import { useRouter } from "next/navigation";
import { ActionType, ButtonAppearance, CellType, SIDEBAR_TYPE, STATIC_SIDEBAR_IDS, ViewOption } from "utils/constants";
import Button from "components/Button";
import Dropdown, { DropDownItemProps } from "components/Dropdown";
import { ViewsIcon, ViewsLabel } from "components/ViewSelector/utils";
import Tooltip from "components/Tooltip";
import useTableActionsState from "hooks/useTableActionsState";
import useInLayout from "hooks/useInLayout";
import useAllPagesLite from "hooks/useAllPagesLite";
import usePageConfigState from "hooks/usePageConfigState";
import { CONFIG_MODAL_TYPES } from "types/common";

type AdminUserActionSelectorProps = {
  views: Partial<ViewOption>[];
  isDetailViewEnabled?: boolean;
  pageDataId?: string;
  tableSlug: string;
  tableName: string;
  activeView: ViewOption;
  searchTableId?: string;
};

const VIEWS_SORTED = [
  ViewOption.GRID,
  ViewOption.CARD,
  ViewOption.FORM,
  ViewOption.BOARD,
  ViewOption.MAP,
  ViewOption.ACTIVITY,
  ViewOption.CALENDAR,
  ViewOption.DETAIL_MAIN,
  ViewOption.MATRIX
];

const AdminUserActionSelector = ({
  views = [],
  isDetailViewEnabled,
  pageDataId,
  tableSlug,
  tableName,
  searchTableId,
  activeView
}: AdminUserActionSelectorProps) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { updateSidebarState } = useTableActionsState();
  const { isAdminPage, isRecord } = useInLayout();
  const { data: allPages } = useAllPagesLite();
  const { updatePageConfigModalState } = usePageConfigState();
  const pageData = useMemo(() => allPages?.find((page) => page.id === pageDataId), [allPages, pageDataId]);

  const onPageViewConfigUpdated = useCallback(() => {
    // Refetch page data for updated column config
    queryClient.invalidateQueries({ queryKey: ["page", pageDataId] });
    queryClient.invalidateQueries({ queryKey: ["page", tableSlug] });
  }, [queryClient, pageDataId, tableSlug]);

  const onOpenViewConfig = useCallback(
    ({
      view,
      showActionConfig,
      showSearchTableConfig,
      showFiltersConfig,
      showBulkEditConfig,
      showColumnsFormattingConfig
    }: {
      view?: ViewOption;
      showActionConfig?: boolean;
      showSearchTableConfig?: boolean;
      showFiltersConfig?: boolean;
      showBulkEditConfig?: boolean;
      showColumnsFormattingConfig?: boolean;
    }) => {
      if (!pageDataId) return;

      if (showSearchTableConfig) {
        if (searchTableId) {
          updateSidebarState(
            {
              showDetailView: false,
              searchTableConfigEditState: {
                isOpen: true,
                onActionComplete: onPageViewConfigUpdated,
                searchTableId: searchTableId
              }
            },
            STATIC_SIDEBAR_IDS.SEARCH_TABLE_CONFIG_EDIT_SIDEBAR
          );
        }
      } else {
        updateSidebarState(
          {
            showDetailView: false,
            pageViewConfigEditState: {
              isOpen: true,
              onActionComplete: onPageViewConfigUpdated,
              pageId: pageDataId,
              view: view ? view : undefined,
              showActionConfig: showActionConfig ?? false,
              showFiltersConfig: showFiltersConfig ?? false,
              showBulkEditConfig: showBulkEditConfig ?? false,
              showColumnsFormattingConfig: showColumnsFormattingConfig ?? false
            }
          },
          STATIC_SIDEBAR_IDS.PAGE_VIEW_CONFIG_EDIT_SIDEBAR
        );
      }
    },
    [updateSidebarState, pageDataId, onPageViewConfigUpdated, searchTableId]
  );

  const onAddRecordTypeClicked = useCallback(() => {
    updatePageConfigModalState({
      isOpen: true,
      type: CONFIG_MODAL_TYPES.RECORD_TYPE,
      additionalConfig: {
        pageId: pageDataId,
        tableName
      },
      onSuccess: () => {}
    });
  }, [pageDataId, tableName, updatePageConfigModalState]);

  const onAddChildPageClicked = useCallback(() => {
    updatePageConfigModalState({
      isOpen: true,
      type: CONFIG_MODAL_TYPES.BASIC_PAGE,
      additionalConfig: {
        pageId: pageDataId,
        parentPage: pageData,
        isChildPage: true
      },
      onSuccess: () => {}
    });
  }, [pageDataId, pageData, updatePageConfigModalState]);

  const onColumnAdded = useCallback(() => {
    router.refresh();
    // Refetch page data for updated column config
    queryClient.invalidateQueries({ queryKey: ["page", pageDataId] });
    queryClient.invalidateQueries({ queryKey: ["page", tableSlug] });
    // Refetch table data
    queryClient.invalidateQueries({ queryKey: ["table", tableName] });
  }, [queryClient, pageDataId, tableSlug, tableName, router]);

  const onAddColClicked = useCallback(() => {
    const newCol = {
      id: `new_${v4()}`,
      header: "",
      type: CellType.TEXT,
      name: ""
    };
    updateSidebarState(
      {
        isOpen: true,
        sidebarType: SIDEBAR_TYPE.ADMIN_COLUMN_EDIT,
        onActionComplete: onColumnAdded,
        additionalProps: {
          column: newCol,
          pageSlug: tableSlug,
          parentTableName: tableName,
          currentView: activeView,
          pageId: pageDataId,
          isNew: true
        }
      },
      STATIC_SIDEBAR_IDS.COLUMN_CONFIG_EDIT_SIDEBAR
    );
  }, [updateSidebarState, activeView, onColumnAdded, tableSlug, tableName, pageDataId]);

  const onAddExistingColClicked = useCallback(() => {
    updateSidebarState(
      {
        showDetailView: false,
        isOpen: false,
        pageViewConfigEditState: {
          isOpen: true,
          pageId: pageDataId || "",
          view: activeView,
          onActionComplete: onColumnAdded
        }
      },
      STATIC_SIDEBAR_IDS.PAGE_VIEW_CONFIG_EDIT_SIDEBAR
    );
  }, [updateSidebarState, activeView, onColumnAdded, pageDataId]);

  const items = useMemo(() => {
    const viewItems = VIEWS_SORTED?.filter((view) => views.includes(view)).concat(
      isDetailViewEnabled ? [ViewOption.DETAIL_MAIN] : []
    );
    const finalItems: DropDownItemProps[] = viewItems
      ?.map((view, index) => ({
        id: `${view}`,
        label: `${ViewsLabel[view]} View`,
        onClick: () => onOpenViewConfig({ view }),
        icon: ViewsIcon[view],
        hasDivider: index === viewItems.length - 1 ? true : false
      }))
      .concat([
        {
          id: "actions",
          label: "Actions",
          onClick: () => onOpenViewConfig({ showActionConfig: true }),
          icon: CpuIcon,
          hasDivider: false
        },
        {
          id: "filters",
          label: "Filters",
          onClick: () => onOpenViewConfig({ showFiltersConfig: true }),
          icon: SlidersHorizontalIcon,
          hasDivider: false
        },
        {
          id: "bulk_Edit",
          label: "Bulk Edit",
          onClick: () => onOpenViewConfig({ showBulkEditConfig: true }),
          icon: PencilIcon,
          hasDivider: false
        },
        {
          id: "columns_formatting",
          label: "Columns Formatting",
          onClick: () => onOpenViewConfig({ showColumnsFormattingConfig: true }),
          icon: PaletteIcon,
          hasDivider: false
        }
      ]);

    // add search table config
    if (searchTableId) {
      finalItems[finalItems.length - 1].hasDivider = true;
      finalItems?.push({
        id: "searchTableConfig",
        label: "Search Table",
        onClick: () => onOpenViewConfig({ showSearchTableConfig: true }),
        icon: SearchIcon,
        hasDivider: activeView !== ViewOption.MATRIX ? true : false
      });
    }

    // add column actions
    if (activeView !== ViewOption.MATRIX) {
      finalItems[finalItems.length - 1].hasDivider = true;
      finalItems?.push(
        ...[
          {
            id: `new_column_add`,
            label: `Add New Column`,
            onClick: onAddColClicked,
            icon: PlusIcon,
            hasDivider: false
          },
          {
            id: `existing column add`,
            label: `Add Existing Column to View`,
            onClick: onAddExistingColClicked,
            icon: BetweenVerticalEndIcon,
            hasDivider: false
          }
        ]
      );
    }

    // add page actions
    if (!isRecord && !isAdminPage) {
      finalItems[finalItems.length - 1].hasDivider = true;
      const addPageItems = [
        {
          id: "add_record_type",
          label: "Add Record Type",
          icon: LibraryBigIcon,
          onClick: onAddRecordTypeClicked
        }
      ];
      if (!pageData?.parent_id) {
        addPageItems.push({
          id: "add_child_page",
          label: "Add Child Page",
          icon: FilePlus2Icon,
          onClick: onAddChildPageClicked
        });
      }
      finalItems.push(...addPageItems);
    }

    return finalItems;
  }, [
    views,
    onOpenViewConfig,
    isDetailViewEnabled,
    searchTableId,
    activeView,
    onAddColClicked,
    onAddExistingColClicked,
    isAdminPage,
    isRecord,
    onAddChildPageClicked,
    onAddRecordTypeClicked,
    pageData?.parent_id
  ]);

  return (
    <Dropdown
      MenuButton={
        <div className="group/menu-button relative cursor-pointer">
          <Button
            label=""
            appearance={ButtonAppearance.SECONDARY}
            icon={SidebarIcon}
            className="dark: whitespace-nowrap   !px-2  group-hover/menu-button:bg-neutral-200 group-hover/menu-button:shadow-none group-active/menu-button:bg-neutral-300 dark:group-hover/menu-button:bg-neutral-dark-200 dark:group-active/menu-button:bg-neutral-dark-300"
          />
          <Tooltip title="Admin View Config" disableInteractive>
            <div className="absolute inset-0" />
          </Tooltip>
        </div>
      }
      items={items}
      data-testid="ViewSelector"
    />
  );
};

export default AdminUserActionSelector;
