"use client";

import sortBy from "lodash/sortBy";

import { useMemo } from "react";
import { CellType, ViewOption } from "utils/constants";
import DensitySelector from "components/DensitySelector";
import useTableActionsState from "hooks/useTableActionsState";
import { SortItem, TableColumnType, TableViewType } from "types/baTypes";
import useSchemaState from "hooks/useSchemaState";
import Filters from "./Filters/Filters";
import Sortings from "./Sortings";
import CurrentUserFilter from "./Filters/CurrentUserFilter";

type BarButtonsProps = {
  activeView: ViewOption;
  columns: TableColumnType[];
  tableName: string;
  tableSlug: string;
  inAddMany?: boolean;
  tableFiltersOption?: TableViewType;
  globalSort?: SortItem[];
};

const BarButtons = ({
  activeView,
  columns,
  tableName,
  tableSlug,
  inAddMany,
  tableFiltersOption,
  globalSort
}: BarButtonsProps) => {
  const { rowDensityByTableSlug, updateRowDensityByTableSlug } = useTableActionsState();
  const { schema } = useSchemaState();

  const isPageViewFiltered = useMemo(() => {
    if (!schema?.[tableName] || !tableSlug || !tableName) return false;
    const viewName = `view_${tableName}_${tableSlug.split("-").join("_")}`;
    return !!schema?.[viewName];
  }, [schema, tableName, tableSlug]);

  const columnsWithFilters = sortBy(columns.filter((col) => col.hasFilter) || [], (col) => col.sortOrder?.[activeView]);
  const columnsWithSorting = sortBy(
    columns.filter((col) => {
      if (isPageViewFiltered) {
        return col.hasFilter;
      }
      if (
        col.isRollup ||
        col.isFormula ||
        [CellType.URL, CellType.BUTTON, CellType.GENERIC_CELL].includes(col.type) ||
        !!col.views?.[ViewOption.FORM]?.formRelationPageId
      ) {
        return false;
      }
      if (col.isLookup && !col?.lookupPath?.["0"]?.lookupColumns?.length) {
        return false;
      }
      return !!col?.views?.[activeView]?.id;
    }) || [],
    (col) => col.sortOrder?.[activeView]
  );

  if (activeView === ViewOption.MATRIX) return null;

  return (
    <>
      <Filters
        columns={columnsWithFilters}
        tableName={tableName}
        tableSlug={tableSlug}
        tableFiltersOption={tableFiltersOption}
        inAddMany={inAddMany}
      />
      <Sortings
        columns={columnsWithSorting}
        tableSlug={tableSlug}
        isViewFiltered={isPageViewFiltered}
        allColumns={columns}
        globalSort={globalSort}
        tableName={tableName}
      />

      <CurrentUserFilter columns={columns} tableName={tableName} tableSlug={tableSlug} />

      {activeView === ViewOption.GRID && (
        <DensitySelector
          value={rowDensityByTableSlug?.[tableSlug]}
          onChange={(rowDensitySelected) => {
            updateRowDensityByTableSlug(rowDensitySelected, tableSlug);
          }}
        />
      )}
    </>
  );
};

export default BarButtons;
