import { useCallback } from "react";
import { Page, TableViewConfig } from "types/baTypes";
import { CrudActions, SidebarContainer, ViewOption } from "utils/constants";
import { generatePageTitle } from "utils/texts";
import useSearchQueryParams from "./useSearchQueryParams";

const useFormOpenHandler = ({
  pageViews,
  pageTitle,
  parentRecordId,
  parentRecordSlug,
  pageSlug
}: {
  pageViews?: TableViewConfig[];
  pageTitle?: string;
  parentRecordId?: string;
  parentRecordSlug?: string;
  pageSlug?: string;
}) => {
  const { setParams } = useSearchQueryParams();
  const handleMultiFormItemClick = useCallback(
    ({
      viewId,
      isCustom,
      page,
      displayType
    }: {
      viewId: string;
      isCustom?: boolean;
      page?: Page;
      displayType?: SidebarContainer;
    }) => {
      let isPageForm = false;
      if (!viewId && page?.id) {
        isPageForm = true;
      }
      const formConfig =
        !isCustom && viewId
          ? pageViews?.find((view) => view.viewId === viewId)
          : isPageForm
            ? pageViews?.find((view) => view.viewId === ViewOption.FORM && !!view.isDefault)
            : null;
      if (formConfig?.additionalConfig?.isFormCollapsible) {
        setParams({
          form: {
            title: `${
              formConfig?.isPageViewCustom
                ? `${formConfig?.title}`
                : `${formConfig?.additionalConfig?.showAddButtonLabel ? "Add" : "Create"} ${generatePageTitle(
                    isPageForm ? page?.title : pageTitle
                  )}`
            }`,
            sidebar: displayType ? displayType : SidebarContainer.Collapsible,
            tablePath: isPageForm ? page?.path?.replace("/", "") : pageSlug?.replace("/", ""),
            action: CrudActions.CREATE,
            parentRecordId,
            parentRecordSlug,
            pageFormId: formConfig?.isPageViewCustom ? viewId : undefined,
            formId: isCustom ? viewId : undefined
          }
        });
      } else {
        setParams({
          form: {
            formId: isCustom ? viewId : undefined,
            pageFormId: formConfig?.isPageViewCustom ? viewId : undefined,
            tablePath: isPageForm ? page?.path?.replace("/", "") : pageSlug?.replace("/", ""),
            sidebar: displayType ? displayType : SidebarContainer.Sidebar,
            action: CrudActions.CREATE,
            parentRecordId,
            parentRecordSlug
          }
        });
      }
    },
    [parentRecordId, parentRecordSlug, pageViews, pageTitle, pageSlug, setParams]
  );

  return {
    openForm: handleMultiFormItemClick
  };
};

export default useFormOpenHandler;
