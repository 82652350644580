"use client";

import { useCallback } from "react";

import Button from "components/Button";
import useTableActionsState from "hooks/useTableActionsState";
import useSearchQueryParams from "hooks/useSearchQueryParams";
import { APP_QUERY_PARAM_TYPES, ButtonAppearance, CellType } from "utils/constants";
import { TableFilterType } from "types/baTypes";

const filterHasValue = (filter: TableFilterType): boolean => {
  if (filter.filterGroup?.length) {
    return filter.filterGroup.some((f) => filterHasValue(f));
  }
  return !!filter?.filterValue || filter?.type === CellType.BOOLEAN;
};
const ClearFilterButton = ({ tableSlug }: { tableSlug: string }) => {
  const { filtersByTableSlug } = useTableActionsState();
  const { clearParams } = useSearchQueryParams();

  const handleClearFilters = useCallback(() => {
    clearParams(APP_QUERY_PARAM_TYPES.FILTERS);
  }, [clearParams]);

  return filtersByTableSlug?.[tableSlug]?.filter((f) => filterHasValue(f)).length ? (
    <Button
      onClick={handleClearFilters}
      label="Clear"
      className="!px-2 text-sm !text-[red] no-underline"
      appearance={ButtonAppearance.TERTIARY}
    />
  ) : null;
};

export default ClearFilterButton;
