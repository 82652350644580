import React from "react";
import clsx from "clsx";
import { Menu } from "@headlessui/react";

import { DropdownPosition } from "utils/constants";
import Dropdown from "components/Dropdown";
import DropdownLabel from "components/Dropdown/DropdownLabel";
import DropdownMenuItem from "components/Dropdown/DropdownMenuItem";
import MenuButton from "./MenuButton";
import MenuLinkButton from "./MenuLinkButton";

type Item = {
  id: number | string;
  label: string;
  wrapper?: React.ReactElement;
  onClick?: () => void;
  icon?: () => React.ReactElement;
  avatar?: string;
  initials?: string;
  items?: Item[];
};

type MenuDropdownProps = {
  label?: string;
  labelComponent?: React.ReactElement;
  isLink?: boolean;
  items?: Item[];
  wrapper: React.ReactElement;
  className?: string;
  labelClassName?: string;
  showArrow?: boolean;
  mRef?: React.RefObject<HTMLDivElement> | null;
};

const MenuDropdown = ({
  label = "",
  labelComponent,
  labelClassName,
  showArrow = true,
  items = [],
  isLink = false,
  wrapper,
  className,
  ...rest
}: MenuDropdownProps) => {
  return (
    <Dropdown
      as="span"
      MenuButton={
        isLink ? (
          <MenuLinkButton showArrow={true} wrapper={wrapper} label={label} className={labelClassName} />
        ) : (
          <MenuButton label={label} labelComponent={labelComponent} showArrow={showArrow} className={labelClassName} />
        )
      }
      className={clsx("w-[15.625rem] rounded-none", className)}
      classNameContainer="self-center"
      data-testid="MenuDropdown"
      isLink={isLink}
      {...rest}
    >
      <div className={clsx("py-3 px-2")}>
        {items.map((item) =>
          item.items ? (
            <Menu.Item key={item.id}>
              {({ active }) => (
                <Dropdown
                  isLink
                  key={item.id}
                  classNameContainer="w-full"
                  className="w-[15.625rem] rounded-none"
                  position={DropdownPosition.RIGHT_START}
                  offset={[0, 16]}
                  MenuButton={<DropdownLabel {...item} />}
                  open={active}
                >
                  <div className={clsx("py-3 px-2")}>
                    {item.items &&
                      item?.items.map((childItem) =>
                        childItem.items ? (
                          <Dropdown
                            isLink
                            key={`${item.id}_${childItem.id}`}
                            classNameContainer="w-full"
                            position={DropdownPosition.RIGHT_START}
                            offset={[0, 16]}
                            items={childItem.items}
                            MenuButton={<DropdownLabel {...childItem} />}
                          />
                        ) : (
                          <DropdownMenuItem key={childItem.id} {...childItem} />
                        )
                      )}
                  </div>
                </Dropdown>
              )}
            </Menu.Item>
          ) : (
            <DropdownMenuItem key={item.id} {...item} />
          )
        )}
      </div>
    </Dropdown>
  );
};

export default MenuDropdown;
