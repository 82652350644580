"use client";
import { useMemo, useCallback } from "react";
import sortBy from "lodash/sortBy";
import { useSearchParams } from "next/navigation";

import ViewSectionSettings from "components/ViewSectionSettings";
import SaveUserViewSection from "components/ViewSectionSettings/SaveUserViewSection";

import useUserPageViewSections from "hooks/useUserPageViewSections";
import usePageViewSections from "hooks/usePageViewSections";
import { TableColumnType, TableViewConfig, ViewSection } from "types/baTypes";
import { APP_QUERY_PARAM_TYPES, ViewModes, ViewOption } from "utils/constants";
import useInLayout from "hooks/useInLayout";

const TableSections = ({
  tableSlug,
  tableName,
  pageId,
  currentView,
  isUserAdmin,
  columns,
  inAddMany,
  inSidebar,
  pageViews
}: {
  tableSlug: string;
  tableName?: string;
  pageId?: string;
  currentView?: ViewModes; // if pasesd, will be used instead of getActiveView
  isUserAdmin?: boolean;
  columns?: TableColumnType[];
  inAddMany?: boolean;
  inSidebar?: boolean;
  pageViews?: TableViewConfig[];
  isLoading?: boolean;
}) => {
  const { getActiveView } = useInLayout();
  const activeView: ViewModes = currentView ? currentView : (getActiveView() as ViewModes);
  const currentViewConfig = pageViews?.find((view) => view.viewType === activeView);
  const { data: pageViewSections, refetch: refetchPageViewSections } = usePageViewSections({ pageId });
  const { data: userPageViewSections, refetch } = useUserPageViewSections(pageId);

  const queryParams = useSearchParams();

  const viewSections = useMemo(() => {
    const finalSections: ViewSection[] = [];
    if (!pageViewSections?.length) {
      return finalSections;
    }
    let currentUserViewConfig = undefined;
    if (userPageViewSections?.length) {
      currentUserViewConfig = userPageViewSections?.find((view) => view.viewType === activeView);
    }
    const currentPageViewSectionsConfig = pageViewSections?.find((view) => view.viewType === activeView);
    currentPageViewSectionsConfig?.sections?.forEach((section) => {
      if (isUserAdmin ? true : !section.isAdminOnly) {
        const sectionInput: ViewSection = {
          ...section,
          columns: []
        };
        // We append the columns to the sections
        if (columns?.length) {
          const colsIdsInSection = section.pageViewColumns?.map((pvCol) => pvCol.columnId);
          columns.forEach((col) => {
            if (colsIdsInSection?.includes(col.id)) {
              (sectionInput.columns as TableColumnType[]).push(col);
            }
          });
        }
        finalSections.push(sectionInput);
      }
    });

    if (currentUserViewConfig?.sections?.length) {
      currentUserViewConfig?.sections?.forEach((section) => {
        if (isUserAdmin || !section.isAdminOnly) {
          const sectionInput: ViewSection = {
            ...section,
            columns: []
          };
          // We append the columns to the sections
          if (columns?.length) {
            const colsIdsInSection = section.pageViewColumns?.map((pvCol) => pvCol.columnId);
            columns.forEach((col) => {
              if (colsIdsInSection?.includes(col.id)) {
                (sectionInput.columns as TableColumnType[]).push(col);
              }
            });
          }
          finalSections.push(sectionInput);
        }
      });
    }
    return finalSections?.length ? sortBy(finalSections, "sort") : finalSections;
  }, [isUserAdmin, activeView, columns, userPageViewSections, pageViewSections]);

  const sectionId = queryParams?.get(APP_QUERY_PARAM_TYPES.VIEW_SECTION);
  const defaultViewSectionId = viewSections?.find(
    (viewSection) => viewSection.id === currentViewConfig?.defaultColumnSectionId
  )?.id;

  const currentViewSection =
    sectionId === "all" ? undefined : viewSections?.find((group) => `${group.id}` === sectionId);

  const onSectionUpdateSuccess = useCallback(() => {
    refetch();
    refetchPageViewSections();
  }, [refetch, refetchPageViewSections]);

  return (
    <>
      {!!([ViewOption.GRID, ViewOption.CARD, ViewOption.BOARD].includes(activeView) && viewSections?.length) && (
        <ViewSectionSettings
          viewSections={viewSections}
          className="self-end max-sm:hidden"
          isExpanded
          tableSlug={tableSlug}
          activeView={activeView}
          columns={columns || []}
          defaultViewSectionId={defaultViewSectionId}
          inSidebar={inSidebar}
          inAddMany={inAddMany}
          onSuccess={onSectionUpdateSuccess}
          currentViewConfig={currentViewConfig}
          pageState={{
            id: pageId,
            table_name: tableName
          }}
        />
      )}

      {[ViewOption.GRID, ViewOption.CARD, ViewOption.BOARD].includes(activeView) && isUserAdmin ? (
        <SaveUserViewSection
          tableSlug={tableSlug}
          currentSection={currentViewSection}
          lastSectionSort={viewSections?.length ? viewSections?.[viewSections?.length - 1]?.sort : undefined}
          currentViewConfig={currentViewConfig}
          onSuccess={onSectionUpdateSuccess}
          columns={columns}
          activeView={activeView}
        />
      ) : null}
    </>
  );
};

export default TableSections;
