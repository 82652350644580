"use client";

import { useEffect, useMemo, useRef } from "react";
import clsx from "clsx";
import { useSearchParams } from "next/navigation";

import useTableActionsState from "hooks/useTableActionsState";
import useFilterUpdate from "hooks/useFilterUpdate";
import useSchemaState from "hooks/useSchemaState";
import { ViewOption } from "utils/constants";
import { TableColumnType } from "types/baTypes";
import useRecordTypes from "hooks/useRecordTypes";
import { appendRecordTypeLookupToCol } from "utils/columnUtils";
import useSortUpdate from "hooks/useSortUpdate";

type BarWrapperProps = {
  activeView: ViewOption;
  children: React.ReactNode;
  tableSlug: string;
  columns: TableColumnType[];
  tableName: string;
  inAddMany?: boolean;
};

const BarWrapper = ({ tableSlug, columns, children, tableName, inAddMany }: BarWrapperProps) => {
  const { schemaInstance } = useSchemaState();
  const queryParams = useSearchParams();
  const lastQueryParamsChecked = useRef("");
  const { data: recordTypesData } = useRecordTypes();

  const finalColumns = useMemo(() => {
    return !schemaInstance?.extendedSchema
      ? []
      : (columns
          ?.map((col) =>
            appendRecordTypeLookupToCol(col, recordTypesData || [], tableName, schemaInstance?.extendedSchema, true)
          )
          .filter(Boolean) as TableColumnType[]) || [];
  }, [columns, recordTypesData, tableName, schemaInstance?.extendedSchema]);

  const { updateFiltersFromSearchParams } = useFilterUpdate(tableSlug, finalColumns, inAddMany);
  const { updateSortFromSearchParams } = useSortUpdate({ tableSlug, columns: finalColumns });

  const { bulkActionsStateByTableSlug } = useTableActionsState();
  const bulkActionsState = bulkActionsStateByTableSlug?.[tableSlug];

  useEffect(() => {
    if (
      (lastQueryParamsChecked.current === queryParams?.toString() &&
        !!lastQueryParamsChecked.current &&
        !!queryParams?.toString()) ||
      (!lastQueryParamsChecked.current && !queryParams?.toString()) ||
      !schemaInstance?.extendedSchema ||
      !finalColumns?.length
    ) {
      return;
    }
    updateFiltersFromSearchParams(queryParams);
    updateSortFromSearchParams(queryParams);
    lastQueryParamsChecked.current = queryParams?.toString() || "";
  }, [
    queryParams,
    updateFiltersFromSearchParams,
    finalColumns,
    schemaInstance?.extendedSchema,
    updateSortFromSearchParams
  ]);

  return (
    <div
      className={clsx(
        bulkActionsState?.isSelected
          ? "hidden"
          : "border-separator flex h-[60px] scroll-pb-1 items-center gap-x-3 overflow-x-auto border-b px-5"
      )}
    >
      {children}
    </div>
  );
};

export default BarWrapper;
