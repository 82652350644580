import { useCallback } from "react";
import { ReadonlyURLSearchParams } from "next/navigation";
import querystring from "query-string";
import isEmpty from "lodash/isEmpty";

import useTableActionsState from "hooks/useTableActionsState";
import useSearchQueryParams from "hooks/useSearchQueryParams";
import { SortItem, TableColumnType } from "types/baTypes";
import { APP_QUERY_PARAM_TYPES } from "utils/constants";

const useSortUpdate = ({ tableSlug, columns }: { tableSlug: string; columns?: TableColumnType[] }) => {
  const { updatePaginationByTableSlug, paginationByTableSlug, sortingByTableSlug, updateSortingByTableSlug } =
    useTableActionsState();
  const { setParams, clearParams } = useSearchQueryParams();

  const handleUpdateSortingForColumn = useCallback(
    (data: TableColumnType[]) => {
      // Reset pagination
      if (paginationByTableSlug?.[tableSlug]?.currentPage !== 1) {
        updatePaginationByTableSlug(
          {
            ...paginationByTableSlug?.[tableSlug],
            currentPage: 1
          },
          tableSlug
        );
      }
      const finalList: SortItem[] = data?.map((col) => ({
        id: col.id,
        desc: false,
        sortSlug: tableSlug
      }));
      if (sortingByTableSlug?.[tableSlug]?.length) {
        sortingByTableSlug?.[tableSlug]?.forEach((item) => {
          if (!finalList.find((col) => col.id === item.id)) {
            finalList.push(item);
          }
        });
      }

      setParams({
        sortItems: finalList
      });
    },
    [updatePaginationByTableSlug, paginationByTableSlug, tableSlug, setParams, sortingByTableSlug]
  );

  const handleUpdateSorting = useCallback(
    (sortItems: SortItem[]) => {
      setParams({
        sortItems
      });
    },
    [setParams]
  );

  const handleSortChange = useCallback(
    (colId: string, desc: string) => {
      if (!sortingByTableSlug?.[tableSlug]) return;
      const newSortItems = sortingByTableSlug?.[tableSlug]?.map((item) => {
        if (item.id === colId) {
          return {
            ...item,
            desc: desc === "desc",
            sortSlug: tableSlug
          };
        }
        return { ...item, sortSlug: tableSlug };
      });

      handleUpdateSorting(newSortItems);
    },
    [handleUpdateSorting, sortingByTableSlug, tableSlug]
  );

  const handleClearSort = useCallback(
    (colId: string) => {
      if (!sortingByTableSlug?.[tableSlug]) return;
      const newSortItems = sortingByTableSlug?.[tableSlug]?.filter((item) => item.id !== colId);
      if (!newSortItems?.length) {
        clearParams(APP_QUERY_PARAM_TYPES.SORT);
        return;
      }
      handleUpdateSorting(newSortItems.map((item) => ({ ...item, sortSlug: tableSlug })));
    },
    [handleUpdateSorting, sortingByTableSlug, tableSlug, clearParams]
  );

  const updateSortFromSearchParams = useCallback(
    (queryParams: ReadonlyURLSearchParams | null) => {
      const newParams = querystring.parse((queryParams || "").toString(), {
        arrayFormat: "comma"
      });
      if (
        isEmpty(newParams) ||
        !tableSlug ||
        newParams?.[APP_QUERY_PARAM_TYPES.SORT] !== tableSlug ||
        !queryParams ||
        !Object.keys(newParams).find((key) => key.startsWith("sort:"))
      ) {
        if (
          !Object.keys(newParams || {}).find((key) => key.startsWith("sort:")) &&
          sortingByTableSlug?.[tableSlug]?.length
        ) {
          updateSortingByTableSlug([], tableSlug);
        }
        return;
      }
      const sortParams = Object.keys(newParams).filter((key) => key.startsWith("sort:"));
      const finalSortItems = sortParams
        ?.map((sortKey: string) => {
          const sort = newParams[sortKey];
          const colId = sortKey.split(":")[1];
          const col = columns?.find((column) => column.id === colId);
          if (col) {
            return {
              ...col,
              id: colId,
              desc: (sort as string) === "desc",
              col: col
            };
          }
          if (colId === "id_sort") {
            // This is a special default if no global sort is added
            return {
              id: "id",
              name: "id",
              isLookup: false,
              header: "ID",
              desc: true,
              col: {
                id: "id",
                name: "id",
                isLookup: false,
                header: "ID"
              }
            };
          }
          return null;
        })
        .filter(Boolean) as SortItem[];

      updateSortingByTableSlug(finalSortItems, tableSlug);
    },
    [sortingByTableSlug, tableSlug, updateSortingByTableSlug, columns]
  );

  return {
    handleUpdateSortingForColumn,
    updateSortFromSearchParams,
    handleSortChange,
    handleUpdateSorting,
    handleClearSort
  };
};

export default useSortUpdate;
