"use client";

import noop from "lodash/noop";
import React, { createContext, useCallback, useContext, useState } from "react";
import { RecordItem } from "types/common";

const GeneralActionsStateContext = createContext<GeneralAction | null>(null);
const GeneralActionsUpdaterContext = createContext<GeneralActionsUpdaterContextState>({
  setActionsState: noop,
  triggerFileUploadAction: noop
});

export interface GeneralActionsUpdaterContextState {
  setActionsState: (newProps: GeneralAction) => void;
  triggerFileUploadAction: (newActionState: GeneralAction) => void;
}
export type GeneralAction = {
  isOpen: boolean;
  currentAction: string | null;
  triggeredBy?: string;
  actionProps?: any;
  returnFiles?: boolean;
  newFiles?: File[];
  uploadStatus?: Record<string, string>;
  uploadedFiles?: RecordItem[];
};
export const GeneralActionsProvider = ({ children }: { children: React.ReactNode }) => {
  const [actionsState, setActionsState] = useState<GeneralAction>({
    isOpen: false,
    currentAction: null,
    actionProps: null,
    returnFiles: false
  });

  const triggerFileUploadAction = useCallback((newProjectActionState: GeneralAction) => {
    const updatedState = {
      ...newProjectActionState
    };

    setActionsState(updatedState);
  }, []);

  return (
    <GeneralActionsStateContext.Provider value={actionsState}>
      <GeneralActionsUpdaterContext.Provider value={{ setActionsState, triggerFileUploadAction }}>
        {children}
      </GeneralActionsUpdaterContext.Provider>
    </GeneralActionsStateContext.Provider>
  );
};

export const useGeneralActionsState = () => {
  const actionsState = useContext(GeneralActionsStateContext);

  if (typeof actionsState === "undefined") {
    throw new Error("useGeneralActionsState must be used within a GeneralActionsProvider");
  }

  return actionsState;
};

export const useGeneralActionsUpdater = () => {
  const actionsUpdater = useContext(GeneralActionsUpdaterContext);

  if (typeof actionsUpdater === "undefined") {
    throw new Error("useGeneralActionsUpdater must be used within a GeneralActionsProvider");
  }

  return actionsUpdater;
};
