import React, { useCallback } from "react";
import clsx from "clsx";
import Button from "components/Button";
import Modal from "components/Modal";
import { ButtonAppearance, ButtonSize } from "utils/constants";

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  info?: string;
  isLoading?: boolean;
  hideDelete?: boolean;
  cancelButtonLabel?: string;
  confirmButtonLabel?: string;
  confirmButtonAppearance?: ButtonAppearance;
};
const ConfirmationModal = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  info,
  isLoading = false,
  hideDelete = false,
  cancelButtonLabel = "",
  confirmButtonLabel = "",
  confirmButtonAppearance = ButtonAppearance.PRIMARY,
}: ConfirmationModalProps) => {
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === "Enter") {
        onConfirm?.();
        onClose();
      }
    },
    [onConfirm, onClose]
  );

  return (
    <Modal
      title={title}
      isOpen={isOpen}
      onClose={() => onClose()}
      data-testid="ConfirmationModal"
      onKeyDown={onKeyDown}
    >
      <>
        <div className="m-10">
          <h4 className="mb-3 text-xl font-light text-base-primary dark:text-base-dark-primary">{message}</h4>
          {info && <span className="text-md font-light text-primary-800 dark:text-primary-dark-800">{info}</span>}
        </div>
        <div className="mt-20 flex justify-end border-t p-6">
          <Button
            className="mr-2"
            appearance={ButtonAppearance.SECONDARY}
            label={cancelButtonLabel || "No, Cancel"}
            size={ButtonSize.SM}
            onClick={() => onClose()}
          />
          {!hideDelete ? (
            <Button
              label={confirmButtonLabel || "Yes, Delete"}
              size={ButtonSize.SM}
              onClick={() => {
                onConfirm();
                onClose();
              }}
              isLoading={isLoading}
              appearance={confirmButtonAppearance}
            />
          ) : null}
        </div>
      </>
    </Modal>
  );
};

export default ConfirmationModal;
