"use client";

import { useMemo } from "react";
import { BoxesIcon } from "lucide-react";

import clsx from "clsx";
import sortBy from "lodash/sortBy";
import Button from "components/Button";
import Dropdown from "components/Dropdown";
import { ButtonAppearance } from "utils/constants";
import useTableActionsState from "hooks/useTableActionsState";
import { MatrixColumnType } from "types/baTypes";

type MatrixXAxisColumnsSettingProps = {
  xAxisColumns: MatrixColumnType[];
  isExpanded?: boolean;
  className?: string;
  tableSlug: string;
};

const MatrixXAxisColumnsSetting = ({
  xAxisColumns,
  isExpanded,
  className,
  tableSlug
}: MatrixXAxisColumnsSettingProps) => {
  const { matrixXAxisColumnIdByTableSlug, updateMatrixXAxisColumnIdByTableSlug } = useTableActionsState();

  const value = matrixXAxisColumnIdByTableSlug?.[tableSlug];

  const items = useMemo(() => {
    return sortBy(
      xAxisColumns.map((item) => ({
        id: item.id,
        label: item.label,
        sort_order: item.sort_order,
        onClick: () => updateMatrixXAxisColumnIdByTableSlug(item.id, tableSlug),
        isActive: value === item.id
      })),
      "sort_order"
    );
  }, [xAxisColumns, value, updateMatrixXAxisColumnIdByTableSlug, tableSlug]);

  if (!isExpanded) {
    return (
      <Dropdown
        MenuButton={
          <Button
            label=""
            appearance={ButtonAppearance.SECONDARY}
            icon={BoxesIcon}
            className="whitespace-nowrap !px-2.5"
          />
        }
        tooltipText="Change X Axis Column"
        items={items}
      />
    );
  }

  return (
    <div className={clsx("flex h-full items-center gap-3 overflow-x-auto scrollbar-hide", className)}>
      {items.map((item) => (
        <div
          key={item.id}
          className={clsx(
            "flex h-full cursor-pointer items-center border-b-[4px] border-transparent py-2 text-sm font-medium transition-transform",
            item.isActive
              ? "text-primary border-b-primary-700 font-medium dark:border-b-primary-dark-700"
              : "text-base-disabled dark:text-base-dark-disabled"
          )}
          onClick={item.onClick}
        >
          <div
            className={clsx(
              "flex items-center rounded-md px-3 py-1.5 hover:bg-neutral-200 dark:hover:bg-neutral-dark-200"
            )}
          >
            {item.label}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MatrixXAxisColumnsSetting;
