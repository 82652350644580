import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { getPageViewSections } from "lib/adminApi";
import { USER_TYPE } from "utils/constants";
import useCurrentUser from "./useCurrentUser";

const usePageViewSections = ({
  pageId,
  pageSlug,
  source,
  userType
}: {
  pageId?: string;
  pageSlug?: string;
  source?: string;
  userType?: USER_TYPE;
}) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const {
    data: userViewSections,
    refetch,
    isLoading
  } = useQuery({
    queryKey: ["pageViewSections", pageId, pageSlug, userType, currentUser?.org_id],
    queryFn: () =>
      getPageViewSections({ supabaseClient, pageId, pageSlug, source, organizationId: currentUser?.org_id, userType }),
    enabled: !!pageId || !!pageSlug,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    staleTime: Infinity,
    gcTime: Infinity
  });

  return {
    data: userViewSections?.data,
    refetch,
    isLoading
  };
};

export default usePageViewSections;
