"use client";

import React, { useMemo } from "react";
import { MoreHorizontalIcon } from "lucide-react";
import { v4 } from "uuid";
import { useQueryClient } from "@tanstack/react-query";
import sortBy from "lodash/sortBy";

import { useRouter } from "next/navigation";
import Dropdown from "components/Dropdown";
import IconButton from "components/IconButton";
import IconComp, { IconProps } from "components/Icon";
import { NavigationItem, NavigationItemAction } from "types/common";
import { ActionType, STATIC_SIDEBAR_IDS } from "utils/constants";
import useCurrentUser from "hooks/useCurrentUser";
import useNotificationsState from "hooks/useNotificationsState";
import { NOTIFICATION_TYPE } from "components/Notifications/utils";
import useTableActionsState from "hooks/useTableActionsState";
import { getTimeStamp } from "utils/columnUtils";
import Tooltip from "components/Tooltip";
import { IconButtonSize } from "components/IconButton/utils";

type MenuActionsProps = {
  actions: NavigationItemAction[];
  isHovered: boolean;
  item?: NavigationItem;
  recordPageId?: string;
};

const MenuActions = ({ actions, isHovered, item, recordPageId }: MenuActionsProps) => {
  const currentUser = useCurrentUser();
  const router = useRouter();
  const { addPendingNotification } = useNotificationsState();
  const queryClient = useQueryClient();
  const { currentProjectId, updateSidebarState } = useTableActionsState();
  const actionsItems = useMemo(() => {
    const actionsSorted = sortBy(actions, "sort");
    return actionsSorted.map((action) => ({
      id: action.id,
      label: action.name,
      onClick: () => {
        if (action.type === ActionType.LINK && action.properties?.path) {
          window.open(action.properties?.path, "_blank");
        }
        if (action.type === ActionType.LINK && action.properties?.linkURL) {
          window.open(action.properties?.linkURL, "_blank");
        }
        // Webhook calls needs the page id and table name from which the action was triggered
        if (
          action.type === ActionType.WEBHOOK &&
          action.properties?.webhookURL &&
          (item?.ui_page_id?.id || item?.tabPageId || (!item && !!action.tabPageId))
        ) {
          const notificationId = v4();
          const timestamp = getTimeStamp();
          fetch(action.properties?.webhookURL, {
            method: "POST",
            mode: "no-cors",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              pageId: item?.ui_page_id?.id || item?.tabPageId || action.tabPageId,
              userId: currentUser?.user_id,
              actionName: action.name,
              actionId: action.ui_action_id?.id,
              timestamp,
              projectId: (item?.tabPageId || action.tabPageId) && currentProjectId ? currentProjectId : undefined,
              action_props: {
                id: notificationId,
                pageTable: item?.ui_page_id?.table_name || action.tableName
              }
            })
          });
          addPendingNotification({
            id: notificationId,
            recordId: "",
            pageId: item?.ui_page_id?.id || action.tabPageId || "",
            tableName: item?.ui_page_id?.table_name || action.tableName || "",
            userId: currentUser?.user_id || "",
            action: action?.name,
            action_props: {
              id: notificationId,
              hasWaitForSuccess: action?.waitForSuccess
            },
            type: NOTIFICATION_TYPE.ACTION
          });
        }

        if ([ActionType.RENAME, ActionType.CUSTOM].includes(action.type)) {
          action.onClick?.();
        }

        if (action.type === ActionType.SIDEBAR_AUDIT) {
          if (action.tableName) {
            updateSidebarState(
              {
                showAuditSidebar: true,
                auditState: { tableName: action.tableName, isOpen: true }
              },
              STATIC_SIDEBAR_IDS.AUDIT_SIDEBAR
            );
          }
        }

        if (action.type === ActionType.MENU_EDIT && item?.ui_menu_id) {
          updateSidebarState(
            {
              showDetailView: false,
              isOpen: false,
              menuConfigEditState: {
                isOpen: true,
                menuId: item.ui_menu_id,
                onActionComplete: () => {
                  queryClient.invalidateQueries({ queryKey: ["ui_menu"] });
                  router.refresh();
                }
              }
            },
            STATIC_SIDEBAR_IDS.MENU_CONFIG_EDIT_SIDEBAR
          );
        }

        if (action.type === ActionType.MENU_ITEM_EDIT && item?.ui_menu_id) {
          updateSidebarState(
            {
              showDetailView: false,
              isOpen: false,
              menuConfigEditState: {
                isOpen: true,
                menuId: item.ui_menu_id,
                menuItemId: item.id,
                pageId: recordPageId,
                onActionComplete: () => {
                  queryClient.invalidateQueries({ queryKey: ["ui_menu"] });
                  router.refresh();
                }
              }
            },
            STATIC_SIDEBAR_IDS.MENU_CONFIG_EDIT_SIDEBAR
          );
        }
      },
      icon: action.icon
        ? (props: Omit<IconProps, "name">) => <IconComp name={action.icon as any} {...props} />
        : undefined,
      hasDivider: action.hasDivider,
      linkToOpenInNewTab: currentUser?.is_admin ? action.properties?.actionURL : undefined
    }));
  }, [
    actions,
    item,
    currentUser,
    addPendingNotification,
    currentProjectId,
    updateSidebarState,
    queryClient,
    router,
    recordPageId
  ]);

  if (item?.showExposedActions) {
    if (!isHovered) return null;
    return (
      <>
        {actionsItems
          .filter((action) => !!action.icon)
          .map((action) => {
            const Icon = action.icon;
            if (Icon) {
              return (
                <Tooltip key={action.id} title={action.label}>
                  <IconButton
                    size={IconButtonSize.XS}
                    key={action.id}
                    icon={() => <Icon className="h-4 w-4" />}
                    className="bg-background"
                    onClick={(e) => {
                      e.stopPropagation();
                      action.onClick();
                    }}
                  />
                </Tooltip>
              );
            }
            return null;
          })}
      </>
    );
  }

  return (
    <Dropdown
      MenuButton={
        <IconButton
          icon={MoreHorizontalIcon}
          size="xs"
          className="bg-background"
          onClick={(e) => e.stopPropagation()}
        />
      }
      items={actionsItems}
      isHovered={isHovered}
      showOnlyInHovered
    />
  );
};

export default React.memo(MenuActions);
