import React from "react";
import clsx from "clsx";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";

type MenuButtonProps = {
  label?: string;
  labelComponent?: React.ReactElement;
  active?: boolean;
  open?: boolean;
  showArrow?: boolean;
  className?: string;
  mRef?: React.RefObject<HTMLDivElement> | null;
};

const MenuButton = React.forwardRef(
  ({ showArrow, label, labelComponent, open, mRef, className, ...rest }: MenuButtonProps, ref: any) => {
    if (labelComponent) {
      const classNameIcon = "h-5 w-5 ml-2 text-base-secondary";

      return (
        <div
          ref={ref || mRef}
          {...rest}
          className={clsx(
            "inline-flex w-full cursor-pointer items-center justify-center px-4 py-[14px] text-sm text-base-secondary  hover:bg-neutral-100 active:bg-neutral-200  dark:text-neutral-200 dark:hover:bg-neutral-dark-0",
            className
          )}
        >
          {React.cloneElement(labelComponent, {
            open,
            ...rest,
          })}
          {showArrow && open && <ChevronUpIcon className={classNameIcon} aria-hidden />}
          {showArrow && !open && <ChevronDownIcon className={classNameIcon} aria-hidden />}
        </div>
      );
    }

    const classNameIcon = "h-4 w-4 ml-2";

    return (
      <div
        className={clsx(
          "hove:bg-neutral-100 inline-flex w-full cursor-pointer items-center justify-center p-4 text-sm font-normal  active:bg-neutral-200  dark:hover:bg-gray-750",
          className
        )}
        ref={ref}
        {...rest}
      >
        {label}
        {showArrow && open && <ChevronUpIcon className={classNameIcon} aria-hidden />}
        {showArrow && !open && <ChevronDownIcon className={classNameIcon} aria-hidden />}
      </div>
    );
  }
);

MenuButton.displayName = "MenuButton";

export default MenuButton;
