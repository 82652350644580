import { useCallback, useMemo, useState } from "react";

import useTableActionsState from "hooks/useTableActionsState";
import useRecordTypes from "hooks/useRecordTypes";
import useSchemaState from "hooks/useSchemaState";
import useFilterUpdate from "hooks/useFilterUpdate";

import { CellType, EXCLUDED_FILTER_CELL_TYPES, FILTER_OPERATOR } from "utils/constants";

import { TableColumnType, TableViewType } from "types/baTypes";

import { appendRecordTypeLookupToCol } from "utils/columnUtils";
import { RecordItem, SelectOption } from "types/common";
import FilterItem from "./FilterItem";
import { FiltersUI } from "./FiltersUI";
import {
  CURRENT_USER_FILTER_ID,
  TableColumnTypeAndFilters,
  getFilterValueFromFilter,
  getValidFilterTypes
} from "./utils";

type FiltersProps = {
  columns: TableColumnType[];
  tableName: string;
  tableSlug: string;
  tableFiltersOption?: TableViewType;
  inAddMany?: boolean;
};

const Filters = ({ columns, tableName, tableSlug, tableFiltersOption, inAddMany = false }: FiltersProps) => {
  const { data: recordTypesData } = useRecordTypes();
  const { filtersByTableSlug, openFiltersByTableSlug } = useTableActionsState();
  const { schemaInstance } = useSchemaState();
  const [jsonFilterValues, setJsonFilterValues] = useState<RecordItem>({});

  const filterRowsToRender = useMemo(() => {
    return (filtersByTableSlug?.[tableSlug] || []).filter((filter) => filter?.id !== CURRENT_USER_FILTER_ID);
  }, [filtersByTableSlug, tableSlug]);

  const finalColumns = useMemo(() => {
    return !schemaInstance?.extendedSchema
      ? []
      : (columns
          ?.map((col) =>
            appendRecordTypeLookupToCol(col, recordTypesData || [], tableName, schemaInstance?.extendedSchema)
          )
          .filter(Boolean) as TableColumnType[]) || [];
  }, [columns, recordTypesData, tableName, schemaInstance?.extendedSchema]);

  const { handleUpdateFilters, handleUpdateFilterValue, handleUpdateOperator, handleUpdateFilterGroup } =
    useFilterUpdate(tableSlug, finalColumns, inAddMany);

  const columnOptions = useMemo(() => {
    return finalColumns
      .filter((item) => !filterRowsToRender.map((col) => col.id).includes(item.id))
      .map((column) => ({
        value: column.id,
        title: column.header || column.name,
        column
      }));
  }, [finalColumns, filterRowsToRender]);

  const columnsWithFilters = useMemo(
    () => finalColumns.filter((col) => col.hasFilter && !EXCLUDED_FILTER_CELL_TYPES.includes(col.type)) || [],
    [finalColumns]
  );

  const updateFilterValue = (params: {
    colId: string;
    value: string | SelectOption | boolean;
    isInOrGroup?: boolean;
    orFilterIndex?: number;
    isJSONFilter?: boolean;
  }) => {
    if (!params.isJSONFilter) {
      handleUpdateFilterValue(params);
    } else {
      setJsonFilterValues((prev) => {
        return {
          ...prev,
          [params.colId]: params.value
        };
      });
    }
  };

  const handleSubmitJsonFilter = useCallback(
    (colId: string) => {
      if (jsonFilterValues?.[colId]) {
        handleUpdateFilterValue({ colId, value: jsonFilterValues[colId] });
        setJsonFilterValues((prev) => {
          const newJsonFilterValues = { ...prev };
          delete newJsonFilterValues[colId];
          return newJsonFilterValues;
        });
      }
    },
    [handleUpdateFilterValue, jsonFilterValues]
  );

  const renderFilter = (item: TableColumnTypeAndFilters, filterIndex: number) => {
    const filter = filtersByTableSlug?.[tableSlug]?.find(
      (filter) => filter.id === item.id && filter.filterOperator === item.filterOperator
    );

    const column = finalColumns.find((col) => col.id === item.id);
    if (!filter || (filter.filterOperator !== FILTER_OPERATOR.OR && !column)) return null;

    const disabled =
      filter?.filterOperator === FILTER_OPERATOR.EMPTY || filter?.filterOperator === FILTER_OPERATOR.NOT_EMPTY;

    if (column?.type === CellType.BOOLEAN && filter.filterValue === undefined) {
      handleUpdateFilterValue({ colId: item.id, value: false });
    }

    const filterValue = jsonFilterValues?.[item.id]
      ? jsonFilterValues[item.id]
      : getFilterValueFromFilter(filter, column);

    if (filter.filterOperator === FILTER_OPERATOR.OR && filter.filterGroup?.length) {
      return (
        <div className="flex w-full flex-col gap-y-2">
          {filter.filterGroup.map((orfilter, orFilterIdx) => {
            if (!orfilter.column) return null;
            const selectOptions = getValidFilterTypes(orfilter.column);
            return (
              <FilterItem
                key={`${orfilter.id}_${orFilterIdx}`}
                onChangeOperator={handleUpdateOperator}
                column={orfilter.column}
                selectOptions={selectOptions}
                onEdit={handleUpdateFilterValue}
                type={orfilter.type}
                value={orfilter.filterValue}
                disabled={disabled}
                isMultiple={filter?.filterOperator === FILTER_OPERATOR.CONTAINS && orfilter.column.isMultiple}
                tableName={tableName}
                tablePath={`/${tableSlug}`}
                tableFiltersOption={tableFiltersOption}
                filterOperator={orfilter?.filterOperator}
                inAddMany={inAddMany}
                columnOptions={columnOptions}
                onUpdateRow={handleUpdateFilters}
                rows={filterRowsToRender}
                filterIndex={filterIndex}
                orFilterIndex={orFilterIdx}
                handleUpdateFilterGroup={handleUpdateFilterGroup}
                isInOrGroup
                allColumns={finalColumns}
              />
            );
          })}
        </div>
      );
    } else if (column) {
      const selectOptions = getValidFilterTypes(column);
      return (
        <FilterItem
          key={column.id}
          onChangeOperator={handleUpdateOperator}
          column={column}
          selectOptions={selectOptions}
          cellOptions={undefined}
          onEdit={updateFilterValue}
          type={filter.type}
          value={filterValue}
          disabled={disabled}
          isMultiple={filter?.filterOperator === FILTER_OPERATOR.CONTAINS && column.isMultiple}
          tableName={tableName}
          tablePath={`/${tableSlug}`}
          tableFiltersOption={tableFiltersOption}
          filterOperator={filter?.filterOperator}
          inAddMany={inAddMany}
          columnOptions={columnOptions}
          onUpdateRow={handleUpdateFilters}
          rows={filterRowsToRender}
          filterIndex={filterIndex}
          handleUpdateFilterGroup={handleUpdateFilterGroup}
          allColumns={finalColumns}
          handleSubmitJsonFilter={handleSubmitJsonFilter}
        />
      );
    }
  };

  return (
    <FiltersUI
      columns={columnsWithFilters}
      renderItem={renderFilter}
      rows={filterRowsToRender}
      onUpdateRow={handleUpdateFilters}
      open={openFiltersByTableSlug?.[tableSlug]}
    />
  );
};

export default Filters;
