import AlgoliaSearchFilter from "components/AlgoliaSearchFilter";
import MatrixXAxisColumnsSetting from "components/MatrixXAxisColumnsSetting";
import ViewColumnSettings from "components/ViewColumnSettings";
import ViewSelector from "components/ViewSelector";
import { SortItem, TableColumnType, TableViewConfig, TableViewType, ViewSection } from "types/baTypes";
import { CardSize } from "types/common";
import { ViewModes, ViewOption } from "utils/constants";
import AdminConfigBarButtons from "./AdminConfigBarButtons";
import BarButtons from "./BarButtons";
import BarWrapper from "./BarWrapper";
import CardSizeControl from "./CardSizeControl";
import ClearFilterButton from "./ClearFilterButton";
import CollapseExpand from "./CollapseExpand";
import ExportGridToCSV from "./ExportGridToCSV";
import FiltersSeparated from "./Filters/FiltersSeparated";
import BarOrgIdFilterToggle from "./Filters/BarOrgIdFilterToggle";

type BarProps = {
  activeView: ViewModes;
  views: ViewModes[];
  params: { slug: string };
  columns: TableColumnType[];
  tableName: string;
  onViewChange?: (view: ViewModes) => void;
  inDetailView?: boolean;
  tableSlug: string;
  tableFiltersOption?: TableViewType;
  searchTableId?: string;
  totalCount?: number;
  pageDataId?: string;
  inAddMany?: boolean;
  isDetailViewEnabled?: boolean;
  exposeFiltersEnabled?: boolean;
  exposeViewSelectorEnabled?: boolean;
  defaultCardSize?: CardSize;
  currentViewConfig?: TableViewConfig;
  viewSections?: ViewSection[];
  defaultViewSection?: ViewSection;
  inSidebar?: boolean;
  globalSort?: SortItem[];
  isAdminPage?: boolean;
  barControlsEnabled?: boolean;
  isPageDataLoading?: boolean;
};

const Bar = ({
  columns,
  activeView,
  views = [],
  params,
  tableName,
  onViewChange,
  inDetailView,
  tableSlug,
  tableFiltersOption,
  searchTableId,
  pageDataId,
  inAddMany = false,
  isDetailViewEnabled = false,
  exposeFiltersEnabled = false,
  exposeViewSelectorEnabled = false,
  defaultCardSize,
  currentViewConfig,
  globalSort,
  isAdminPage,
  barControlsEnabled = false
}: BarProps) => {
  return (
    <BarWrapper
      activeView={activeView}
      tableName={tableName}
      tableSlug={tableSlug}
      columns={columns}
      inAddMany={inAddMany}
    >
      {barControlsEnabled ? (
        <>
          <ViewSelector
            activeView={activeView}
            views={views}
            onViewChange={onViewChange}
            slug={params.slug}
            inDetailView={inDetailView}
            pageId={pageDataId}
            isExposed={exposeViewSelectorEnabled}
          />
          <BarOrgIdFilterToggle tableSlug={tableSlug} />
          {exposeFiltersEnabled && pageDataId && activeView !== ViewOption.MATRIX && (
            <FiltersSeparated
              tableSlug={tableSlug}
              columns={columns}
              activeView={activeView}
              inAddMany={inAddMany}
              tableName={tableName}
            />
          )}
          <BarButtons
            activeView={activeView}
            columns={columns}
            tableName={tableName}
            tableSlug={tableSlug}
            inAddMany={inAddMany}
            tableFiltersOption={tableFiltersOption}
            globalSort={globalSort}
          />
          {[ViewOption.GRID, ViewOption.CARD, ViewOption.BOARD].includes(activeView) && (
            <>
              <ViewColumnSettings columns={columns} activeView={activeView} tableSlug={tableSlug} />
              <ClearFilterButton tableSlug={tableSlug} />
            </>
          )}
          {activeView === ViewOption.MATRIX && !currentViewConfig?.additionalConfig?.matrixShowExpandedXAxisCols && (
            <MatrixXAxisColumnsSetting
              xAxisColumns={currentViewConfig?.additionalConfig?.matrixXAxisCols || []}
              tableSlug={tableSlug}
            />
          )}
          <CollapseExpand tableSlug={tableSlug} activeView={activeView} />
          {activeView === ViewOption.GRID && <ExportGridToCSV />}
          {activeView === ViewOption.CARD && (
            <CardSizeControl tableSlug={tableSlug} defaultCardSize={defaultCardSize} />
          )}
          <AdminConfigBarButtons
            views={views}
            isDetailViewEnabled={isDetailViewEnabled}
            pageDataId={pageDataId}
            tableSlug={tableSlug}
            tableName={tableName}
            activeView={activeView}
            searchTableId={searchTableId}
          />
          {searchTableId && !inAddMany && (
            <div className="min-w-[200px] max-w-[300px]">
              <AlgoliaSearchFilter
                searchTableId={searchTableId}
                tableViewFilters={tableFiltersOption}
                tableSlug={tableSlug}
                isAdminPage={isAdminPage}
              />
            </div>
          )}
        </>
      ) : (
        <BarOrgIdFilterToggle tableSlug={tableSlug} />
      )}
    </BarWrapper>
  );
};

export default Bar;
